import * as poseDetection from '@tensorflow-models/pose-detection';

var limbs = [
    [[11, 13, 15], false, 185, 30],
    [[12, 14, 16], false, 185, 30],
    [[5, 11, 13], false, 185, 30],
    [[6, 12, 14], false, 185, 30]
];

var hip_knee_distance = 0.0;
var knee_ankle_distance = 0.0;

export function isPostureCorrect() {
    var result;
    limbs.forEach((limb, key) => {
        if (limb[1] == false) {
            result = false;
        } else {
            result = true;
        }
        
    })
    return result;
}

export function setLimbsStatus(bool) {
    limbs.forEach((limb) => {
        limb[1] = bool
    })
}


// MoveNet Helper Functions //

export function setHipKneeDistance(distance) {
    hip_knee_distance = distance;
}

export function getHipKneeDistance() {
    return hip_knee_distance;
}

export function setKneeAnkleDistance(distance) {
    knee_ankle_distance = distance;
}

export function getKneeAnkleDistance() {
    return knee_ankle_distance;
}

export function calcHipKneeDistance(keypoints) {
    let model = poseDetection.SupportedModels.MoveNet;
    const keypointInd = poseDetection.util.getKeypointIndexBySide(model);
    let kneeHipDLeft = keypoints[keypointInd.left[6]].y - keypoints[keypointInd.left[5]].y;
    let kneeHipDRight = keypoints[keypointInd.right[6]].y - keypoints[keypointInd.right[5]].y;
    return (kneeHipDLeft + kneeHipDRight) / 2
}

export function calcKneeAnkleDistance(keypoints) {
    let model = poseDetection.SupportedModels.MoveNet;
    const keypointInd = poseDetection.util.getKeypointIndexBySide(model);
    let kneeAnkleDLeft = keypoints[keypointInd.left[7]].y - keypoints[keypointInd.left[6]].y;
    let kneeAnkleDRight = keypoints[keypointInd.right[6]].y - keypoints[keypointInd.right[6]].y;
    return (kneeAnkleDLeft + kneeAnkleDRight) / 2
}




// BlazePose Calculation/Helper Functions below //

// Calculates the 3D vector between two points
let getVector = (a, b) => [b.x-a.x, b.y-a.y, b.z-a.z];

// Calculates the distance of a 3D vector
let calcDist = a => Math.sqrt(a[0]**2 + a[1]**2 + a[2]**2);

// Calculates the dot product of two vectors
let dot = (a, b) => a.map((x, i) => a[i] * b[i]).reduce((m, n) => m + n);

// Calculates the angle between two 3D vectors in degrees
export function calc3dAngle(v1, v2) {
    let dotProduct = dot(v1, v2);
    let magnitude = calcDist(v1) * calcDist(v2);
    let angle = Math.acos(dotProduct / magnitude) * 180/Math.PI;
    if (angle > 180) {
        return 360 - angle;
    }
    return angle;
}

// Calculates the angle between three points in 3D space
export function calcAngle3Points(p1, p2, p3) {
    let v1 = getVector(p1, p2);
    let v2 = getVector(p1, p3);
    return calc3dAngle(v1, v2);
}


// keypoints should be 3d keypoints
export function calc3dKneeAngles(kpts) {
    let angleLeft = calcAngle3Points(kpts[25], kpts[27], kpts[23]);
    let angleRight = calcAngle3Points(kpts[26], kpts[28], kpts[24]);
    return [angleLeft, angleRight];
}

export function calcAngleToesKnees(kpts) {
    let angleLeft = calcAngle3Points(kpts[31], kpts[29], kpts[25]);
    let angleRight = calcAngle3Points(kpts[32], kpts[30], kpts[26]);
    return [angleLeft, angleRight];
}

export function calcKneesDistance(kpts) {
    return calcDist(getVector(kpts[28], kpts[27]));
}

export function calcAnklesDistance(kpts) {
    return calcDist(getVector(kpts[26], kpts[25]));
}


export function calcChestSlope(kpts) {
    let hipShoulderLeft = getVector(kpts[23], kpts[11]);
    let hipShoulderRight = getVector(kpts[24], kpts[12]);
    return [
        (hipShoulderLeft[0] - hipShoulderRight[0])/2,
        (hipShoulderLeft[1] - hipShoulderRight[1])/2,
        (hipShoulderLeft[2] - hipShoulderRight[2])/2
    ]
}


export function calcShoulderAngle(kpts) {
    let shoulderLeft = calcAngle3Points(kpts[11], kpts[23], kpts[13]);
    let shoulderRight = calcAngle3Points(kpts[12], kpts[24], kpts[14]);
    return [shoulderLeft, shoulderRight];
}

export function calcElbowAngle(kpts) {
    let elbowLeft = calcAngle3Points(kpts[13], kpts[11], kpts[15]);
    let elbowRight = calcAngle3Points(kpts[14], kpts[12], kpts[16]);
    return [elbowLeft, elbowRight];
}

export function calcHandsPosition(kpts) {
    let leftHand = kpts[16];
    let rightHand = kpts[15];
    let leftShoulder = kpts[12];
    let rightShoulder = kpts[11];
    //let lrVec = getVector(leftHand, rightHand); // used to check if hands are near each other
    let rs = leftShoulder.x - rightHand.x;
    let ls = rightShoulder.x - leftHand.x;
    return [ls, rs];
}

export function calcHandsAngle(kpts) {
    // 14 12 24
    // 13 11 23
    let leftAngle = calcAngle3Points(kpts[24], kpts[12], kpts[14]);
    let rightAngle = calcAngle3Points(kpts[23], kpts[11], kpts[13]);

    return [leftAngle, rightAngle];
}

export function getKneeSample(kpts) {
    let knee_sample = [
        kpts[32].x,
        kpts[32].y,
        kpts[32].z,
        kpts[28].x,
        kpts[28].y,
        kpts[28].z,
        kpts[26].x,
        kpts[26].y,
        kpts[26].z,
        kpts[31].x,
        kpts[31].y,
        kpts[31].z,
        kpts[27].x,
        kpts[27].y,
        kpts[27].z,
        kpts[25].x,
        kpts[25].y,
        kpts[25].z
    ];
    return knee_sample
}

export function getBackSample(kpts) {
    let back_data = [
        kpts[25].x,
        kpts[25].y,
        kpts[25].z,
        kpts[23].x,
        kpts[23].y,
        kpts[23].z,
        kpts[11].x,
        kpts[11].y,
        kpts[11].z,
        kpts[26].x,
        kpts[26].y,
        kpts[26].z,
        kpts[24].x,
        kpts[24].y,
        kpts[24].z,
        kpts[12].x,
        kpts[12].y,
        kpts[12].z
    ];
    return back_data
}