import React, { useState, useContext } from "react";
import { Card, Col, Container, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AnalyticsContext } from "../../../App";

/*define the type for a Question Object*/
export class Question {
  name: string;
  title: string;
  subtitle: string;
  response_type: string;
  response_fields: number;

  constructor(
    name: string,
    title: string,
    subtitle: string,
    response_type: string,
    response_fields: number
  ) {
    this.name = name;
    this.title = title;
    this.subtitle = subtitle;
    this.response_type = response_type;
    this.response_fields = response_fields;
  }
}

function FeedbackForm(props) {
  let questions: Question[];
  const [formData, setFormData] = useState({
    recognition_satisfaction: "",
    height_cm: "",
    wishes_exercises: "",
  });

  let webAnalytics = useContext(AnalyticsContext);
  const navigate = useNavigate();

  const handleInputData = (input) => (e) => {
    const { value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };
  async function handleSubmit(e) {
    e.preventDefault();
    // probably check before if form is filled
    webAnalytics.trackFormInput(formData);
    navigate("/", {
      replace: false,
    });
  }

  const handleChange = (e) => {
    formData[e.currentTarget.name] = e.currentTarget.value;

    handleInputData(e.currentTarget.name);
  };

  const questionsStyle = {
    fontFamily: "Roboto",
    fontWeight: 500,
  };
  const cardStyle = {
    padding: "1rem",
    margin: "1rem",
  };
  questions = [
    new Question(
      "recognition_satisfaction",
      "How satisfied are you with the recognition of the exercises?",
      "",
      "radio",
      5
    ),
    new Question("height_cm", "Enter your height in cm?", "", "text", 1),
    new Question(
      "free_text",
      "Any thoughts/suggestions?",
      "",
      "text",
      1
    ),
  ];

  return (
    <div
      style={{
        background: "linear-gradient(180deg, #ffffff 0%, #eaf9ff 100%)",
        display: "flex",
        flexDirection: "column",
        height: "100vh"
      }}
    >
      <SemiCircleDiv
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Title>Feedback Form</Title>
      </SemiCircleDiv>

      <Container
        style={{
          maxWidth: "65%",
          position: "relative",
        }}
      >
        <Form onSubmit={handleSubmit}>
          {questions.map((question) => (
            <Form.Group>
              <Card style={cardStyle}>
                <Card.Title>
                  <Form.Label style={questionsStyle} htmlFor={question.name}>
                    {question.title}
                  </Form.Label>
                  {question.subtitle.length > 0 ? (
                    <Form.Label
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: 300,
                        fontSize: "16px",
                      }}
                    >
                      {question.subtitle}
                    </Form.Label>
                  ) : (
                    <></>
                  )}
                </Card.Title>
                <Card.Body>
                  {question.response_type === "radio" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <Form.Label>Very unsatisfied</Form.Label>
                      <div>
                        <Form.Check
                          inline
                          label="1"
                          name={question.name}
                          type="radio"
                          value="1"
                          onChange={handleChange}
                        />
                        <Form.Check
                          inline
                          label="2"
                          name={question.name}
                          type="radio"
                          value="2"
                          onChange={handleChange}
                        />
                        <Form.Check
                          inline
                          label="3"
                          name={question.name}
                          type="radio"
                          value="3"
                          onChange={handleChange}
                        />
                        <Form.Check
                          inline
                          label="4"
                          name={question.name}
                          type="radio"
                          value="4"
                          onChange={handleChange}
                        />
                        <Form.Check
                          inline
                          label="5"
                          name={question.name}
                          type="radio"
                          value="5"
                          onChange={handleChange}
                        />
                      </div>
                      <Form.Label>Very satisfied</Form.Label>
                    </div>
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Your answer"
                      name={question.name}
                      onChange={handleInputData(question.name)}
                      value={formData[question.name]}
                    />
                  )}
                </Card.Body>
              </Card>
            </Form.Group>
          ))}
          <Card
            style={{
              padding: "2rem",
              margin: "1rem",
              background: "transparent",
              border: "0px",
            }}
          >
            <Button type="submit" variant="success" style={{}}>
              Submit
            </Button>
          </Card>
        </Form>
      </Container>
    </div>
  );
}

const SemiCircleDiv = styled.div`
  width: 450px;
  height: 130px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -15%);
  background-color: white;
  border-bottom-left-radius: 210px;
  border-bottom-right-radius: 210px;
  border: 3px solid #e3e3e3;
  border-top: 0;
  flex-direction: center;
  justify-content: center;
  text-align: center;
`;

const Title = styled.div`
  background-clip: text;
  -webkit-background-clip: text;
  color: #25ba7a;
  font-family: "Roboto";
  font-size: 40px;
  font-weight: 500;
`;

export default FeedbackForm;
