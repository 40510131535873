import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const Redirect = () => {
    let username = localStorage.getItem("username");
    const navigate = useNavigate();

    const checkBrowser = () => {
        const userAgent = window.navigator.userAgent;
        const isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(window.navigator.vendor);

        if (!isChrome) {
            alert('Only Chrome is supported. Please use Google Chrome for the best experience.');
        }
    };

    const checkChallengeDate = () => {
        const today = new Date();
        const challenge_end = new Date("2023-04-14T00:00:00");

        if (today > challenge_end) {
            navigate("/finish_challenge");
        }
    };


    
    useEffect(() => {
        checkChallengeDate();
        checkBrowser()
        if (username === null || username === "" || username === undefined) {
            navigate("/login")
        }
    }, [])
}

export default Redirect