import * as poseDetection from "@tensorflow-models/pose-detection";
import {
    calc3dKneeAngles,
    calcElbowAngle,
    calcShoulderAngle, getBackSample, getKneeSample,
} from "./handler";
import KNN from "ml-knn";
import {train_dataset, train_labels} from "./data_knees";
import {train_data_back, train_labels_back} from "./data_back";


const KEYPOINT_DICT = {
    'nose': 0,
    'left_eye': 1,
    'right_eye': 2,
    'left_ear': 3,
    'right_ear': 4,
    'left_shoulder': 5,
    'right_shoulder': 6,
    'left_elbow': 7,
    'right_elbow': 8,
    'left_wrist': 9,
    'right_wrist': 10,
    'left_hip': 11,
    'right_hip': 12,
    'left_knee': 13,
    'right_knee': 14,
    'left_ankle': 15,
    'right_ankle': 16
}

const STAGES = {
    "down": -1,
    "neutral": 0,
    "up": 1
}


export class SquatAnalyticsBlaze {

    repetitions = 0;
    sumKneeAngle = 0;
    angleIntervalStart = 150;
    angleIntervalEnd = 60;

    counter = 0;
    stage = -1;

    // posture
    kneeOverFeetCount = 0;
    kneeInsideCount = 0;
    badBackCount = 0;

    k_knn = new KNN(train_dataset, train_labels);
    b_knn = new KNN(train_data_back, train_labels_back);

    setMinToeKneeDistance(tkDist) {
        this.sumToeKneeDistance += tkDist;
        if (tkDist < this.minToeKneeDistance) {
            this.minToeKneeDistance = tkDist;
        } else if (tkDist > this.maxToeKneeDistance) {
            this.maxToeKneeDistance = tkDist;
        }
    }

    calcKneeAngle(keypoints) {
        let angle = calc3dKneeAngles(keypoints);
        angle = (angle[0] + angle[1]) / 2;
        this.repetitionCounter(angle);
        return angle;
    }

    avgKneeAngle() {
        return this.sumKneeAngle / this.counter;
    }

    avgToeKneeDistance() {
        return this.sumToeKneeDistance / this.counter;
    }

    repetitionCounter(alpha) {
        if (alpha < 95) {
            this.stage = STAGES.up
        } else if (alpha > 130) {
            if (this.stage === STAGES.up) {
                this.repetitions++;
            }
            this.stage = STAGES.down
        }
    }

    checkPosture(keypoints) {
        let kpts = keypoints;

        if ((kpts[32].score + kpts[31].score + kpts[25].score + kpts[26].score) / 4 < 0.5) return;
        let knee_sample = getKneeSample(kpts);
        let knee_pred = this.k_knn.predict(knee_sample);
        if (knee_pred === 1) this.kneeOverFeetCount++;
        else {
            if (this.kneeOverFeetCount > 0) this.kneeOverFeetCount = this.kneeOverFeetCount - 0.5;
        }

        if ((kpts[12].score + kpts[11].score + kpts[25].score + kpts[26].score) / 4 < 0.5) return;
        let back_data = getBackSample(kpts);
        let back_pred = this.b_knn.predict(back_data);
        if (back_pred === 1) this.badBackCount++;
        else {
            if (this.badBackCount > 0) this.badBackCount = this.badBackCount - 0.5;
        }
        console.log(this.kneeOverFeetCount);
    }

    getPostureInstructions() {
        let poseInstructions = "";
        if (this.kneeOverFeetCount > 30) {
            poseInstructions = "Versuche die Knie etwas weniger weit nach vorne zu beugen"
        }
        if (this.kneeInsideCount > 30) {
            // not yet implemented
            poseInstructions = poseInstructions + "\nVersuche deine Knie nicht nach innen zu drehen"
        }
        if (this.badBackCount > 30) {
            poseInstructions = poseInstructions + "\nVersuche deinen Rücken aufrechter zu halten"
        }
        if (poseInstructions !== "") {
            this.kneeOverFeetCount = 0;
            this.kneeInsideCount = 0;
            this.badBackCount = 0;
        }
        return poseInstructions
    }
}


export class SquatAnalytics {

    hip_knee_distance = 0.0;
    knee_ankle_distance = 0.0;
    fk_dist = 0.0;
    repetitions = 0;

    minKneeAngle = 180;
    maxKneeAngle = 0;
    sumKneeAngle = 0;
    minKneeAngleRA = [0, 0];
    maxKneeAngleRA = [0, 0];

    minToeKneeDistance = 180;
    maxToeKneeDistance = 180;
    sumToeKneeDistance = 0;

    angleIntervalStart = 150;
    angleIntervalEnd = 60;

    counter = 0;
    stage = -1;
    keypointInd;

    changedFlag = false;

    constructor() {
        let model = poseDetection.SupportedModels.MoveNet;
        this.keypointInd = poseDetection.util.getKeypointIndexBySide(model);
    }


    calcHipKneeDistance(keypoints) {
        const keypointInd = this.keypointInd;
        let kneeHipDLeft = keypoints[keypointInd.left[6]].y - keypoints[keypointInd.left[5]].y;
        let kneeHipDRight = keypoints[keypointInd.right[6]].y - keypoints[keypointInd.right[5]].y;
        return (kneeHipDLeft + kneeHipDRight) / 2
    }

    calcKneeAnkleDistance(keypoints) {
        const keypointInd = this.keypointInd;
        let kneeAnkleDLeft = keypoints[keypointInd.left[7]].y - keypoints[keypointInd.left[6]].y;
        let kneeAnkleDRight = keypoints[keypointInd.right[6]].y - keypoints[keypointInd.right[6]].y;
        return (kneeAnkleDLeft + kneeAnkleDRight) / 2
    }

    calcFeetKneeDistance(alpha) {
        this.fk_dist = Math.cos(alpha / (180/Math.PI)) * this.knee_ankle_distance;
        this.sumToeKneeDistance += this.fk_dist;
        if (this.fk_dist < this.minToeKneeDistance) {
            this.minToeKneeDistance = this.fk_dist;
        } else if (this.fk_dist > this.maxToeKneeDistance) {
            this.maxToeKneeDistance = this.fk_dist;
        }
    }

    calcKneeAngle(keypoints) {
        let hipKneeD = this.calcHipKneeDistance(keypoints);
        let kneeAnkleD = this.calcKneeAnkleDistance(keypoints);
        if (hipKneeD > this.hip_knee_distance) {
            hipKneeD = this.hip_knee_distance;
        }
        if (kneeAnkleD > this.knee_ankle_distance) {
            kneeAnkleD = this.knee_ankle_distance;
        }
        let alpha1 = Math.asin(hipKneeD / this.hip_knee_distance) * (180/Math.PI);
        let alpha2 = Math.asin(kneeAnkleD / this.knee_ankle_distance) * (180/Math.PI);
        if (hipKneeD / this.hip_knee_distance > 1) {
            alpha1 = 90
        }
        if (kneeAnkleD / this.knee_ankle_distance > 1) {
            alpha2 = 90
        }
        let angle = alpha1 * 2 //+ alpha2;

        // cos(alpha2) = distance / knee_ankle_distance
        this.calcFeetKneeDistance(alpha2);
        this.repetitionCounter(alpha1 + alpha2);

        if (angle < this.minKneeAngle) {
            this.minKneeAngleRA[0] += angle
            this.minKneeAngleRA[1] += 1
            if (this.minKneeAngleRA[1] === 5) {
                this.minKneeAngle = this.minKneeAngleRA[0] / 5
                this.minKneeAngleRA[0] = 0
                this.minKneeAngleRA[1] = 0
            }
        } else if (angle > this.maxKneeAngle) {
            this.maxKneeAngleRA[0] += angle
            this.maxKneeAngleRA[1] += 1
            if (this.maxKneeAngleRA[1] === 5) {
                this.maxKneeAngle = this.maxKneeAngleRA[0] / 5
                this.maxKneeAngleRA[0] = 0
                this.maxKneeAngleRA[1] = 0
            }
        }
        this.sumKneeAngle += angle;
        this.counter++;

        return angle;
    }

    frontalKneeAngle(hip, knee, ankle) {
        let dy1 = Math.abs(hip.y - knee.y);
        let dy2 = Math.abs(knee.y - ankle.y);
        let dx1 = Math.abs(hip.x - knee.x);
        let dx2 = Math.abs(knee.x - ankle.x)

        let angle1 = Math.atan(dx1/dy1) * (180/Math.PI);
        let angle2 = Math.atan(dx2/dy2) * (180/Math.PI);

        return angle1 + angle2;
    }

    avgKneeAngle() {
        return this.sumKneeAngle / this.counter;
    }

    avgToeKneeDistance() {
        return this.sumToeKneeDistance / this.counter;
    }


    calculate(keypoints) {
        let leftFKA = this.frontalKneeAngle(
            keypoints[KEYPOINT_DICT.left_hip],
            keypoints[KEYPOINT_DICT.left_knee],
            keypoints[KEYPOINT_DICT.left_ankle]
        );
        let rightFKA = this.frontalKneeAngle(
            keypoints[KEYPOINT_DICT.right_hip],
            keypoints[KEYPOINT_DICT.right_knee],
            keypoints[KEYPOINT_DICT.right_ankle]
        );
        let diff = Math.abs(leftFKA - rightFKA)/((leftFKA + rightFKA)/2);
        if (diff > 0.05) {
            //console.log("Bad Knee Angle");
        }
        return diff;
    }

    repetitionCounter(alpha) {
        if (alpha < 95) {
            this.stage = STAGES.up
        } else if (alpha > 130) {
            if (this.stage === STAGES.up) {
                this.repetitions++;
                if (this.repetitions > 2) {
                    if (this.angleIntervalStart < this.maxKneeAngle - 1 || this.angleIntervalStart > this.maxKneeAngle + 1) {
                        //this.angleIntervalStart = this.maxKneeAngle - this.maxKneeAngle * 0.12;
                        //this.changedFlag = true;
                    }
                    if (this.angleIntervalEnd < this.minKneeAngle - 1 || this.angleIntervalEnd > this.minKneeAngle + 1) {
                        //this.angleIntervalEnd = this.minKneeAngle + this.minKneeAngle * 0.3;
                        //this.changedFlag = true;
                    }
                    this.maxKneeAngle = 0;
                    this.minKneeAngle = 180;
                }
            }
            this.stage = STAGES.down
        }
    }
}

export class WYAnalyticsBlaze {
    repetitions = 0;

    counter = 0;
    stage = -1;

    calcShElAngle(keypoints) {
        let shAngle = calcShoulderAngle(keypoints);
        let elAngle = calcElbowAngle(keypoints);
        shAngle = (shAngle[0] + shAngle[1]) / 2;
        elAngle = (elAngle[0] + elAngle[1]) / 2;
        this.wyRepetitionCounter(shAngle, elAngle);
        return shAngle;
    }

    wyRepetitionCounter(shoulder, elbow) {
        if (shoulder < 85 && elbow > 70 && elbow < 95) {
            this.stage = STAGES.up;
        } else if (shoulder > 120 && elbow > 150) {
            if (this.stage === STAGES.up) {
                this.repetitions++;
            }
            this.stage = STAGES.down;
        }
    }
}

export function pushSlidingWindow(posHistory, angles, window_size) {
    posHistory.push((angles[0] + angles[1]) / 2);
    if (posHistory.length > window_size) {
        posHistory.shift();
    }
    let avgPos = 0;
    for (let i = 0; i < posHistory.length; i++) {
        avgPos += posHistory[i];
    }
    avgPos /= posHistory.length;
    return avgPos;
}
