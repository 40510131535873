import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {getScores, sortScores} from "../../../services/firebase";
import './leaderboard.css';
import BestThreeRankings from "./best_three_rankings";
import deskImage from "../../../assets/ZP_Sitzen_Unterbrechen_3.svg";
import arrowUp from "../../../assets/arrow_up.svg";
import arrowDown from "../../../assets/arrow_down.svg";
import UserRankings from "./user_rankings";

let dummyUser = {
    userName: "",
    score: [],
    workouts: [],
    duration: [],
};

const Leaderboard = () => {

    // gets scores etc. only from navigation, to get it in general, use eg. context
    let username = localStorage.getItem("username");
    const [bestScores, setBestScores] = useState([]);
    const [myRank, setMyRank] = useState(0);
    const [user, setUser] = useState(dummyUser);
    const [best3, setBest3] = useState([dummyUser, dummyUser, dummyUser])
    const navigate = useNavigate();

    useEffect(() => {
        if (username === null || username === undefined) return
        username = localStorage.getItem("username").toString();
        getScores().then(r => {
            let result = getEntries(r, username);
            if (result != null) {
                let res = result[0];
                setBestScores(res);
            }
            r = sortScores(r);
            r.forEach((a, i) => {
                if (i < 3) {
                    let best = best3;
                    best[i] = a;
                    setBest3(best);
                }
            });
        });
    }, [])


    function getEntries(arr, username) {
        arr = sortScores(arr);
        const index = arr.findIndex((elem) => elem.userName === username);
        if (index === -1) {
            return null;
        }
        setMyRank(index + 1);
        setUser(arr[index]);

        const result = [];
        const rank = [];

        for (let i = 0; i < arr.length - 3; i++) {
            result.push(arr[i + 3])
            rank.push(i + 3 + 1)
        }
        return [result, rank];
    }

    return (
        <div className="leaderboard">
            <h1 className="title">Ranking</h1>
            <div className="scores-container">
                <div className="lead-container">
                    <div className="lead-section">
                        <div className="lead-text">
                            {best3[0].userName}, {best3[1].userName} und {best3[2].userName} sind in Führung.<br/>Holen Sie auf!
                        </div>
                        <button className="challenge-button" onClick={() => {
                            navigate("/place_laptop")
                        }}>Challenge starten</button>
                    </div>

                    <img src={deskImage} alt="desk" style={{ height: "15vh" , marginLeft: "5vw", marginRight: "2vw"}} />

                    <BestThreeRankings score={best3[2].score?.reduce((acc, val) => acc + val, 0) || 0} username={best3[2].userName} place={3} style={{ marginLeft: 'auto' }}></BestThreeRankings>
                    <BestThreeRankings score={best3[1].score?.reduce((acc, val) => acc + val, 0) || 0} username={best3[1].userName} place={2}></BestThreeRankings>
                    <BestThreeRankings score={best3[0].score?.reduce((acc, val) => acc + val, 0) || 0} username={best3[0].userName} place={1}></BestThreeRankings>
                </div>

                <div style={{overflow: "hidden", width: "100%"}}>
                    <div className="leaderboard-scores">
                        <div className="leaderboard-background">
                            <div className="leaderboard-ranking">Sie sind auf Platz {myRank}!</div>
                            <table className="leaderboard-table">
                                <thead>
                                <tr>
                                    <th>Rang</th>
                                    <th>Spieler</th>
                                    <th>Punkte</th>
                                    <th>Evolution</th>
                                </tr>
                                </thead>
                                <tbody>
                                {bestScores.map((value, index) => {
                                    return <UserRankings value={value} username={username} rank={index + 1 + 3}></UserRankings>
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="leaderboard-last-workouts">
                        <div className="leaderboard-background">
                            <div className="leaderboard-ranking">Ihre letzten Übungen</div>
                            <table className="leaderboard-table">
                                <thead>
                                <tr>
                                    <th>Zeitraum</th>
                                    <th></th>
                                    <th>Dauer</th>
                                    <th>Punkte</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    user.workouts ? user.workouts
                                            .map((value, index) => ({
                                                date: new Date(value),
                                                duration: user.duration[index],
                                                score: user.score[index]
                                            }))
                                            .sort((a, b) => b.date - a.date)
                                            .map(({date, duration, score}, index) => (
                                                <tr key={index}>
                                                    <td>{getDateString(date)}</td>
                                                    <td></td>
                                                    <td>{duration} min</td>
                                                    <td>{formatNumber(score)}</td>
                                                </tr>
                                            ))
                                        : null
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function getDateString(timeStamp) {
    const date = new Date(timeStamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const min = date.getMinutes().toString().padStart(2, '0');

    const today = new Date(Date.now());
    console.log(today.toDateString())
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = new Date(today.setDate(today.getDate() - 1)).toDateString() === date.toDateString();

    if (isToday) {
        return `Heute ${hour}:${min}`;
    } else if (isYesterday) {
        return `Gestern ${hour}:${min}`;
    } else {
        return `${day}.${month} ${hour}:${min}`;
    }
}

export function arr_down() {
    return (
        <img src={arrowDown} alt="arrow_down" style={{height: "25px", marginTop: "auto"}}/>
    )
}

export function arr_up() {
    return (
        <img src={arrowUp} alt="arrow_up" style={{height: "25px", marginTop: "auto", marginBottom: "auto"}}/>
    )
}

export function formatNumber(num) {
    if (num < 1000) {
        return num.toString();
    } else {
        let numString = (num / 1000).toFixed(1);
        if (numString.endsWith('.0')) {
            numString = numString.slice(0, -2);
        }
        return numString + 'K ';
    }
}


export default Leaderboard;

