import React from "react";
import {arr_down, arr_up} from "./leaderboard";
import { useState, useEffect } from "react";

const UserRankings = ({ value, username, rank }) => {
    const [changeNmbr, setChangeNmbr] = useState("");
    const [change, setChange] = useState(0);
    const [score, setScore] = useState(0);

    useEffect(() => {
        let newScore = 0;
        if (value.score !== undefined) {
            newScore = value.score.reduce((a, b) => a + b, 0);
        }
        setScore(newScore);

        let newChange = rank < value.lastRank ? 1 : rank > value.lastRank ? -1 : 0;
        if (newScore !== 0) {
            if (value.currentRank !== rank) {
                newChange = rank > value.currentRank ? -1 : rank < value.currentRank ? 1 : 0;
                if (newChange !== 0) {
                    if (newChange === 1) {
                        setChangeNmbr(`+ ${(value.currentRank - rank).toFixed(0)}`);
                    } else {
                        setChangeNmbr(`- ${(rank - value.currentRank).toFixed(0)}`);
                    }
                }
            } else {
                if (newChange !== 0) {
                    const val = Math.abs(value.lastRank - value.currentRank);
                    if (newChange === 1) {
                        setChangeNmbr(`+ ${val.toFixed(0)}`);
                    } else {
                        setChangeNmbr(`- ${val.toFixed(0)}`);
                    }
                }
            }
            setChange(newChange);
        } else {
            console.log(score)
            setChange(0);
            setChangeNmbr("");
        }
    }, [value, rank]);

    return (
        <tr className={value.userName === username ? "current" : ""}>
            <td>{rank}</td>
            <td>{value.userName}</td>
            <td>{score}</td>
            <td>
                {change === 1 ? (
                    <span className="arrow-up">{arr_up()}</span>
                ) : change === -1 ? (
                    <span className="arrow-down">{arr_down()}</span>
                ) : (
                    <span className="arrow-stay">&mdash;</span>
                )}
                <span>{changeNmbr}</span>
            </td>
        </tr>
    );
};


export default UserRankings;