import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.95fr 0.05fr;
  grid-template-rows: 0.2fr 0.5fr 0.3fr;
  grid-template-areas:
    "title title2"
    "body action"
    "options options";
  border-radius: 18px;
  border: 1.5px solid rgba(225, 225, 225);
  background: #ffffff;
  width: 100%;
  position: relative;
  padding: 40px;
`;

const CardTitle = styled.h5`
  grid-area: title;
  background-clip: text;
  -webkit-background-clip: text;
  text-align: start;
  color: rgb(88, 95, 102);
  font-family: "Roboto";
  font-weight: 400;
  font-size: 2.5vw;
  flex-grow: 1;
`;

const CardOptions = styled.div`
  grid-area: options;
  font-size: 1.2em;
  display: flex;
  grid-area: title2;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
`;

const CardTextWrapper = styled.div`
  grid-area: body;
  margin: 20px 0px 20px 0px;
`;

const CardTextBody = styled.p`
  color: rgb(88, 95, 102);
  font-family: "Roboto";
  font-weight: 300;
  font-size: 1.7vw;
  text-align: start;
`;

const CardBadge = styled.div`
  display: flex;
  height: 30px;
  width: 80px;
  background: ${(props) => props.badgeColor || "rgba(0,0,0,10%)"};
  color: ${(props) => props.textColor || "rgba(88, 95, 102, 1)"};
  border-radius: 44px;
  justify-content: center;
  align-items: center;
`;

const CardButton = styled.button`
 position: absolute;
 width: 290px;
 height: 56px;
 background: #2E99C2;
 border-radius: 8px;
 color: white;
 font-family: Roboto;
 font-size: 25px;
 font-weight: 400;
 @media (max-width: 1280px) {
    width: 200px;
    height: 40px;
    font-size: 18px;
 };
 right: 40px;
 bottom: 40px
`

export const ExerciseCardChallenge = (props) => {
    const navigate = useNavigate();
    return (
        <CardWrapper>
            <div style={{display: "flex", width: "100%"}}>
                <CardTitle>{props.title || " "}</CardTitle>
                <CardOptions>
                    <CardBadge
                        badgeColor="rgba(42, 163, 42, 0.2)"
                        textColor="rgb(42, 163, 42)">
                        {props.type || " "}
                    </CardBadge>
                    <CardBadge>{props.duration || " "}.</CardBadge>
                </CardOptions>
            </div>
            <CardTextWrapper>
                <CardTextBody>{props.body || " "}</CardTextBody>
            </CardTextWrapper>
            <CardButton onClick={() => navigate(props.path)}>
                Starten
            </CardButton>
        </CardWrapper>
    );
};