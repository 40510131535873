import React, {useContext, useState, useRef, useEffect} from "react";
import Webcam from "react-webcam";
import {Camera, CameraWrapper, Canvas, Container} from "../stretch/position";
import * as poseDetection from "@tensorflow-models/pose-detection";
import * as mpPose from "@mediapipe/pose";
import {useNavigate} from "react-router-dom";
import {pushSlidingWindow, SquatAnalytics} from "../../../../services/workout_analysis/workout_analytics";
import styled from "styled-components";
import {calc3dKneeAngles} from "../../../../services/workout_analysis/handler";
import instruction_video from "../../../../assets/squats_video.mov";

const VIDEO_CONSTRAINTS = {
    width: 500,
    height: 480,
    facingMode: "user",
    deviceId: "",
    frameRate: { max: 60, ideal: 30 },
};

const model = poseDetection.SupportedModels.BlazePose;
let loaded_detector = false;
let detector = null;
var kneeAngle = 0.0;
let posHistory = [];
let window_size = 5;

const detectorConfig = {
    runtime: 'mediapipe', // or 'tfjs'
    modelType: 'light',
    solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`
};


const InstructionScreen = () => {
    const [cameraReady, setCameraReady] = useState(false);
    const webcamRef = useRef({});
    const navigate = useNavigate();
    const [analytics] = useState(new SquatAnalytics())


    useEffect(() => {
        poseDetection.createDetector(model, detectorConfig).then((det) => {
            detector = det;
            loaded_detector = true;
        });

        var canvas = document.getElementById("instruction_video");
        var ctx = canvas.getContext("2d")
        var video = document.getElementById('video');
        video.muted = true;

        video.addEventListener('loadedmetadata', function() {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
        });

        video.play();
        video.addEventListener("play", () => {
            function step() {
                ctx.drawImage(video, 0, -200, canvas.width, canvas.height);
                requestAnimationFrame(step);
            }
            requestAnimationFrame(step);
        });

    }, [])

    useEffect(() => {
        loop();
    })

    const onUserMediaError = () => {
        console.log('ERROR in Camera!');
    };

    const onUserMedia = () => {
        console.log('Camera loaded!');
        setCameraReady(true);
    };

    async function loop() {
        console.log(loaded_detector)
        if (analytics.repetitions > 0) {
            setTimeout(function() {
                navigate("/new_game", {
                    replace: false,
                });
            }, 5000);
        }
        if (loaded_detector) {
            poseNetLoop();
        }
        setTimeout(function() {
            loop();
        }, 1000 / 50)
    }

    async function poseNetLoop() {
        const video = webcamRef.current && webcamRef.current['video'];
        if (!cameraReady && !video) {
            return;
        }
        if (video.readyState < 2) {
            return;
        }

        console.log(video)
        const poses = await detector.estimatePoses(video, {
            maxPoses: 1,
            flipHorizontal: false
        });

        if (poses != null) {
            calculateAngles(poses);
        }
    }


    const calculateAngles = (poses) => {
        console.log(poses)
        for (const pose of poses) {
            if (checkScores(pose.keypoints3D) < 0.1) {
                console.log("Return")
                return;
            }
            let angles = calc3dKneeAngles(pose.keypoints3D)
            kneeAngle = pushSlidingWindow(posHistory, angles, window_size);
            analytics.repetitionCounter(kneeAngle);
        }
    }

    function checkScores(keypoints3D) {
        let sum = 0;
        let count = 0;
        for (let i = 23; i < 28; i++) {
            sum += keypoints3D[i].score;
            count++;
        }
        return sum / count;
    }


    return (
        <ContainerInstr>
            <CameraWrapper>
                <video id="video" controls loop style={{ display: "none" }}>
                    <source src={instruction_video} type="video/mp4"/>
                    <p>No support</p>
                </video>
                <Canvas id="instruction_video"></Canvas>
                <Camera
                    className="filter blur-lg"
                    ref={webcamRef}
                    style={{ visibility: "hidden" }}
                    audio={false}
                    videoConstraints={VIDEO_CONSTRAINTS}
                    onUserMediaError={onUserMediaError}
                    onUserMedia={onUserMedia}
                />
            </CameraWrapper>
            <SideBar>
                <Title>Squats</Title>
                <PoseDescription>Achte auf folgende Dinge: </PoseDescription>
                <br/>
                <PoseDescription>Deine Füße sollten mindestens Schulterbreit auseinander stehen</PoseDescription>
                <br/>
                <PoseDescription>Deine Knie sollten während der Übung nicht über deine Zehenspitzen ragen</PoseDescription>
                <br/>
                <PoseDescription>Deine Knie sollten sich nicht nach innen beugen</PoseDescription>
                <br/>
                <br/>
                <br/>
                <br/>
                <PoseDescription>Mache nun eine Wiederholung der Übung, wie im Video zu sehen, danach geht es weiter zur Übung</PoseDescription>
            </SideBar>
        </ContainerInstr>
    )
}

const ContainerInstr = styled.div`
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr;
    grid-template-columns: 0.4fr 0.6fr;
    grid-template-areas: "camera sidebar";
    transition: all 0.25s ease-in-out;
    @media (max-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0.4fr 0.6fr;
        grid-template-areas:
            "camera"
            "sidebar";
    }
`;

const Title = styled.h4`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    margin: 50px 0px 20px 20px;
    align-items: start;
    font-size: 40px;
    font-weight: 400;
`;

const PoseDescription = styled.p`
    color: rgb(88, 95, 102);
    font-family: "Roboto";
    font-weight: 350;
    font-size: 24px;
    margin-left: 20px;
`;

const SideBar = styled.div`
    grid-area: sidebar;
    overflow: hidden;
    flex-direction: column;
    margin-right: 100px
`;

export default InstructionScreen;