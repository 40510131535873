import styled from "styled-components";
import laptop from "../../../../assets/place_laptop_desk.png";
import angle from "../../../../assets/angle.png";
import { useNavigate } from "react-router-dom";

const PlaceLaptopScreen = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <MainWrapper>
                <img
                    src={laptop}
                    style={{ width: "55%", height: "70%" }}
                    alt=""
                />
            </MainWrapper>

            <SideBar>
                <Title>Platzierung des Laptop</Title>
                <div style={{ height: 40 }}></div>
                <Body>
                    Bevor Sie mit der Übung beginnen, <br /> stellen Sie sicher,
                    dass der Laptop <br />
                    korrekt platziert ist. <br />
                    <br />
                    <br />
                    In den meisten Fällen sollte ein <br />
                    <span style={{ fontWeight: "bold" }}>
                        Winkel von 80-90°
                    </span>{" "}
                    zwischen <br /> Bildschirm und Tastatur bestehen.
                </Body>
                <div style={{ height: 20 }}></div>

                <Button
                    onClick={() => {
                        navigate("/position");
                    }}
                >
                    <h3 style={{ color: "white" }}>Fortsetzen</h3>
                </Button>
            </SideBar>
        </Container>
    );
};

const Container = styled.div`
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr;
    grid-template-columns: 0.4fr 0.6fr;
    grid-template-areas: "sidebar camera";
    transition: all 0.25s ease-in-out;
    @media (max-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0.4fr 0.6fr;
        grid-template-areas:
            "camera"
            "sidebar";
    }
    background-color: linear-gradient(180deg, #ffffff 0%, #e5f8ff 100%);
`;

const SideBar = styled.div`
    background: radial-gradient(
        363.87% 138.58% at 2.13% 0%,
        rgba(102, 109, 162, 0.192) 10.04%,
        rgba(179, 216, 230, 0.114) 100%
    );
    grid-area: sidebar;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const MainWrapper = styled.div`
    grid-area: camera;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Title = styled.h4`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgb(88, 95, 102);
    font-family: "Roboto";
    margin: 15% 0px 20px 80px;
    align-items: start;
    font-size: 40px;
    font-weight: 400;
`;

const Body = styled.p`
    color: rgb(88, 95, 102);
    font-family: "Roboto";
    font-weight: 300;
    font-size: 20px;
    margin: 10px 20px 10px 80px;
    width: 65%;
`;

const Button = styled.button`
    height: 55px;
    width: 60%;
    background: #2e99c2;
    border-radius: 8px;
    border: none;
    margin: auto;
`;

export default PlaceLaptopScreen;