import * as mpPose from "@mediapipe/pose";
import * as poseDetection from '@tensorflow-models/pose-detection';

export const VIDEO_CONSTRAINTS = {
    width: 500,
    height: 480,
    facingMode: "user",
    deviceId: "",
    frameRate: { max: 60, ideal: 30 },
};

const detectorConfig = {
    runtime: 'mediapipe', // or 'tfjs'
    modelType: 'full',
    solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`
};


export async function getDetector() {
    return poseDetection.createDetector(poseDetection.SupportedModels.BlazePose, detectorConfig);
}