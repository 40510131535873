import React, {useContext} from "react";
import {ActivePauseCounterContext} from "../../App";
import triangle from '../../assets/time_circle_triangle.svg';

const num_active_pauses = 8;
const work_begin_hour = 9;

const TimeCircle = () => {
    const {apc, _} = useContext(ActivePauseCounterContext);
  const currentHour = new Date(Date.now()).getHours();

  const size = 330;
  const strokeWidth = 4;
  const radius = (size - (strokeWidth + 90)) / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const circumference = radius * Math.PI * 2;
  const dash = circumference / (num_active_pauses + 4);

  const circles = [];

  function rotation(hour) {
        return `rotate(${-105 + (45 * (hour - work_begin_hour))} ${size / 2} ${size / 2})`
  }

  function rotationIndicator(hour) {
        return `rotate(${-108 + (45 * (hour - work_begin_hour))} ${size / 2} ${size / 2})`
  }

  function rotationText(hour) {
        return `rotate(${(45 * (hour - work_begin_hour))} ${size / 2} ${size / 2})`
  }


  // grey indicator current hour -> no pause yet
    if (!apc.includes(currentHour)
        && currentHour >= work_begin_hour
        && currentHour <= work_begin_hour + num_active_pauses) {
    let addition = 15;
    let dash = circumference / (num_active_pauses + 2);
    circles.push(
      <circle
        key={"c_cir" + currentHour}
        fill={"none"}
            stroke={'rgb(217,217,217)'}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        transform={rotationIndicator(currentHour)}
        strokeDasharray={[dash, circumference - dash]}
        strokeWidth={`${strokeWidth + addition}px`}
      />
    );
    circles.push(
      <text
        key={"c_t" + currentHour}
        fontFamily={"roboto"}
        fontSize={"13px"}
        x={"50%"}
        y={"50%"}
        dy={"-150px"}
            fill={'rgb(88,95,102)'}
        transform={rotationText(currentHour)}
            textAnchor={"middle"}>
        {currentHour} - {currentHour + 1} Uhr
      </text>
    );
    circles.push(
      <image
        key={"c_img" + currentHour}
        x={"48%"}
        y={"6%"}
        transform={rotationText(currentHour)}
        width="13"
        height="13"
            href={triangle}/>)
  }

  // active pauses
  for (let i = 0; i < apc.length; i++) {
    circles.push(
      <circle
        key={"d_cir" + apc[i]}
        fill={"none"}
            stroke={'rgb(42,163,42)'}
        cx={size / 2}
        cy={size / 2}
            r ={radius}
        transform={rotation(apc[i])}
        strokeDasharray={[dash, circumference - dash]}
        strokeWidth={`${strokeWidth}px`}
        />);
  }

  // pauses left
    let workhours = Array.from({length: num_active_pauses}, (_, i) => i + work_begin_hour)
    let pausesLeft = workhours.filter(value => !apc.includes(value) && value >= currentHour);
    let pausesPassed = workhours.filter(value => !apc.includes(value) && value < currentHour);
  for (let i = 0; i < pausesLeft.length; i++) {
    circles.push(
      <circle
        key={"lef_cir" + pausesLeft[i]}
        fill={"none"}
            stroke={'rgb(255,255,255)'}
        cx={size / 2}
        cy={size / 2}
            r ={radius}
        transform={rotation(pausesLeft[i])}
        strokeDasharray={[dash, circumference - dash]}
        strokeWidth={`${strokeWidth}px`}
        />);
  }
  for (let i = 0; i < pausesPassed.length; i++) {
    circles.push(
      <circle
        key={"pas_cir" + pausesPassed[i]}
        fill={"none"}
            stroke={'rgb(198,199,219)'}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        transform={rotation(pausesPassed[i])}
        strokeDasharray={[dash, circumference - dash]}
        strokeWidth={`${strokeWidth}px`}
        />);
  }

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      {circles}
      <foreignObject
        className="node"
        x={"0%"}
        y={"25%"}
        textAnchor={"middle"}
        width={size}
                height={"200"}>
                <div style={{height: "100px"}}>
                    <span style={{
                        color:'rgb(88,95,102)',
                        fontFamily: 'Roboto',
              fontWeight: 300,
                        fontSize: "80px"
                    }}>{apc.length}</span>
                    <span style={{
                        color:'rgb(145,154,160)',
                        fontFamily: 'Roboto',
              fontWeight: 300,
                        fontSize: "23px"
                    }}>von {num_active_pauses}</span>
        </div>
                <span style={{
                    color:'rgb(88,95,102)',
                    fontFamily: 'Roboto',
            fontWeight: 300,
                    fontSize: "18px"
                }}>aktive Pausen</span>
      </foreignObject>
    </svg>
  );
};

export default TimeCircle;