import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import { Camera, CameraWrapper, Canvas, Container } from "../stretch/position";
import * as poseDetection from "@tensorflow-models/pose-detection";
import * as mpPose from "@mediapipe/pose";
import { useNavigate } from "react-router-dom";
import { WYAnalyticsBlaze } from "../../../../services/workout_analysis/workout_analytics";
import styled from "styled-components";
import instruction_video from "../../../../assets/YW_video.mov";

const VIDEO_CONSTRAINTS = {
    width: 500,
    height: 480,
    facingMode: "user",
    deviceId: "",
    frameRate: { max: 60, ideal: 30 },
};

const model = poseDetection.SupportedModels.BlazePose;
let loaded_detector = false;
let detector = null;

const detectorConfig = {
    runtime: "mediapipe", // or 'tfjs'
    modelType: "light",
    solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`,
};

const WYInstructionScreen = () => {
    const [cameraReady, setCameraReady] = useState(false);
    const webcamRef = useRef({});
    const navigate = useNavigate();
    const [analytics] = useState(new WYAnalyticsBlaze());

    useEffect(() => {
        poseDetection.createDetector(model, detectorConfig).then((det) => {
            detector = det;
            loaded_detector = true;
        });
    }, []);

    useEffect(() => {
        var canvas = document.getElementById("instruction_video");
        var ctx = canvas.getContext("2d")
        var video = document.getElementById('video');
        video.muted = true;

        video.addEventListener('loadedmetadata', function() {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
        });

        video.play();
        video.addEventListener("play", () => {
            function step() {
                ctx.drawImage(video, 0, -300, canvas.width, canvas.height);
                requestAnimationFrame(step);
            }
            requestAnimationFrame(step);
        });

        loop();
    }, []);

    const onUserMediaError = () => {
        console.log("ERROR in Camera!");
    };

    const onUserMedia = () => {
        console.log("Camera loaded!");
        setCameraReady(true);
    };

    async function loop() {
        // console.log(loaded_detector);
        if (analytics.repetitions > 0) {
            setTimeout(function () {
                navigate("/wy_game_screen", {
                    replace: false,
                });
            }, 2000);
        }
        if (loaded_detector) {
            poseNetLoop();
        }
        setTimeout(function () {
            loop();
        }, 1000 / 50);
    }

    async function poseNetLoop() {
        const video = webcamRef.current && webcamRef.current["video"];
        if (!cameraReady && !video) {
            return;
        }
        if (video !== null && video.readyState < 2) {
            return;
        }

        const poses = await detector.estimatePoses(video, {
            maxPoses: 1,
            flipHorizontal: false,
        });

        if (poses != null) {
            calculateAngles(poses);
        }
    }

    const calculateAngles = (poses) => {
        for (const pose of poses) {
            if (checkScores(pose.keypoints3D) < 0.1) {
                return;
            }
            let shelAngle = analytics.calcShElAngle(pose.keypoints3D);
            // console.log("shoulder", shelAngle);
            // posHistory.push(shelAngle);
            // console.log("length", posHistory.length);
            // if (posHistory.length > window_size) {
            //     posHistory.shift();
            //     console.log("after shift", posHistory);
            // }
            // let avgPos = 0;
            // for (let i = 0; i < posHistory.length; i++) {
            //     avgPos += posHistory[i];
            // }
            // avgPos /= posHistory.length;
            // console.log("avg pose", avgPos);
            // shoulderAngle = avgPos;
            // analytics.wyRepetitionCounter(shoulderAngle);
        }
    };

    function checkScores(keypoints3D) {
        let sum = 0;
        let count = 0;
        for (let i = 23; i < 28; i++) {
            sum += keypoints3D[i].score;
            count++;
        }
        return sum / count;
    }

    return (
        <Container>
            <CameraWrapper>
                <video id="video" controls loop style={{ display: "none" }}>
                    <source src={instruction_video} type="video/mp4"/>
                    <p>No support</p>
                </video>
                <Canvas id="instruction_video"></Canvas>
                <Camera
                    className="filter blur-lg"
                    ref={webcamRef}
                    style={{ visibility: "hidden" }}
                    audio={false}
                    videoConstraints={VIDEO_CONSTRAINTS}
                    onUserMediaError={onUserMediaError}
                    onUserMedia={onUserMedia}
                />
            </CameraWrapper>
            <SideBar>
                <Title>YW - Dehnübung</Title>
                <PoseDescription>Achte auf folgende Dinge: </PoseDescription>
                <br/>
                <PoseDescription>Du solltest stehen</PoseDescription>
                <br/>
                <PoseDescription>Dein Rücken sollte gerade/aufrecht sein</PoseDescription>
                <br/>
                <PoseDescription>Mache mit den Armen ein "W"</PoseDescription>
                <br/>
                <PoseDescription>Bewege die Ellbogen anschließend nach unten und versuche die Rippen zu berühren</PoseDescription>
                <br/>
                <PoseDescription>Presse die Schulterblätter zusammen und halte diese Position für zwei Atemzüge</PoseDescription>
                <br/>
                <PoseDescription>Mache als nächstes eine "Y"-Form, indem du beide Arme nach oben streckts, halte diese Position ebenfalls zwei Atemzüge lang</PoseDescription>
                <br/>
                <br/>
                <PoseDescription>Mache nun eine Wiederholung der Übung, wie im Video zu sehen, danach geht es weiter zur Übung</PoseDescription>
            </SideBar>
        </Container>
    );
};

const Title = styled.h4`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    margin: 50px 0px 20px 20px;
    align-items: start;
    font-size: 40px;
    font-weight: 400;
`;

const PoseDescription = styled.p`
    color: rgb(88, 95, 102);
    font-family: "Roboto";
    font-weight: 350;
    font-size: 24px;
    margin-left: 20px;
`;

const SideBar = styled.div`
    grid-area: sidebar;
    overflow: hidden;
    flex-direction: column;
    margin-right: 20px;
`;

export default WYInstructionScreen;
