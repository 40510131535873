import styled from "styled-components";
import React, {useContext, useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPause} from "@fortawesome/free-solid-svg-icons";
import * as poseDetection from '@tensorflow-models/pose-detection';
import {calc3dKneeAngles} from '../../../../services/workout_analysis/handler';
import Webcam from "react-webcam";
import {pushSlidingWindow, SquatAnalyticsBlaze} from "../../../../services/workout_analysis/workout_analytics";
import {useNavigate} from "react-router-dom";
import {useElapsedTime} from "use-elapsed-time";
import PauseModal from "../PauseModal";
import InformalModal from "../InformalModal";
import {useInformalModal, useModal} from "../../../../hooks/useModal";
import BounceLoader from "react-spinners/BounceLoader";
import Coins from "../../../../services/workout_analysis/coins";
import CoinProgress from "../progressbar-coin";
import {ActivePauseCounterContext, AnalyticsContext} from "../../../../App";
import {getScores, getCurrentUser, sortScores, storeWorkout, updateRanks} from "../../../../services/firebase";
import {
    detectorConfig, drawBall,
    getColorGradients,
    getObjectFitSize,
    model,
    override,
    toTime,
    VIDEO_CONSTRAINTS
} from "../utils";

let startInferenceTime,
    numInferences = 0;
let inferenceTimeSum = 0,
    lastPanelUpdate = 0;
let fpsSum = 0;
let counter = 0;

var kneeAngle = 0.0;

let duration = 1800;
let coinsGoal = 1000;
var fps_counter = 0;
var dispFPS = 0;

let poseNetMutex = true;
let loaded_detector = false;
let lastDiff = 101;
let outsideCount = 0;
let insideCount = 0;

let whd = 0;
let colors = [];
let posHistory = [];
let window_size = 3;
let userName = "";
let poseInstructions = "";
let experimentalMode = false;

let challenge = true;

let detector = null;
let startTime = null;

const SquatsScreen = () => {

    const [cameraReady, setCameraReady] = useState(false);
    const [displayFps, setDisplayFps] = useState(0.0);
    const [isPlaying, setIsPlaying] = useState(true);
    const webcamRef = useRef({});
    const navigate = useNavigate();
    const [analytics] = useState(new SquatAnalyticsBlaze());
    const [coins] = useState(new Coins());
    const [countdownPause, setCountdownPause] = useState(false);
    const {elapsedTime} = useElapsedTime({
        isPlaying: isPlaying,
    });
    const {isShowing, toggle} = useModal();
    const {isInfShowing, toggleInf} = useInformalModal();
    let informed = useRef(false);
    let [loading, setLoading] = useState(true);
    let {_, increaseActivePauseCounter} = useContext(ActivePauseCounterContext);
    let webAnalytics = useContext(AnalyticsContext);

    const amplitude = 200;
    const frequency = 0.0085;
    var isPaused = useRef(false);
    let previousTime;


    useEffect(() => {
        userName = localStorage.getItem("username").toString();
        startTime = Date.now();
        poseDetection.createDetector(model, detectorConfig).then((det) => {
            detector = det;
            setLoading(false);
            loaded_detector = true;
        });
        coins.fillCoordinates((Math.PI / 2) * 5, duration, Math.PI) // TODO
        webAnalytics.analytics.track("new_game")
    }, []);

    async function poseNetLoop() {
        beginEstimatePosesStats()

        const video = webcamRef.current && webcamRef.current['video'];
        if (!cameraReady && !video) {
            poseNetMutex = !poseNetMutex;
            return;
        }
        if (video.readyState < 2) {
            poseNetMutex = !poseNetMutex;
            return;
        }

        const poses = await detector.estimatePoses(video, {
            maxPoses: 1,
            flipHorizontal: false
        });

        if (poses != null) {
            calculateAngles(poses);
            endEstimatePosesStats();
        }
    }

    useEffect(() => {
        previousTime = performance.now();
        animationLoop()
    }, []);

    useEffect(() => {
        if (coins.number_coins === coinsGoal) {
            breakUp("/finish");
        }
    }, [coins.number_coins])



    async function animationLoop() {
        const currentTime = performance.now();

        const elapsedT = currentTime - previousTime;

        if (loaded_detector) {
            await poseNetLoop();
        }

        if (elapsedT >= 1000 / 25) {
            draw();
            drawColoration();
            fps_counter++;
            previousTime = currentTime;
        }

        let currentElapsedTime = (Date.now() - startTime) / 1000;
        if (Math.floor(currentElapsedTime) !== 0 && Math.floor(currentElapsedTime) % (5 * 60) === 0) {
            setCountdownPause(true)
            isPaused.current = true;
            setIsPlaying((prevIsPlaying) => !prevIsPlaying);
            toggle();
        }

        if (
            !informed.current &&
            analytics.repetitions >= 5 &&
            coins.number_coins <= 3
        ) {
            informed.current = true;
            setCountdownPause(true);
            isPaused.current = true;
            setIsPlaying((prevIsPlaying) => !prevIsPlaying);
            toggleInf();
        }
        requestAnimationFrame(animationLoop);
    }


    const onUserMediaError = () => {
        console.log('ERROR in Camera!');
    };

    const onUserMedia = () => {
        console.log('Camera loaded!');
        setCameraReady(true);
    };

    const beginEstimatePosesStats = () => {
        startInferenceTime = (performance || Date).now();
    }

    const endEstimatePosesStats = () => {
        if (isPaused.current) return;
        const endInferenceTime = (performance || Date).now();
        inferenceTimeSum += endInferenceTime - startInferenceTime;
        ++numInferences;
        const panelUpdateMilliseconds = 1000;

        if (endInferenceTime - lastPanelUpdate >= panelUpdateMilliseconds) {
            const averageInferenceTime = inferenceTimeSum / numInferences;
            inferenceTimeSum = 0;
            numInferences = 0;
            let fps = 1000 / averageInferenceTime;
            fpsSum += fps;
            counter++;
            setDisplayFps(fps);
            dispFPS = fps;
            if (counter === 30) {
                dispFPS = fpsSum / 30;
                counter = 0;
                fpsSum = 0;
                webAnalytics.trackFPS(fps);
            }
            lastPanelUpdate = endInferenceTime;
        }
    }

    const calculateAngles = (poses) => {
        for (const pose of poses) {
            if (isPaused.current) return;
            let angles = calc3dKneeAngles(pose.keypoints3D);
            kneeAngle = pushSlidingWindow(posHistory, angles, window_size);
            analytics.repetitionCounter(kneeAngle);
            if (experimentalMode) checkPosture(poses);
        }
    }

    function checkPosture(poses) {
        if (analytics.kneeOverFeetCount + analytics.kneeInsideCount + analytics.badBackCount === 0) poseInstructions = "";
        analytics.checkPosture(poses[0].keypoints3D);
        poseInstructions = analytics.getPostureInstructions();

        if (poseInstructions !== "") {
            setCountdownPause(true);
            isPaused.current = true;
            setIsPlaying((prevIsPlaying) => !prevIsPlaying);
            toggle();
        }
    }

    //////////////////////////////////////////

    function wavePath(x, height) {
        return height * 0.5 - (Math.cos(Math.cos(-x) * 0.35 + x) * amplitude)
    }

    var time = 0;
    let time_step = 0.11;
    let colCanvas = null;

    function drawColoration() {
        if (colCanvas == null) {
            colCanvas = document.getElementById("myColorationCanvas");
        }

        let [greenGradient, redGradient, ctx] = getColorGradients(colCanvas);
        let x = drawWavePath(ctx, redGradient, greenGradient);
        drawPoint(ctx, x, redGradient, greenGradient);
        drawCoins(ctx);

    }

    function drawWavePath(ctx, redGradient, greenGradient) {
        //// colored path
        ctx.beginPath();
        let x = 0;
        let len = colors.length
        for (let j = -1; j <= colCanvas.width * 0.3; j++) {
            x = time + j * frequency;
            ctx.lineTo(j, wavePath(x, colCanvas.height));
            if (len === 0) {
                colors.push("green")
            } else {
                ctx.lineWidth = 40;
                if (j === -1) continue
                if (colors[j] === "green") {
                    ctx.strokeStyle = greenGradient
                } else if (colors[j] === "red") {
                    ctx.strokeStyle = redGradient
                }
                ctx.stroke()
                ctx.beginPath();
                ctx.lineTo(j, wavePath(x, colCanvas.height));
            }
        }
        return x;
    }

    function drawPoint(ctx, x, redGradient, greenGradient) {
        let aIStart = 150;
        let aIEnd = 65;
        let intervalDistance = Math.abs(aIStart - aIEnd);
        let min = colCanvas.height * 0.5 - amplitude;
        let max = colCanvas.height * 0.5 + amplitude;
        let mappedAngle = -730 + (1460 / intervalDistance) * (kneeAngle - aIEnd);
        let pH = 1 / (0.002 + 0.002 * Math.exp(0.003 * mappedAngle)) + min;
        if (pH > (max + max * 0.05)) {
            pH = (max + max * 0.05);
        }
        if (pH < (min - min * 0.05)) {
            pH = (min - min * 0.05)
        }
        if (isPaused.current) return pH;
        whd++;
        let wP = wavePath(x, colCanvas.height);
        let diff = wP - pH;
        if (Math.abs(diff) > 110) {
            outsideCount++;
            if (outsideCount >= 15) insideCount = 0
        } else {
            if (lastDiff > 110) {
                lastDiff = diff;
            }
            insideCount++;
            if (insideCount >= 30) outsideCount = 0
        }
        if (insideCount > 30) {
            pH = wP - lastDiff;
            lastDiff = lastDiff - lastDiff / 20;
            ctx.strokeStyle = greenGradient;
            for (let i = 0; i < 10; i++) {
                colors.shift()
                colors.push("green")
            }
            coins.checkCoins(x, pH, wP, time_step) // optimization potential
        } else if (outsideCount > 15) {
            ctx.strokeStyle = redGradient;
            for (let i = 0; i < 10; i++) {
                colors.shift()
                colors.push("red")
            }
        }
        //angle point
        drawBall(ctx, pH, colCanvas.width, colCanvas.height, ctx.strokeStyle === redGradient);
        return pH;
    }

    function drawCoins(ctx) {
        const coinGrad = ctx.createLinearGradient(
            0,
            0,
            colCanvas.width / 2,
            colCanvas.height / 2
        );
        coinGrad.addColorStop(0, "rgb(255,240,0)");
        coinGrad.addColorStop(0.5, "rgb(255,254,0)");

        let currentCoins = coins.getCurrentCoordinates(time, time + colCanvas.width * frequency) // TODO: optimization potential
        for (let coinX of currentCoins) {
            ctx.beginPath();
            ctx.arc((coinX - time) / frequency, wavePath(coinX, colCanvas.height), 12, 0, 2 * Math.PI);
            ctx.strokeStyle = "rgba(219,204,39,0.35)";
            ctx.fillStyle = coinGrad;
            ctx.lineWidth = 16;
            ctx.stroke();
            ctx.fill();
        }
    }


    function draw() {
        if (!isPaused.current) {
            time = time + time_step;
        }
        var canvas = document.getElementById("myCanvas");
        const originalHeight = canvas.height;
        const originalWidth = canvas.width;
        const dimensions = getObjectFitSize(
            true,
            canvas.clientWidth,
            canvas.clientHeight,
            canvas.width,
            canvas.height
        );
        canvas.width = dimensions.width;
        canvas.height = dimensions.height;

        var ctx = canvas.getContext("2d");
        let ratio = Math.min(
            canvas.clientWidth / originalWidth,
            canvas.clientHeight / originalHeight
        );

        ctx.scale(ratio, ratio);
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        //// Mid line
        ctx.beginPath();
        for (let j = -1; j <= canvas.width; j++) {
            let x = time + j * frequency
            ctx.lineTo(j, wavePath(x, canvas.height));
        }
        ctx.lineWidth = 40;
        ctx.strokeStyle = "#CADADF";
        ctx.stroke();
    }



    function resume() {
        isPaused.current = false;
        setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    }

    function sendWorkoutEndStatistics(coinGoal) {
        webAnalytics.trackWorkoutDone(
            analytics.repetitions,
            elapsedTime.toFixed(1),
            coinGoal,
            analytics.angleIntervalStart,
            analytics.angleIntervalEnd,
            analytics.avgKneeAngle(),
            analytics.minToeKneeDistance,
            analytics.maxToeKneeDistance,
            analytics.avgToeKneeDistance(),
        )
    }

    async function breakUp(route) {
        increaseActivePauseCounter();
        sendWorkoutEndStatistics(true);

        let args = {
            time: toTime((((Date.now() - startTime) / 1000)).toFixed(2)),
            title: "Squatchallenge",
            repititions: analytics.repetitions,
            points: coins.number_coins
        }

        if (challenge) {
            let score = coins.number_coins;
            let duration = (((Date.now() - startTime) / 1000) / 60).toFixed(1);

            getCurrentUser().then((res) => {
                storeWorkout(score, duration).then((r) => {
                    // calc new Rank
                    //if new Rank != currentRank
                    let newRank = -1;
                    getScores().then(r => {
                        newRank = sortScores(r).findIndex(s => s.userName === userName);
                        if (newRank !== -1) {
                            newRank += 1;
                        }
                        if (newRank !== res.currentRank && newRank !== -1) {
                            updateRanks(newRank, res.currentRank).then(r => console.log(r));
                        }
                    });
                }).then();
            });
        }

        setLoading(!loading);
        setTimeout(function () {
            setLoading(!loading);
            navigate(route, {
                replace: false,
                state: args !== null || args !== undefined ? args : null,
            });
        }, 3000);
    }


    return (
        <div style={{width: "100%", height: "100%"}}>
            <CamDiv>
                <Webcam
                    className="filter blur-lg"
                    style={{visibility: "hidden"}}
                    ref={webcamRef}
                    audio={false}
                    videoConstraints={VIDEO_CONSTRAINTS}
                    onUserMediaError={onUserMediaError}
                    onUserMedia={onUserMedia}
                />
            </CamDiv>
            <Canvas id="myCanvas" style={{objectFit: "contain"}}></Canvas>
            <ColorationCanvas
                id="myColorationCanvas"
                style={{objectFit: "contain"}}
            ></ColorationCanvas>
            <Canvas id="videoCanvas" style={{visibility: "hidden"}}></Canvas>

            <SemiCircleDiv>

                <ExerciseDetails>Squats</ExerciseDetails>
                <span>{dispFPS.toFixed(2)}</span>
                <CoinProgress completed={(coins.number_coins / coinsGoal) * 100}
                              numberCoins={coins.number_coins}></CoinProgress>
            </SemiCircleDiv>
            <RepCircleDiv>
                <Reps>{analytics.repetitions}</Reps>
                <Wdh>Wdh.</Wdh>
            </RepCircleDiv>
            <div>
                <PauseDiv onClick={() => {
                    setCountdownPause(false);
                    isPaused.current = true;
                    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
                    toggle();
                }}>
                    <Butt type={"button"}>
                        <IconSpan>
                            <FontAwesomeIcon
                                icon={faPause}
                            />
                        </IconSpan>
                        <TxtSpan>Pause</TxtSpan>
                    </Butt>
                </PauseDiv>
                <ExperimentalDiv type={"button"} onClick={() => {
                    experimentalMode = !experimentalMode
                }}>
                    <TxtSpan>Experimental Mode: {experimentalMode.toString()}</TxtSpan>
                </ExperimentalDiv>
            </div>
            <PauseModal
                isShowing={isShowing}
                hide={toggle}
                hideCallback={resume}
                finishCallback={() => breakUp("/finish")}
                poseInstructions={poseInstructions}
                countdown={countdownPause}
            />
            <InformalModal
                isShowing={isInfShowing}
                hide={toggleInf}
                hideCallback={resume}
                finishCallback={() => breakUp("/finish")}
                countdown={countdownPause}
            />
            <BounceLoader
                cssOverride={override}
                loading={loading}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
                color="hsla(168, 67%, 53%, 1)"
            />
        </div>
    );
};

const Canvas = styled.canvas`
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #ffffff 0%, #eaf9ff 100%);
    position: absolute;
    overflow: hidden;
`;
const ColorationCanvas = styled.canvas`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
`;

const SemiCircleDiv = styled.div`
    position: absolute;
    width: 450px;
    height: 180px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -15%);

    background-color: transparent;
    border-bottom-left-radius: 210px;
    border-bottom-right-radius: 210px;
    border: 3px transparent #e3e3e3;
    border-top: 0;
    flex-direction: column;
    justify-content: end;
    text-align: center;
`;

const RepCircleDiv = styled.div`
    position: absolute;
    width: 150px;
    height: 150px;
    top: 3%;
    right: 3%;

    background-color: white;
    border-radius: 100px;
    border: 3px solid #e3e3e3;
    flex-direction: column;
    justify-content: end;
`;


const ExerciseDetails = styled.h6`
    background-clip: text;
    -webkit-background-clip: text;
    color: #585f66;
    font-family: "Roboto";
    text-align: center;
    font-size: 25px;
    font-weight: 200;
`;

const Reps = styled.h4`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    margin-top: 30%;
`;

const Wdh = styled.h6`
    background-clip: text;
    -webkit-background-clip: text;
    color: #585f66;
    font-family: "Roboto";
    text-align: center;
    font-size: 20px;
    font-weight: 200;
`;

const PauseDiv = styled.div`
    position: absolute;
    left: 20px;
    top: 10px;
`;

const ExperimentalDiv = styled.div`
    position: absolute;
    left: 20px;
    bottom: 10px;
`;

const CamDiv = styled.div`
    position: absolute;
    right: 20px;
    top: 10px;
`;

const Butt = styled.button`
    display: flex;
    height: 40px;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    border-radius: 5px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
`;

const IconSpan = styled.span`
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    color: #0796ee;
    height: 100%;
    font-size: 1.5em;
`;

const TxtSpan = styled.span`
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    color: #0796ee;
    height: 100%;
`;

export default SquatsScreen;
