import { useState } from "react";

export const useModal = () => {
    const [isShowing, setIsShowing] = useState(false);

    function toggle() {
        setIsShowing(!isShowing);
    }

    return {
        isShowing,
        toggle,
    };
};

export const useInformalModal = () => {
    const [isInfShowing, setIsInfShowing] = useState(false);

    function toggleInf() {
        setIsInfShowing(!isInfShowing);
    }

    return {
        isInfShowing,
        toggleInf,
    };
};


