// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {getDatabase, onValue, push, ref, update} from "firebase/database";
import {getMessaging, getToken, onMessage} from "firebase/messaging";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyATV76ndTnnmeKQuS_cZUHfgzPDuNihtZk",
    authDomain: "sportify-27a13.firebaseapp.com",
    databaseURL: "https://sportify-27a13.firebaseio.com",
    projectId: "sportify-27a13",
    storageBucket: "sportify-27a13.appspot.com",
    messagingSenderId: "727112228694",
    appId: "1:727112228694:web:56d8a35ba9359a622bc0f7",
    measurementId: "G-CJ8YN8ENCB"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = getDatabase(firebaseApp);
const auth = firebase.auth();

export { auth, db };


export const getCurrentUser = () => {
    const userId = localStorage.getItem("userId");

    return new Promise((resolve, reject) => {
        onValue(ref(db, `users/${userId}`), (snapshot) => {
            const user = snapshot.val();
            if (user) {
                const currentUser: User = toUser(user);
                resolve(currentUser);
            } else {
                reject(Error("User not found"));
            }
        });
    });
};

export const getCurrentRank = () => {
    let username = localStorage.getItem("username");
    return new Promise((resolve, reject) => {
        getScores().then((scores) => {
            scores.sort((a, b) => {
                const aScore = a.score?.reduce((acc, val) => acc + val, 0) || 0;
                const bScore = b.score?.reduce((acc, val) => acc + val, 0) || 0;
                return bScore - aScore;
            });
            const index = scores.findIndex((elem) => elem.userName === username);
            resolve(index + 1);
        });
    })
};

export const getScores = () => {
    return new Promise((resolve, reject) => {
        onValue(ref(db, "users"), (snapshot => {
            const data = snapshot.val();
            const scores = [];

            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const user = data[key];
                    scores.push(toUser(user));
                }
            }
            resolve(scores);
        }))
    });
};


export const storeWorkout = (score, duration) => {
    let userId = localStorage.getItem("userId");
    console.log(userId);
    return new Promise((resolve, reject) => {
        push(ref(db, `users/${userId}/duration`), duration).then(r =>
            push(ref(db, `users/${userId}/score`), score).then(r =>
                push(ref(db, `users/${userId}/workouts`), Date.now()).then(r => resolve(r))
            ))});
};

export const storeNotificationToken = (token) => {
    let userId = localStorage.getItem("userId");
    console.log(userId);
    if (userId === undefined || userId === null) {
        return
    }
    return new Promise((resolve, reject) => {
        update(ref(db, `users/${userId}/`), {token: token}).then(r =>
            resolve(r)
        )});
};

export const updateRanks = (newRank, currentRank) => {
    let userId = localStorage.getItem("userId");
    console.log(userId);
    return new Promise((resolve, reject) => {
        if (newRank !== currentRank) {
            update(ref(db, `users/${userId}/`), {currentRank: newRank}).then((r) => {
                update(ref(db, `users/${userId}/`), {lastRank: currentRank}).then((r) => {
                    resolve(r);
                });
            });
        }
    });
};




const messaging = getMessaging(firebaseApp);
export let registrationToken = "";
const topic = "ExerciseReminder";
const FIREBASE_API_KEY =
    "AAAAqUtEH1Y:APA91bGMgWPAuf_d2Cnxa7R7T9HYrLOvo2PCDN5hg9xeTAZgsY1ZAVsp2h8hTkGmot8hMEfbt-cNbjbymSK9vttTJtl9_epDFR2pFykmCoMUyoxZWBVmeu_koL0j8gHiR14YIVwz8GG_";

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, {
        vapidKey:
            "BGacREnWYznt_ociHsD47roRvYfAiQHvA2VMzYdR3cssR2jBQ3YOlFkmOfphIw3wVA6SrklJFb-jTEhghyjvptc",
    })
        .then((currentToken) => {
            if (currentToken) {
                console.log("current token for client: ", currentToken);
                setTokenFound(true);
                registrationToken = currentToken;
                //subscribeTokenToTopic(currentToken);
                storeNotificationToken(currentToken).then(r => console.log("Stored token"))
                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                console.log(
                    "No registration token available. Request permission to generate one."
                );
                setTokenFound(false);
                // shows on the UI that permission is required
            }
        })
        .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            // catch error while creating client token
        });
};

export const subscribeTokenToTopic = async (token) => {
    fetch(
        "https://iid.googleapis.com/iid/v1/" + token + "/rel/topics/" + topic,
        {
            method: "POST",
            headers: new Headers({
                Authorization: "key=" + FIREBASE_API_KEY,
            }),
        }
    )
        .then((response) => {
            if (response.status < 200 || response.status >= 400) {
                throw (
                    "Error subscribing to topic: " +
                    response.status +
                    " - " +
                    response.text()
                );
            }
            console.log('Subscribed to "' + topic + '"');
        })
        .catch((error) => {
            console.error(error);
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });



const getFieldsFromUser = (user) => {
    let workouts = undefined;
    let durations = undefined;
    let score = undefined;
    if (user.duration !== undefined && user.workouts !== undefined && user.score !== undefined) {
        workouts = Object.keys(user.workouts).map(function(key){
            return user.workouts[key];
        });
        durations = Object.keys(user.duration).map(function(key){
            return user.duration[key];
        });
        score = Object.keys(user.score).map(function(key){
            return user.score[key];
        });
    }
    return [workouts, durations, score];
}

function toUser(user) {
    let fields = getFieldsFromUser(user);
    let workouts = fields[0];
    let durations = fields[1];
    let score = fields[2];
    return {
        userName: user.username,
        score: score,
        workouts: workouts,
        duration: durations,
        currentRank: user.currentRank,
        lastRank: user.lastRank
    };
}


export function sortScores(arr) {
    arr.sort((a, b) => {
        const aScore = a.score?.reduce((acc, val) => acc + val, 0) || 0;
        const bScore = b.score?.reduce((acc, val) => acc + val, 0) || 0;
        return bScore - aScore;
    });
    return arr;
}