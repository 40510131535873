import React, { useState } from "react";
import Button from "@mui/material/Button";
import styled from "styled-components";
import deskImage from "../../../assets/desk.png";
import { signInWithEmailAndPassword } from "firebase/auth";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {auth, db, getScores} from "../../../services/firebase";
import { useNavigate } from "react-router-dom";
import { addDoc, collection } from "firebase/firestore";
import {onValue, ref, set} from "firebase/database";

const RegisterScreen = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorText, setErrorText] = useState("");
    const [isFirstScreen, setIsFirstScreen] = useState(true);
    const [showPassword, setshowPassword] = useState(false);

    const onRegister = (e) => {
        auth
            .createUserWithEmailAndPassword(email, password)
            .catch((error) => {
                setErrorText(error.message);
            }).then((user) => {
                var userId = user.user.uid;
            getScores().then(scores => {
                    let rank = scores.length + 1;
                    set(ref(db, `users/${userId}`), {
                        username: username,
                        currentRank: rank,
                    lastRank: rank
                }).then(r => console.log("Username stored"))
                    signInWithEmailAndPassword(auth, email, password)
                        .then((userCredential) => {
                            // Signed in
                            const user = userCredential.user;
                            var userId = user.uid;
                            // Get the username from the database
                        onValue(ref(db, `users/${userId}/username`),
                                (snapshot) => {
                                    // Get the username
                                    var username = snapshot.val();

                                localStorage.setItem("username", username.toString());
                                localStorage.setItem("userId", userId.toString())
                                    // Show a success message
                                    alert(`Welcome back, ${username}!`);
                                navigate('/home_challenge', {state: {username}});
                            })
                        })
                        .catch((error) => {
                            const errorMessage = error.message;
                            setErrorText(errorMessage);
                        });
            })

                localStorage.setItem("username", username.toString());
                localStorage.setItem("userId", userId.toString());

                // Show a success message
                alert("Your account has been created!");
            });
    };

    const nextScreen = () => {
        if (username.length > 0) {
            setIsFirstScreen(false);
        } else {
            setErrorText("Benutzername ist erforderlich");
        }
    };

    const updateUsername = (username) => {
        setErrorText("");
        setUsername(username);
    };

    // async function addUserWeb(user) {
    //     try {
    //         const docRef = await addDoc(collection(db, "user_web"), {
    //             user: user,
    //         });
    //         console.log("Document written with ID: ", docRef.id);
    //     } catch (e) {
    //         console.error("Error adding document: ", e);
    //     }
    // }
    return (
        <Background>
            <Main>
                <WelcomeDiv>Wilkommen,</WelcomeDiv>
                {isFirstScreen ? (
                    <SubHeadDiv>
                        Bitte wählen Sie einen Benutzernamen.
                    </SubHeadDiv>
                ) : (
                    <SubHeadDiv>
                        Bitte registrieren Sie sich mit einer Email Adresse.
                    </SubHeadDiv>
                )}
                {isFirstScreen ? (
                    <form
                        style={{
                            marginTop: "8%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div className="form-group">
                            <label
                                htmlFor="InputUsername"
                                style={{ color: "#585F66", marginLeft: "15%" }}
                            >
                                Benutzername
                            </label>
                            <LoginInput
                                key={"username"}
                                onChange={(e) => updateUsername(e.target.value)}
                            ></LoginInput>
                        </div>

                        <Button
                            variant="outlined"
                            style={{
                                textTransform: "none",
                                borderRadius: "8px",
                                width: "50%",
                                background: "#2E99C2",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "7%",
                                height: "60px",
                                color: "#fff",
                            }}
                            onClick={nextScreen}
                        >
                            Weiter
                        </Button>
                        <small
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "10px",
                                color: "red",
                            }}
                        >
                            {errorText}
                        </small>
                    </form>
                ) : (
                    <form
                        style={{
                            marginTop: "8%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div className="form-group">
                            <label
                                htmlFor="InputEmail"
                                style={{ color: "#585F66", marginLeft: "15%" }}
                            >
                                Email
                            </label>
                            <LoginInput
                                key={"email"}
                                onChange={(e) => setEmail(e.target.value)}
                            ></LoginInput>
                        </div>

                        <div className="form-group" style={{ marginTop: "3%" }}>
                            <label
                                htmlFor="InputPassword"
                                style={{ color: "#585F66", marginLeft: "15%" }}
                            >
                                Password
                            </label>
                            <LoginInput
                                key={"password"}
                                onChange={(e) => setPassword(e.target.value)}
                                type={showPassword ? "text" : "password"}
                            ></LoginInput>
                            <div style={{ marginLeft: "15%" }}>
                                <label style={{ color: "#585F66" }}>
                                    <input
                                        type="checkbox"
                                        style={{ marginRight: "5px" }}
                                        onChange={(e) =>
                                            setshowPassword(
                                                (prevState) => !prevState
                                            )
                                        }
                                    />
                                    Show password
                                </label>
                            </div>
                        </div>
                        <Button
                            variant="outlined"
                            style={{
                                textTransform: "none",
                                borderRadius: "8px",
                                width: "50%",
                                background: "#2E99C2",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "7%",
                                height: "60px",
                                color: "#fff",
                            }}
                            onClick={onRegister}
                        >
                            Registrierung abschließen
                        </Button>
                        <small
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginTop: "10px",
                                color: "red",
                            }}
                        >
                            {errorText}
                        </small>
                    </form>
                )}

                <div
                    style={{
                        textAlign: "left",
                        marginLeft: "70px",
                        margin: "auto",
                    }}
                >
                    <a
                        href="datenschutz"
                        style={{ fontSize: "10px", marginRight: "20px" }}
                    >
                        Datenschutzerklärung
                    </a>
                    <a href="impressum" style={{ fontSize: "10px" }}>
                        Impressum
                    </a>
                </div>
            </Main>
            <SideBar>
                <Logo>
                    <img src={deskImage} alt="" width="100%" />
                </Logo>
            </SideBar>
            <Version>V0.12</Version>
        </Background>
    );
};

const Background = styled.div`
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr;
    grid-template-columns: 0.35fr 0.65fr;
    grid-template-areas: "sidebar main";
    text-align: center;
    transition: all 0.25s ease-in-out;
    @media (max-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0.4fr 0.6fr;
        grid-template-areas:
            "sidebar"
            "main";
    }
    background: radial-gradient(
        363.87% 138.58% at 2.13% 0%,
        rgba(102, 109, 162, 0.2) 5%,
        rgba(179, 216, 230, 1) 100%
    );
`;
const Container = styled.div`
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr;
    grid-template-columns: 0.35fr 0.65fr;
    grid-template-areas: "sidebar main";
    text-align: center;
    transition: all 0.25s ease-in-out;
    @media (max-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0.4fr 0.6fr;
        grid-template-areas:
            "sidebar"
            "main";
    }
    color: white;
`;

const Main = styled.main`
    background: transparent;
    grid-area: main;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    justify-content: flex-start;
    text-align: start;
    color: white;
`;
const SideBar = styled.div`
    background: transparent;
    grid-area: sidebar;
    overflow-y: scroll;
    color: white;
`;
const Logo = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
`;
const BlueDiv = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 17vh;
    background: #b8dcea;
`;
const HoursDiv = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    padding-top: 7%;
    left: 0;
    right: 0;
`;

const WelcomeDiv = styled.div`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    margin: 30px 0px 5px 60px;
    font-size: 64px;
    font-weight: 300;
    display: flex;
    margin-left: 15%;
`;

const SubHeadDiv = styled.div`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 0.6);
    font-family: "Roboto";
    font-weight: 300;
    margin: 5px 0px 5px 60px;
    font-size: 32px;
    display: flex;
    margin-left: 15%;
`;

const NeuHier = styled.div`
    background-clip: text;
    -webkit-background-clip: text;
    color: #666666;
    font-family: "Roboto";
    font-weight: 300;
    margin: 5px 0px 5px 60px;
    font-size: 64px;
    display: flex;
    margin-top: 10%;
    margin-left: 15%;
`;

const Version = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: black;
    font-size: 12px;
`;

const LoginInput = styled.input`
    display: block;
    width: 60%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-left: 15%;
`;

export default RegisterScreen;
