import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {ExerciseCardChallenge} from "./challenge_exercise_card";
import deskImage from "../../../assets/ZP_Sitzen_Unterbrechen_3.svg";
import "../leaderboard/leaderboard.css";
import { IconButton, Tooltip } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { getAuth, signOut } from "firebase/auth";
import UserRankings from "../leaderboard/user_rankings";
import {getScores, sortScores} from "../../../services/firebase";

const HomeChallenge = () => {
    const navigate = useNavigate();
    let username = localStorage.getItem("username");
    const [bestScores, setBestScores] = useState([]);
    const [rank, setRanks] = useState([]);
    const [myRank, setMyRank] = useState(0);

    useEffect(() => {
        setBestScores([])
        getScores().then(r => {
            let result = getNeighboringEntries(r, username);
            if (result != null) {
                let res = result[0];
                let ranks = result[1];
                setRanks(ranks);
                setBestScores(res);
                console.log(res);
            }
        });
    }, [])

    let currentUser = username;

    const exercises = [
        {
            id: 1,
            title: "Squat Challenge",
            body: "Stärken Sie Ihre Muskeln mit dieser effektiven Kraftübung für die Beine.",
            type: "Kraft",
            duration: "2 min",
            path: "/place_laptop"
        },
    ];


    function getNeighboringEntries(arr, username) {
        arr = sortScores(arr);
        const index = arr.findIndex((elem) => elem.userName === username);
        if (index === -1) {
            return null;
        }
        setMyRank(index + 1);

        const result = [];
        const rank = [];

        if (index === 0) {
            result.push(arr[0]);
            rank.push(1);
            if (arr.length >= 2) {
                result.push(arr[1]);
                rank.push(2);
            }
            if (arr.length >= 3) {
                result.push(arr[2]);
                rank.push(3);
            }
        } else if (index === arr.length - 1) {
            if (arr.length >= 3) {
                result.push(arr[arr.length - 3]);
                rank.push(arr.length - 2);
            }

            if (arr.length >= 2) {
                result.push(arr[arr.length - 2]);
            }
            if (arr.length >= 2) {
                rank.push(arr.length - 1);
            }
            result.push(arr[arr.length - 1]);
            rank.push(arr.length);
        } else {
            result.push(arr[index - 1]);
            result.push(arr[index]);
            result.push(arr[index + 1]);
            rank.push(index);
            rank.push(index + 1);
            rank.push(index + 2);
        }

        return [result, rank];
    }

    const onLogout = (e) => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                localStorage.removeItem("username");
                localStorage.removeItem("userId");
                navigate(0);
            })
            .catch((error) => {
                alert("Logout not possible " + error)
            });
    };

    return (
        <Container>
            <Logout>
                <Tooltip title="Logout">
                    <IconButton aria-label="logout">
                        <LogoutIcon
                            style={{ fontSize: "30px" }}
                            onClick={onLogout}
                        />
                    </IconButton>
                </Tooltip>
            </Logout>
            <Main>
                <HalloDiv>Hallo, {currentUser}</HalloDiv>
                <SubHeadDiv>Zeit für eine Squat Challenge</SubHeadDiv>
                <div style={{marginTop: "auto"}}>
                    <div style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginLeft: "60%",
                        marginTop: "20px"}}>
                        <img src={deskImage} alt="desk" style={{ height: "15vh" }} />
                    </div>
                    <ExerciseList>
                        {exercises.map((exercise) => (
                            <ExerciseCardChallenge
                                key={exercise.id}
                                title={exercise.title}
                                body={exercise.body}
                                type={exercise.type}
                                duration={exercise.duration}
                                path={exercise.path}
                            />
                        ))}
                    </ExerciseList>
                </div>
            </Main>
            <SideBar>
                <div style={{width: "100%", marginLeft: "50px", float: "center", marginRight: "112px", marginBottom: "60px"}}>
                    <div className="leaderboard-background-home">
                        <div className="leaderboard-ranking">Sie sind auf Platz {myRank}!</div>
                        <div className="leaderboard-challenge">Machen Sie eine Challenge um aufzuholen</div>
                        <table className="leaderboard-table">
                            <thead>
                                <tr>
                                    <th>Rang</th>
                                    <th>Spieler</th>
                                    <th>Punkte</th>
                                    <th>Evolution</th>
                                </tr>
                            </thead>
                            <tbody>
                            {bestScores.map((value, index) => {
                                return <UserRankings value={value} username={username} rank={index + rank[0]}></UserRankings>
                            })}
                            </tbody>
                        </table>
                    </div>
                    <MoreButton onClick={() =>
                            navigate(`/leaderboard`, {
                            state: { currentUser }
                        })}>Vollständiges Ranking &rarr;</MoreButton>
                </div>
            </SideBar>
        </Container>
    );
};

const Container = styled.div`
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr;
    grid-template-columns: 0.45fr 0.55fr;
    grid-template-areas: "main sidebar";
    text-align: center;
    transition: all 0.25s ease-in-out;
    @media (max-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0.6fr 0.4fr;
        grid-template-areas:
            "main"
            "sidebar";
    }
    color: white;
`;

const Logout = styled.div`
    position: fixed;
    top: 10px;
    right: 10px;
`;

const Main = styled.main`
    background: linear-gradient(180deg, #ffffff 0%, #e4f8ff 100%);
    color: white;
    grid-area: main;
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const SideBar = styled.div`
    background: linear-gradient(180deg, #ffffff 0%, #e4f8ff 100%);
    grid-area: sidebar;
    align-items: end;
    display: flex;
    justify-content: center;
`;

const HalloDiv = styled.div`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    margin: 60px 0px 5px 70px;
    font-size: 3.9vw;
    font-weight: 300;
    display: flex;
`;

const ExerciseList = styled.ul`
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: 1fr;
    grid-gap: 0px;
    justify-content: start;
    margin-bottom: 60px;
    margin-top: auto;
    cursor: pointer;
    justify-content: center;
    margin-left: 30px;
`;

const MoreButton = styled.button`
    background: #D9ECF2;
    width: 100%;
    height: 90px;
    color: #626970;
    font-family: Roboto;
    font-weight: 400;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: auto;
    display: flex;
    justify-content: right;
    align-items: center;
`;

const SubHeadDiv = styled.div`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 0.6);
    font-family: "Roboto";
    font-weight: 300;
    margin: 0px 0px 0px 70px;
    font-size: 2.0vw;
    display: flex;
`;



export default HomeChallenge;
