import React, {useContext, useState, useRef, useEffect} from "react";
import Webcam from "react-webcam";
import {Camera, CameraWrapper, Canvas, Container} from "./position";
import * as poseDetection from "@tensorflow-models/pose-detection";
import * as mpPose from "@mediapipe/pose";
import {useNavigate} from "react-router-dom";
import {SquatAnalytics} from "../../../../services/workout_analysis/workout_analytics";
import styled from "styled-components";
import {calc3dKneeAngles} from "../../../../services/workout_analysis/handler";
import instruction_video from "../../../../assets/upperbody_video.mov";

const VIDEO_CONSTRAINTS = {
    width: 500,
    height: 480,
    facingMode: "user",
    deviceId: "",
    frameRate: { max: 60, ideal: 30 },
};

const InstructionScreenStretch = () => {
    const webcamRef = useRef({});
    const navigate = useNavigate();


    useEffect(() => {
        var canvas = document.getElementById("instruction_video");
        var ctx = canvas.getContext("2d")
        var video = document.getElementById('video');
        video.muted = true;

        video.addEventListener('loadedmetadata', function() {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
        });

        video.play()
        video.addEventListener("play", () => {
            function step() {
                ctx.drawImage(video, 0, -100, canvas.width, canvas.height);
                requestAnimationFrame(step);
            }
            requestAnimationFrame(step);
        });

    }, [])

    useEffect(() => {
        setTimeout(function() {
            navigate("/new_game_stretch", {
                replace: false,
            });
        }, 12000);
    }, [])


    return (
        <ContainerInstr>
            <CameraWrapper>
                <video id="video" controls loop style={{ display: "none" }}>
                    <source src={instruction_video} type="video/mp4"/>
                    <p>No support</p>
                </video>
                <Canvas id="instruction_video"></Canvas>
            </CameraWrapper>
            <SideBar>
                <Title>Oberkörperdehnen</Title>
                <PoseDescription>Achte auf folgende Dinge: </PoseDescription>
                <br/>
                <PoseDescription>Dehne dich nur soweit, dass es nicht schmerzt</PoseDescription>
                <br/>
                <PoseDescription>Versuche langsame und kontinuierliche Bewegungen zu machen</PoseDescription>
                <br/>
                <PoseDescription>Vermeide schnelle, ruckartige Bewegungen</PoseDescription>
            </SideBar>
        </ContainerInstr>
    )
}

const ContainerInstr = styled.div`
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr;
    grid-template-columns: 0.4fr 0.6fr;
    grid-template-areas: "camera sidebar";
    transition: all 0.25s ease-in-out;
    @media (max-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0.4fr 0.6fr;
        grid-template-areas:
            "camera"
            "sidebar";
    }
`;

const Title = styled.h4`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    margin: 50px 0px 20px 20px;
    align-items: start;
    font-size: 40px;
    font-weight: 400;
`;

const PoseDescription = styled.p`
    color: rgb(88, 95, 102);
    font-family: "Roboto";
    font-weight: 350;
    font-size: 24px;
    margin-left: 20px;
`;

const SideBar = styled.div`
    grid-area: sidebar;
    overflow: hidden;
    flex-direction: column;
    margin-right: 20px
`;

export default InstructionScreenStretch;