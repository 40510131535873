import React from 'react';
import PropTypes from 'prop-types';
import {formatNumber} from "./leaderboard";

const SCORE_COLORS = {
    1: '#2E99C2',
    2: '#666DA2',
    3: '#332D52'
};

// Best Three users inside Leaderboard
const BestThreeRankings = ({ username, score, place }) => {
    const scoreColor = SCORE_COLORS[place] || 'transparent';

    const scoreStyle = {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '4vw',
        lineHeight: '80px',
        textAlign: 'center',
        color: '#FFFFFF',
        backgroundColor: scoreColor,
        height: place === 1 ? 170 : place === 2 ? 140 : 110,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-end',
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "10px"
    };

    const usernameStyle = {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '21px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: '#23272A',
        backgroundColor: 'rgba(46, 153, 194, 0.29)'
    };

    const placeStyle = {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 75,
        lineHeight: '88px',
        textAlign: 'center',
        color: '#666DA2'
    };

    const userScoreStyle = {
        marginLeft: "20px",
        marginRight: "20px",
    };

    const pStyle = {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '3.0vw',
        lineHeight: '75px',
        textAlign: 'center',
        color: '#FFFFFF',
    };

    return (
        <div style={userScoreStyle}>
            <div style={placeStyle}>{place}</div>
            <div style={usernameStyle}>{username}</div>
            <div style={scoreStyle}>
                {formatNumber(score)}
                <span style={pStyle}> p.</span>
            </div>
        </div>
    );
};

BestThreeRankings.propTypes = {
    username: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    place: PropTypes.oneOf([1, 2, 3, undefined])
};

export default BestThreeRankings;
