import React, {useEffect} from "react";
import Webcam from "react-webcam";
import * as poseDetection from '@tensorflow-models/pose-detection';
import * as scatter from 'scatter-gl';
import {calcAngleToesKnees, calc3dKneeAngles, calcChestSlope, calcKneesDistance, calcAnklesDistance} from '../../services/workout_analysis/handler';
import {useRef, useState} from "react";
import * as mpPose from "@mediapipe/pose";
import styled from "styled-components";
import Button from "@mui/material/Button";
import KNN from "ml-knn";
import {train_dataset, train_labels} from "../../services/workout_analysis/data_knees";

const VIDEO_CONSTRAINTS = {
    width: 500,
    height: 480,
    facingMode: "user",
    deviceId: "",
    frameRate: { max: 60, ideal: 30 },
};

const ANCHOR_POINTS = [[0, 0, 0], [0, 1, 0], [-1, 0, 0], [-1, -1, 0]];

let scatterGLEl;
let scatterGL;
let scatterGLHasInitialized = false;
let poseNetMutex = true;
let prevMutex = false;
let worker;
let counter = 0;
let loaded_detector = false;

let posHistory = [];
let window_size = 5;

const model = poseDetection.SupportedModels.BlazePose;
let detector = null;
let knn = null;

let train_data = ""

let knee_text = "";

const detectorConfig = {
    runtime: 'mediapipe', // or 'tfjs'
    modelType: 'full',
    solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`
};

// This is only for development, for example to get data for false postures. Its pretty rudimentary
const TestingScreen = () => {

    const webcamRef = useRef({});
    let canvas = null;
    const [cameraReady, setCameraReady] = useState(false);
    const [mutex, setMutex] = useState(true);
    const [kneeAngle, setKneeAngle] = useState(0);
    const [backSlopeX, setBackSlopeX] = useState(0);
    const [backSlopeY, setBackSlopeY] = useState(0);
    const [backSlopeZ, setBackSlopeZ] = useState(0);
    const [feetKneeAngle, setFeetKneeAngle] = useState(0);
    const [distRatio, setDistRatio] = useState(0);

    const onUserMediaError = () => {
        console.log('ERROR in Camera!');
    };

    const onUserMedia = () => {
        console.log('Camera loaded!');
        setCameraReady(true);
    };

    useEffect(() => {
        knn = new KNN(train_dataset, train_labels, { k: 2 }); // consider 2 nearest neighbors

        poseDetection.createDetector(model, detectorConfig).then((det) => {
            detector = det;
            loaded_detector = true;
        });

        scatterGLEl = document.querySelector('#scatter-gl-container');
        scatterGL = new scatter.ScatterGL(scatterGLEl, {
            'rotateOnStart': true,
            'selectEnabled': false,
            'styles': {polyline: {defaultOpacity: 1, deselectedOpacity: 1}}
        });
        scatterGLEl.style =
            `width: ${VIDEO_CONSTRAINTS.width}px; height: ${VIDEO_CONSTRAINTS.height}px;`;
        scatterGL.resize();
        scatterGLEl.style.display = 'inline-block';



        worker = new Worker(new URL('../../services/workout_analysis/worker.js', import.meta.url));
        worker.addEventListener("message", (event) => {
            console.log("OnMessage")
            const poses = event.data;
            console.log(mutex);
            setMutex(!mutex);
            if (poses != null) {
                //console.log(calc3dKneeAngles(poses[0].keypoints3D))
                counter++;
                setKneeAngle(calc3dKneeAngles(poses[0].keypoints3D));
                if (counter === 20) {
                    let slope = calcChestSlope(poses[0].keypoints3D);
                    setBackSlopeX(slope[0]);
                    setBackSlopeY(slope[1]);
                    setBackSlopeZ(slope[2]);
                    let ratio = calcAnklesDistance(poses[0].keypoints3D) / calcKneesDistance(poses[0].keypoints3D)
                    setDistRatio(ratio);
                    setFeetKneeAngle(calcAngleToesKnees(poses[0].keypoints3D));
                    counter = 0
                }
                drawKeypoints3D(poses[0].keypoints3D)
            }
            poseNetMutex = !poseNetMutex;
        });
    }, []);


    async function poseNetLoop() {
        const video = webcamRef.current && webcamRef.current['video'];
        if (!cameraReady && !video) {
            poseNetMutex = !poseNetMutex;
            return;
        }
        if (video.readyState < 2) {
            poseNetMutex = !poseNetMutex;
            return;
        }
        if (canvas == null) {
            canvas = document.createElement("canvas");
            //canvas = document.getElementById("videoCanvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
        }

        const poses = await detector.estimatePoses(video, {
            maxPoses: 1,
            flipHorizontal: false
        });

        if (poses != null) {
            counter++;
            setKneeAngle(calc3dKneeAngles(poses[0].keypoints3D));
            if (counter === 20) {
                let slope = calcChestSlope(poses[0].keypoints3D);
                setBackSlopeX(slope[0]);
                setBackSlopeY(slope[1]);
                setBackSlopeZ(slope[2]);
                let ratio = calcAnklesDistance(poses[0].keypoints3D) / calcKneesDistance(poses[0].keypoints3D)
                setDistRatio(ratio);
                setFeetKneeAngle(calcAngleToesKnees(poses[0].keypoints3D));
                counter = 0
            }
            drawKeypoints3D(poses[0].keypoints3D)
            let kpts = poses[0].keypoints3D;
            //addWrongKneesData(kpts);
            addBackData(kpts);
            //train_data += kpts[0].x + ", " + kpts[0].y + ", " + kpts[0].z + ", " + kpts[1].x + "\n"
        }

        /*

        let context = canvas.getContext('2d', { willReadFrequently: true });
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        let data_knees.jsx = context.getImageData(0, 0, canvas.width, canvas.height);
        worker.postMessage({
            pixels: data_knees.jsx.data_knees.jsx.buffer,
            width: canvas.width,
            height: canvas.height,
        }, [data_knees.jsx.data_knees.jsx.buffer] )
         */
    }


    function addWrongKneesData(kpts) {
        // left: 32, 28, 26, (24)
        // right: 31, 27, 25, (23)
        if (kpts[32].score + kpts[31].score < 0.7) return;
        train_data +=
            kpts[32].x.toFixed(4) + ", " +
            kpts[32].y.toFixed(4) + ", " +
            kpts[32].z.toFixed(4) + ", " +
            kpts[28].x.toFixed(4) + ", " +
            kpts[28].y.toFixed(4) + ", " +
            kpts[28].z.toFixed(4) + ", " +
            kpts[26].x.toFixed(4) + ", " +
            kpts[26].y.toFixed(4) + ", " +
            kpts[26].z.toFixed(4) + ", " +
            kpts[31].x.toFixed(4) + ", " +
            kpts[31].y.toFixed(4) + ", " +
            kpts[31].z.toFixed(4) + ", " +
            kpts[27].x.toFixed(4) + ", " +
            kpts[27].y.toFixed(4) + ", " +
            kpts[27].z.toFixed(4) + ", " +
            kpts[25].x.toFixed(4) + ", " +
            kpts[25].y.toFixed(4) + ", " +
            kpts[25].z.toFixed(4) + ", " + "\n"

        let knee_sample = [
            kpts[32].x,
            kpts[32].y,
            kpts[32].z,
            kpts[28].x,
            kpts[28].y,
            kpts[28].z,
            kpts[26].x,
            kpts[26].y,
            kpts[26].z,
            kpts[31].x,
            kpts[31].y,
            kpts[31].z,
            kpts[27].x,
            kpts[27].y,
            kpts[27].z,
            kpts[25].x,
            kpts[25].y,
            kpts[25].z
        ]

        console.log(knee_sample)
        let pred = knn.predict(knee_sample);
        if (pred === 1) {
            knee_text = "WRONG KNEE POSITION";
        } else {
            knee_text = "Correct";
        }
        console.log(knee_text)
    }

    function addBackData(kpts) {
        if (kpts[32].score + kpts[31].score < 0.9) return;
        train_data +=
            kpts[25].x.toFixed(4) + ", " +
            kpts[25].y.toFixed(4) + ", " +
            kpts[25].z.toFixed(4) + ", " +
            kpts[23].x.toFixed(4) + ", " +
            kpts[23].y.toFixed(4) + ", " +
            kpts[23].z.toFixed(4) + ", " +
            kpts[11].x.toFixed(4) + ", " +
            kpts[11].y.toFixed(4) + ", " +
            kpts[11].z.toFixed(4) + ", " +
            kpts[26].x.toFixed(4) + ", " +
            kpts[26].y.toFixed(4) + ", " +
            kpts[26].z.toFixed(4) + ", " +
            kpts[24].x.toFixed(4) + ", " +
            kpts[24].y.toFixed(4) + ", " +
            kpts[24].z.toFixed(4) + ", " +
            kpts[12].x.toFixed(4) + ", " +
            kpts[12].y.toFixed(4) + ", " +
            kpts[12].z.toFixed(4) + ", " + "\n"
    }

    useEffect(() => {
        asyncLoop()
    }, [])

    async function asyncLoop() {
        setTimeout(function() {
            if (poseNetMutex !== prevMutex) {
                prevMutex = poseNetMutex.copy
                //poseNetLoop()
            }
            poseNetLoop()
            asyncLoop()
        }, 15)
    }

    function drawKeypoints3D(keypoints) {
        const scoreThreshold = 0.5;
        const pointsData =
            keypoints.map(keypoint => ([-keypoint.x, -keypoint.y, -keypoint.z]));

        const dataset =
            new scatter.ScatterGL.Dataset([...pointsData, ...ANCHOR_POINTS]);

        const keypointInd =
            poseDetection.util.getKeypointIndexBySide(poseDetection.SupportedModels.BlazePose);
        scatterGL.setPointColorer((i) => {
            if (keypoints[i] == null || keypoints[i].score < scoreThreshold) {
                // hide anchor points and low-confident points.
                return '#ffffff';
            }
            if (i === 0) {
                return '#ff0000' /* Red */;
            }
            if (keypointInd.left.indexOf(i) > -1) {
                return '#00ff00' /* Green */;
            }
            if (keypointInd.right.indexOf(i) > -1) {
                return '#ffa500' /* Orange */;
            }
        });

        if (!scatterGLHasInitialized) {
            scatterGL.render(dataset);
        } else {
            scatterGL.updateDataset(dataset);
        }
        const connections = poseDetection.util.getAdjacentPairs(poseDetection.SupportedModels.BlazePose);
        const sequences = connections.map(pair => ({indices: pair}));
        scatterGL.setSequences(sequences);
        scatterGLHasInitialized = true;
    }

    function renderBadChest() {
        if (backSlopeX*100 > 1.1) {
            return <h3 style={{color: "Red"}}>Rücken sollte aufrechter sein</h3>;
        }
        return null
    }

    function renderBadKnees() {
        if (distRatio < 0.75) {
            return <h3 style={{color: "Red"}}>Knie zu weit nach innen gedreht</h3>;
        }
        return null
    }

    function renderKneesTooMuchForward() {
        if (feetKneeAngle < 90) {
            return <h3 style={{color: "Red"}}>Knie zu weit nach Vorne gebeugt (Knie über Zehenspitzen)</h3>;
        }
        return null;
    }

    return (
        <div>
            <Webcam
                className="filter blur-lg"
                style={{ visibility: "visible" }}
                ref={webcamRef}
                audio={false}
                videoConstraints={VIDEO_CONSTRAINTS}
                onUserMediaError={onUserMediaError}
                onUserMedia={onUserMedia}
            />
            <div id="scatter-gl-container"></div>
            <p>{knee_text}</p>
            <Button onClick={console.log("hello")}>Reset</Button>
            <Main>
                <p>{train_data}</p>
            </Main>
        </div>

    )
}

/* inside div:
                <h4>Knee Angle:                         {kneeAngle.toString().substring(0, 3)}</h4>
                <h4>Chest Slope:</h4>
                <h4>X: {(backSlopeX*1000).toString().substring(0, 4)}</h4>
                <h4>Y: {(backSlopeY*1000).toString().substring(0, 4)}</h4>
                <h4>Z: {(backSlopeZ*1000).toString().substring(0, 4)}</h4>
                <h4>Angle Feet Knee:                    {feetKneeAngle.toString().substring(0, 3)}</h4>
                <h4>Ratio Knee/Ankle Distances:         {distRatio.toString().substring(0, 3)}</h4>


                            <div>

            </div>
            {renderBadChest()}
            {renderKneesTooMuchForward()}
            {renderBadKnees()}
 */
// <div id="scatter-gl-container"></div>

const Main = styled.main`
  color: black;
  grid-area: main;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  white-space: pre-line;
`;

export default TestingScreen;