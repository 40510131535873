import React, { useState } from "react";
import Button from "@mui/material/Button";
import styled from "styled-components";
import deskImage from "../../../assets/desk.png";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

import { useNavigate } from "react-router-dom";
import {auth, db} from "../../../services/firebase";
import {onValue, ref} from "firebase/database";

const LoginScreen = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorText, setErrorText] = useState("");
    const [showPassword, setshowPassword] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                var userId = user.uid;
                // Get the username from the database
                onValue(ref(db, `users/${userId}/username`),
                    (snapshot) => {
                    var username = snapshot.val();

                    localStorage.setItem("username", username.toString());
                        localStorage.setItem("userId", userId.toString())
                    alert(`Welcome back, ${username}!`);
                        navigate('/home_challenge', {state: {username}});
                    })
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setErrorText(errorMessage);
            });
    };

    const onForgotPassword = () => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                alert(`Reset E-Mail sent to: ${email}!`);
                setResetPassword(false);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorMessage)
            });
    }
    return (
        <Container>
            <Main>
                <WelcomeDiv>Wilkommen,</WelcomeDiv>
                <SubHeadDiv>Bitte loggen Sie sich mit Ihrer</SubHeadDiv>
                <SubHeadDiv>E-Mail und Passwort ein.</SubHeadDiv>
                <form
                    style={{
                        marginTop: "8%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div className="form-group">
                        <label
                            htmlFor="InputEmail"
                            style={{ color: "#585F66", marginLeft: "15%" }}
                        >
                            E-Mail
                        </label>
                        <LoginInput
                            onChange={(e) => setEmail(e.target.value)}>

                        </LoginInput>
                    </div>
                    <div className="form-group" style={{ marginTop: "3%" }}>
                        {!resetPassword && (
                            <div>
                                <label
                                    htmlFor="InputPassword"
                                    style={{ color: "#585F66", marginLeft: "15%" }}
                                >
                                    Password
                                </label>
                                <LoginInput onChange={(e) => setPassword(e.target.value)}
                                            type={showPassword ? "text" : "password"}>
                                </LoginInput>
                            </div>
                        )}
                        <div style={{ marginLeft: "15%" }}>
                            {!resetPassword && (
                                <label style={{ color: "#585F66" }}>
                                    <input
                                        type="checkbox"
                                        style={{ marginRight: "5px" }}
                                        onChange={(e) =>
                                            setshowPassword(
                                                (prevState) => !prevState
                                            )
                                        }
                                    />
                                    Show password
                                </label>
                            )}
                            <label style={{ color: "#585F66", fontSize: "12px", marginLeft: "10px" }}>
                                <a
                                    href="#"
                                    style={{ textDecoration: "underline", marginRight: "5px" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setResetPassword(!resetPassword);
                                    }}
                                >
                                    {!resetPassword ? "Reset Password" : "Login"}
                                </a>
                            </label>

                        </div>
                    </div>

                    <Button
                        variant="outlined"
                        style={{
                            textTransform: "none",
                            borderRadius: "8px",
                            width: "50%",
                            background: "#2E99C2",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "7%",
                            height: "60px",
                            color: "#fff",
                        }}
                        onClick={resetPassword ? onForgotPassword : onLogin}
                    >
                        {resetPassword ? "Reset Password" : "Login"}
                    </Button>
                    <small
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginTop: "10px",
                            color: "red",
                        }}
                    >
                        {errorText}
                    </small>
                </form>
                <div
                    style={{
                        textAlign: "left",
                        marginLeft: "70px",
                        margin: "auto",
                    }}
                >
                    <a
                        href="datenschutz"
                        style={{ fontSize: "10px", marginRight: "20px" }}
                    >
                        Datenschutzerklärung
                    </a>
                    <a href="impressum" style={{ fontSize: "10px" }}>
                        Impressum
                    </a>
                </div>
            </Main>
            <SideBar>
                <NeuHier>Neu hier?</NeuHier>
                <SubHeadDiv>Werde aktiver mit Sportify!</SubHeadDiv>

                <Logo>
                    <img src={deskImage} alt="" width="70%" />
                </Logo>
                <Button
                    variant="outlined"
                    style={{
                        textTransform: "none",
                        borderRadius: "8px",
                        width: "60%",
                        background: "#fff",
                        borderColor: "#CDD1E8",
                        borderWidth: "3px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "40px",
                        height: "60px",
                    }}
                    onClick={() => {navigate('/register')}}
                >
                    Registrieren
                </Button>
            </SideBar>
            <Version>V0.12</Version>
        </Container>
    );
};

const Container = styled.div`
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr;
    grid-template-columns: 0.35fr 0.65fr;
    grid-template-areas: "sidebar main";
    text-align: center;
    transition: all 0.25s ease-in-out;
    @media (max-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0.4fr 0.6fr;
        grid-template-areas:
            "sidebar"
            "main";
    }
    color: white;
`;

const Main = styled.main`
    background: linear-gradient(180deg, #ffffff 0%, #e4f8ff 100%);
    color: white;
    grid-area: main;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    justify-content: flex-start;
    text-align: start;
`;
const SideBar = styled.div`
    background: radial-gradient(
        363.87% 138.58% at 2.13% 0%,
        rgba(102, 109, 162, 0.2) 5%,
        rgba(179, 216, 230, 1) 100%
    );
    grid-area: sidebar;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    align-items: start;
    justify-content: flex-start;

    position: relative;
`;
const Logo = styled.div`
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
`;
const BlueDiv = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 17vh;
    background: #b8dcea;
`;
const HoursDiv = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    padding-top: 7%;
    left: 0;
    right: 0;
`;

const WelcomeDiv = styled.div`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    margin: 30px 0px 5px 60px;
    font-size: 64px;
    font-weight: 300;
    display: flex;
    margin-left: 15%;
`;

const SubHeadDiv = styled.div`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 0.6);
    font-family: "Roboto";
    font-weight: 300;
    margin: 5px 0px 5px 60px;
    font-size: 32px;
    display: flex;
    margin-left: 15%;
`;

const NeuHier = styled.div`
    background-clip: text;
    -webkit-background-clip: text;
    color: #666666;
    font-family: "Roboto";
    font-weight: 300;
    margin: 5px 0px 5px 60px;
    font-size: 64px;
    display: flex;
    margin-top: 10%;
    margin-left: 15%;
`;

const Version = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: black;
    font-size: 12px;
`;

const LoginInput = styled.input`
    display: block;
    width: 60%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-left: 15%;
`;

export default LoginScreen;
