import React, {useContext} from "react";
import styled from "styled-components";
import Webcam from "react-webcam";
import { useEffect, useRef, useState } from "react";
import deskImage from '../../../../assets/position.png';
// eslint-disable-next-line
import * as tfJsCore from '@tensorflow/tfjs-core';
// eslint-disable-next-line
import * as tfJsConverter from '@tensorflow/tfjs-converter';
// eslint-disable-next-line
import * as tfJsBackendWebgl from '@tensorflow/tfjs-backend-webgl';
import * as poseDetection from '@tensorflow-models/pose-detection';
import {
    calcHipKneeDistance, calcKneeAnkleDistance,
    isPostureCorrect,
    setHipKneeDistance, setKneeAnkleDistance,
    setLimbsStatus
} from '../../../../services/workout_analysis/handler';
import { useNavigate } from 'react-router-dom';
import {AnalyticsContext} from "../../../../App";
import { CountdownCircleTimer } from "react-countdown-circle-timer";


const VIDEO_CONSTRAINTS = {
    width: 500,
    height: 480,
    facingMode: "user",
    deviceId: "",
    frameRate: { max: 60, ideal: 30 },
};

const MOVENET_CONFIG = {
    maxPoses: 1,
    type: 'lightning',
    scoreThreshold: 0.3
};

const DEFAULT_LINE_WIDTH = 5;
const DEFAULT_RADIUS = 10;

let detector;
let startInferenceTime,
    numInferences = 0;
let inferenceTimeSum = 0,
    lastPanelUpdate = 0;
let rafId;
let canvasFullScreen;
let ctxFullScreen;
let model;
let modelType;


const StretchPositionScreen = () => {
    const [cameraReady, setCameraReady] = useState(false);
    const [displayFps, setDisplayFps] = useState(0);
    const webcamRef = useRef({});
    const navigate = useNavigate();
    let webAnalytics = useContext(AnalyticsContext);
    const [countdown, setCountdown] = useState(false);
    const [alert, setAlert] = useState(false);

    var wdh = 0;
    var start = Date.now();

    var correctCount = 0;

    const onUserMediaError = () => {
        console.log('ERROR in Camera!');
    };

    const onUserMedia = () => {
        console.log('Camera loaded!');
        setCameraReady(true);
    };

    useEffect(() => {
        webAnalytics.analytics.track("position_screen")
        _loadPoseNet().then();

        // eslint-disable-next-line
    }, []);

    const _loadPoseNet = async () => {

        if (rafId) {
            window.cancelAnimationFrame(rafId);
            detector.dispose();
        }

        detector = await createDetector();
        await renderPrediction();
    };

    const createDetector = async () => {
        model = poseDetection.SupportedModels.MoveNet;
        modelType = poseDetection.movenet.modelType.SINGLEPOSE_THUNDER; //or SINGLEPOSE_THUNDER
        return await poseDetection.createDetector(model, {
            modelType: modelType,
        });
    };

    const renderPrediction = async () => {
        await renderResult();
        rafId = requestAnimationFrame(renderPrediction);
    };

    const renderResult = async () => {

        if (Date.now() - start >= 5000) {
            console.log(wdh / 5);
            start = Date.now();
            wdh = 0;
        }
        wdh += 1;

        const video = webcamRef.current && webcamRef.current["video"];

        if (!cameraReady && !video) {
            return;
        }

        if (video.readyState < 2) {
            return;
        }

        beginEstimatePosesStats();
        const poses = await detector.estimatePoses(video, {
            maxPoses: MOVENET_CONFIG.maxPoses, //When maxPoses = 1, a single pose is detected
            flipHorizontal: false,
        });
        endEstimatePosesStats();
        drawCtxFullScreen(video);

        if (poses.length > 0 && avgPoseScore(poses) > 0.50) {
            drawResultsFullScreen(poses);
        }
    };

    const beginEstimatePosesStats = () => {
        startInferenceTime = (performance || Date).now();
    };

    const endEstimatePosesStats = () => {
        const endInferenceTime = (performance || Date).now();
        inferenceTimeSum += endInferenceTime - startInferenceTime;
        ++numInferences;
        const panelUpdateMilliseconds = 1000;

        if (endInferenceTime - lastPanelUpdate >= panelUpdateMilliseconds) {
            const averageInferenceTime = inferenceTimeSum / numInferences;
            inferenceTimeSum = 0;
            numInferences = 0;
            setDisplayFps(1000.0 / averageInferenceTime, 120);
            lastPanelUpdate = endInferenceTime;
        }
    };

    const drawCtxFullScreen = (video) => {
        canvasFullScreen = document.getElementById('output-full-screen');
        ctxFullScreen = canvasFullScreen.getContext('2d');

        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        video.width = videoWidth;
        video.height = videoHeight;

        canvasFullScreen.width = videoWidth;
        canvasFullScreen.height = videoHeight;
        ctxFullScreen.fillRect(0, 0, videoWidth, videoHeight);

        ctxFullScreen.translate(video.videoWidth, 0);
        ctxFullScreen.scale(-1, 1);
        ctxFullScreen.drawImage(video, 0, 0, videoWidth, videoHeight);
    };

    const drawResultsFullScreen = (poses) => {
        for (const pose of poses) {
            drawResult(pose);
            // angles = doReps(poses[0].keypoints);
        }
    };

    const navigateToExercise = () => {
        if (isPostureCorrect()) {
            navigate("/instruction_stretch");
        } else {
            setCountdown(false);
        }
    };

    const drawResult = (pose) => {
        if (pose.keypoints != null) {
            drawKeypoints(pose.keypoints);
            //drawSkeleton(pose.keypoints);
        }
    };

    const drawKeypoints = (keypoints) => {
        const keypointInd = poseDetection.util.getKeypointIndexBySide(model);
        ctxFullScreen.fillStyle = 'White';
        ctxFullScreen.strokeStyle = 'White';
        ctxFullScreen.lineWidth = DEFAULT_LINE_WIDTH;


        // draw eyes -> always green
        ctxFullScreen.fillStyle = 'Green';
        ctxFullScreen.strokeStyle = 'Green';
        drawKeypoint(keypoints[keypointInd.left[0]]);
        drawKeypoint(keypoints[keypointInd.right[0]]);

        let nose = keypoints[keypointInd.middle[0]];

        // shoulders
        let shouldersCorrect = drawPair(
            keypoints[keypointInd.left[2]],
            keypoints[keypointInd.right[2]],
            nose
        );

        // elbows
        let elbowsCorrect = drawPair(
            keypoints[keypointInd.left[3]],
            keypoints[keypointInd.right[3]],
            nose
        );

        // hands
        let handsCorrect = drawHands(
            keypoints[keypointInd.left[4]],
            keypoints[keypointInd.right[4]],
            nose,
            (keypoints[5].y + keypoints[6].y)/2,
        );

        if (
            shouldersCorrect &&
            elbowsCorrect &&
            handsCorrect
        ) {
            correctCount++;

            if (correctCount > 10) {
                setLimbsStatus(true);

                // use knee hip distance to determine angle or "level" of pushup
                setHipKneeDistance(calcHipKneeDistance(keypoints));
                setKneeAnkleDistance(calcKneeAnkleDistance(keypoints));
                setCountdown(true);
            }
        } else {
            setLimbsStatus(false);
            setHipKneeDistance(0.0);
            correctCount = 0;
        }
    };

    function drawHands(left, right, nose, y_shoulder) {
        let aligned = keypointsAligned(left, right, nose);
        let correctHeightL = left.y > y_shoulder * 0.93 && left.y < y_shoulder * 1.07;
        let correctHeightR = right.y > y_shoulder * 0.93 && right.y < y_shoulder * 1.07;

        if (aligned && correctHeightL && correctHeightR) {
            ctxFullScreen.fillStyle = "Green";
            ctxFullScreen.strokeStyle = "Green";
            drawKeypoint(left);
            drawKeypoint(right);
            return true;
        } else {
            ctxFullScreen.fillStyle = "Red";
            ctxFullScreen.strokeStyle = "Red";
            drawKeypoint(left);
            drawKeypoint(right);
            return false;
        }
    }

    function drawPair(left, right, nose) {
        let correct = keypointsAligned(left, right, nose);
        if (correct) {
            correct = true;
            ctxFullScreen.fillStyle = "Green";
            ctxFullScreen.strokeStyle = "Green";
        } else {
            ctxFullScreen.fillStyle = "Red";
            ctxFullScreen.strokeStyle = "Red";
        }

        drawKeypoint(left);
        drawKeypoint(right);
        return correct;
    }

    function keypointsAligned(left, right, nose, tol = 160) {
        let dLeftNose = Math.abs(nose.x - left.x);
        let dRightNose = Math.abs(right.x - nose.x);
        let xDiff = Math.abs(dLeftNose - dRightNose);
        let totalXDiff = Math.abs(right.x - left.x);
        let yDiff = Math.abs(left.y - right.y);
        if (totalXDiff < 80) {
            return xDiff < tol && yDiff < tol;
        }

        return xDiff < totalXDiff/4 && yDiff < tol;
    }

    const drawKeypoint = (keypoint) => {
        // If score is null, just show the keypoint.
        const score = keypoint.score != null ? keypoint.score : 1;
        const scoreThreshold = MOVENET_CONFIG.scoreThreshold || 0;

        if (score >= scoreThreshold) {
            const circle = new Path2D();
            circle.arc(keypoint.x, keypoint.y, DEFAULT_RADIUS, 0, 2 * Math.PI);
            ctxFullScreen.fill(circle);
            ctxFullScreen.stroke(circle);
        }
    };

    function avgPoseScore(poses) {
        var sum = 0.0;
        for (let p of poses) {
            sum += p.score;
        }
        return sum / poses.length;
    }

    const RenderTime = ({ remainingTime }) => {
        return (
            <TimerContentWrapper>
                <TimerValue>{remainingTime}</TimerValue>
            </TimerContentWrapper>
        );
    };

    return (
        <Container>
            <CameraWrapper>
                <Canvas id="output-full-screen"></Canvas>
                <Camera
                    className="filter blur-lg"
                    ref={webcamRef}
                    style={{ visibility: "hidden" }}
                    audio={false}
                    videoConstraints={VIDEO_CONSTRAINTS}
                    onUserMediaError={onUserMediaError}
                    onUserMedia={onUserMedia}
                />
            </CameraWrapper>
            <SideBar>
                {!countdown ? (
                    <>
                        <Title>In Position gehen</Title>
                        <ImageRow>
                            <Logo>
                                <img src={deskImage} />
                            </Logo>
                            <PoseDescription>
                                Stellen Sie sich frontal vor die Kamera, so dass
                                Ihr Oberkörper sowie Ihre (seitlich) ausgestreckten Arme zu sehen sind.
                            </PoseDescription>
                        </ImageRow>
                        <div style={{ height: 40 }}></div>
                        <NotWorkingText>
                            Erkennung funktioniert nicht?
                        </NotWorkingText>
                        <NotWorkingParagraph>
                            <ul style={{ listStyleType: "disc" }}>
                                <li>Achten Sie auf ausreichende Beleuchtung</li>
                                <li>
                                    Vermeiden Sie es, die Kamera direkt auf
                                    Licht zu richten
                                </li>
                                <li>
                                    Stellen Sie sicher, dass Sie richtig
                                    positioniert sind
                                </li>
                            </ul>
                        </NotWorkingParagraph>
                        <div style={{ height: 60 }}></div>
                        <NotWorkingText>Hilft nichts?</NotWorkingText>
                        <a href="src/components/workout/startup/squats/position" style={{ marginLeft: 20 }}>
                            Support Kontaktieren
                        </a>
                    </>
                ) : (
                    <>
                        <Title>Perfekt! Bleiben Sie in Position</Title>
                        <PoseDescription>Übung startet in...</PoseDescription>
                        <TimerWrapper>
                            <CountdownCircleTimer
                                isPlaying
                                duration={8}
                                trailColor={"#a6dcfd"}
                                colors={[
                                    "#87CEFA",
                                    "#6495ED",
                                    "#1E90FF",
                                    "#0000FF",
                                ]}
                                colorsTime={[10, 6, 3, 0]}
                                onComplete={navigateToExercise}
                                onUpdate={(remaining) => {
                                    !isPostureCorrect()
                                        ? setAlert(true)
                                        : setAlert(false);
                                }}
                                size={330}
                                strokeWidth={10}
                            >
                                {RenderTime}
                            </CountdownCircleTimer>
                            <AlertText
                                style={
                                    alert
                                        ? { visibility: "visible" }
                                        : { visibility: "hidden" }
                                }
                            >
                                Aufmerksamkeit ! halten Sie
                                <br /> Ihre Position
                            </AlertText>
                        </TimerWrapper>
                    </>
                )}
            </SideBar>
        </Container>
    );
};

export const Container = styled.div`
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr;
    grid-template-columns: 0.6fr 0.4fr;
    grid-template-areas: "camera sidebar";
    transition: all 0.25s ease-in-out;
    @media (max-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0.4fr 0.6fr;
        grid-template-areas:
            "camera"
            "sidebar";
    }
`;

const SideBar = styled.div`
    background: linear-gradient(180deg, #ffffff 0%, #e4f8ff 100%);
    grid-area: sidebar;
    overflow: hidden;
    flex-direction: column;
`;

export const CameraWrapper = styled.main`
    grid-area: camera;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #ffffff 0%, #e4f8ff 100%);
`;

export const Camera = styled(Webcam)`
    width: 100%;
    height: 100%;
    position: fixed;
`;

export const Canvas = styled.canvas`
    width: 100%;
    height: 100%;
`;

export const Title = styled.h4`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    margin: 50px 0px 20px 20px;
    align-items: start;
    font-size: 40px;
    font-weight: 400;
`;

const ImageRow = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 50px 20px 20px 20px;
`;

const Logo = styled.div``;

const PoseDescription = styled.p`
    color: rgb(88, 95, 102);
    font-family: "Roboto";
    font-weight: 300;
    font-size: 24px;
    margin-left: 20px;
`;

const NotWorkingText = styled.h6`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    margin-left: 20px;
    font-size: 24px;
    font-weight: 400;
`;

const NotWorkingParagraph = styled.p`
    color: rgb(88, 95, 102);
    font-family: "Roboto";
    font-weight: 300;
    font-size: 24px;
    margin-left: 40px;
`;

const TimerWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 25%;
    flex-direction: column;
    align-items: center;
`;

const TimerValue = styled.div`
    font-size: 100px;
    color: #1e90ff;
`;

const TimerContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const AlertText = styled.h1`
    color: rgb(245, 27, 27);
    font-family: "Roboto";
    font-weight: 400;
    font-size: 35px;
    margin-top: 20px;
`;

export default StretchPositionScreen;
