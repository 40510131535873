import React, { useContext } from "react";
import styled from "styled-components";
import Webcam from "react-webcam";
import { useEffect, useRef, useState } from "react";
import deskImage from "../../../../assets/position.png";
// eslint-disable-next-line
import * as tfJsCore from "@tensorflow/tfjs-core";
// eslint-disable-next-line
import * as tfJsConverter from "@tensorflow/tfjs-converter";
// eslint-disable-next-line
import * as tfJsBackendWebgl from "@tensorflow/tfjs-backend-webgl";
import * as poseDetection from "@tensorflow-models/pose-detection";
import {
    calcHipKneeDistance,
    calcKneeAnkleDistance,
    isPostureCorrect,
    setHipKneeDistance,
    setKneeAnkleDistance,
    setLimbsStatus,
    calcElbowAngle,
} from "../../../../services/workout_analysis/handler";
import { useNavigate } from "react-router-dom";
import { AnalyticsContext } from "../../../../App";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import * as mpPose from "@mediapipe/pose";

const VIDEO_CONSTRAINTS = {
    width: 500,
    height: 480,
    facingMode: "user",
    deviceId: "",
    frameRate: { max: 60, ideal: 30 },
};

const DEFAULT_LINE_WIDTH = 5;
const DEFAULT_RADIUS = 10;

let startInferenceTime,
    numInferences = 0;
let inferenceTimeSum = 0,
    lastPanelUpdate = 0;
let rafId;
let canvasFullScreen;
let ctxFullScreen;

const model = poseDetection.SupportedModels.BlazePose;
let detector = null;
const detectorConfig = {
    runtime: "mediapipe", // or 'tfjs'
    modelType: "full",
    solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`,
};

const WYPositionScreen = () => {
    const [cameraReady, setCameraReady] = useState(false);
    const [displayFps, setDisplayFps] = useState(0);
    const webcamRef = useRef({});
    const navigate = useNavigate();
    let webAnalytics = useContext(AnalyticsContext);
    const [countdown, setCountdown] = useState(false);
    const [alert, setAlert] = useState(false);

    var wdh = 0;
    var start = Date.now();

    var correctCount = 0;

    const onUserMediaError = () => {
        console.log("ERROR in Camera!");
    };

    const onUserMedia = () => {
        console.log("Camera loaded!");
        setCameraReady(true);
    };

    useEffect(() => {
        webAnalytics.analytics.track("position_screen_yw");
        _loadPoseNet().then();

        // eslint-disable-next-line
    }, []);

    const _loadPoseNet = async () => {
        if (rafId) {
            window.cancelAnimationFrame(rafId);
            detector.dispose();
        }

        detector = await createDetector();
        await renderPrediction();
    };

    const createDetector = async () => {
        poseDetection.createDetector(model, detectorConfig).then((det) => {
            detector = det;
        });
    };

    const renderPrediction = async () => {
        await renderResult();
        rafId = requestAnimationFrame(renderPrediction);
    };

    const renderResult = async () => {
        if (Date.now() - start >= 5000) {
            console.log(wdh / 5);
            start = Date.now();
            wdh = 0;
        }
        wdh += 1;

        const video = webcamRef.current && webcamRef.current["video"];

        if (!cameraReady && !video) {
            return;
        }

        if (video.readyState < 2) {
            return;
        }

        beginEstimatePosesStats();
        const poses = await detector.estimatePoses(video, {
            maxPoses: 1, //When maxPoses = 1, a single pose is detected
            flipHorizontal: false,
        });
        endEstimatePosesStats();
        drawCtxFullScreen(video);

        if (poses.length > 0) {
            drawResultsFullScreen(poses);
        }
    };

    const beginEstimatePosesStats = () => {
        startInferenceTime = (performance || Date).now();
    };

    const endEstimatePosesStats = () => {
        const endInferenceTime = (performance || Date).now();
        inferenceTimeSum += endInferenceTime - startInferenceTime;
        ++numInferences;
        const panelUpdateMilliseconds = 1000;

        if (endInferenceTime - lastPanelUpdate >= panelUpdateMilliseconds) {
            const averageInferenceTime = inferenceTimeSum / numInferences;
            inferenceTimeSum = 0;
            numInferences = 0;
            setDisplayFps(1000.0 / averageInferenceTime, 120);
            lastPanelUpdate = endInferenceTime;
        }
    };

    const drawCtxFullScreen = (video) => {
        canvasFullScreen = document.getElementById("output-full-screen");
        ctxFullScreen = canvasFullScreen.getContext("2d");

        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        video.width = videoWidth;
        video.height = videoHeight;

        canvasFullScreen.width = videoWidth;
        canvasFullScreen.height = videoHeight;
        ctxFullScreen.fillRect(0, 0, videoWidth, videoHeight);

        ctxFullScreen.translate(video.videoWidth, 0);
        ctxFullScreen.scale(-1, 1);
        ctxFullScreen.drawImage(video, 0, 0, videoWidth, videoHeight);
    };

    const drawResultsFullScreen = (poses) => {
        for (const pose of poses) {
            drawResult(pose);
            // angles = doReps(poses[0].keypoints);
        }
    };

    const navigateToExercise = () => {
        if (isPostureCorrect()) {
            navigate("/instruction_stretch_yw");
        } else {
            setCountdown(false);
        }
    };

    const drawResult = (pose) => {
        if (pose.keypoints != null) {
            drawKeypoints(pose);
            //drawSkeleton(pose.keypoints);
        }
    };

    const drawKeypoints = (pose) => {
        ctxFullScreen.fillStyle = "White";
        ctxFullScreen.strokeStyle = "White";
        ctxFullScreen.lineWidth = DEFAULT_LINE_WIDTH;

        // draw eyes -> always green
        ctxFullScreen.fillStyle = "Green";
        ctxFullScreen.strokeStyle = "Green";
        drawKeypoint(pose.keypoints[2]);
        drawKeypoint(pose.keypoints[5]);

        let nose = pose.keypoints[0];

        // shoulders
        let shouldersCorrect = drawPair(
            pose.keypoints[11],
            pose.keypoints[12],
            nose
        );

        // elbows
        let elbowsCorrect = drawPair(
            pose.keypoints[14],
            pose.keypoints[13],
            nose
        );

        // hands
        let elbowAngle = calcElbowAngle(pose.keypoints3D);
        let handsCorrect = drawHands(
            pose.keypoints[15],
            pose.keypoints[16],
            nose,
            [
                pose.keypoints[11],
                pose.keypoints[12],
                (elbowAngle[0] + elbowAngle[1]) / 2,
            ]
        );

        if (shouldersCorrect && elbowsCorrect && handsCorrect) {
            correctCount++;

            if (correctCount > 10) {
                setLimbsStatus(true);

                setCountdown(true);
            }
        } else {
            setLimbsStatus(false);
            correctCount = 0;
        }
    };

    function drawHands(
        wrist_left,
        wrist_right,
        nose,
        [shoulder_left, shoulder_right, elbow_angle]
    ) {
        let aligned = keypointsAligned(wrist_left, wrist_right, nose);
        let y_shoulder = (shoulder_right.y + shoulder_left.y) / 2;
        let correctHeightL =
            wrist_left.y > y_shoulder * 0.85 && wrist_left.y < y_shoulder * 1.2;
        let correctHeightR =
            wrist_right.y > y_shoulder * 0.85 &&
            wrist_right.y < y_shoulder * 1.2;
        let correctElbowAngle = elbow_angle > 70 && elbow_angle < 120;

        if (aligned && correctHeightL && correctHeightR && correctElbowAngle) {
            ctxFullScreen.fillStyle = "Green";
            ctxFullScreen.strokeStyle = "Green";
            drawKeypoint(wrist_left);
            drawKeypoint(wrist_right);
            return true;
        } else {
            ctxFullScreen.fillStyle = "Red";
            ctxFullScreen.strokeStyle = "Red";
            drawKeypoint(wrist_left);
            drawKeypoint(wrist_right);
            return false;
        }
    }

    function drawPair(left, right, nose) {
        let correct = keypointsAligned(left, right, nose);
        if (correct) {
            correct = true;
            ctxFullScreen.fillStyle = "Green";
            ctxFullScreen.strokeStyle = "Green";
        } else {
            ctxFullScreen.fillStyle = "Red";
            ctxFullScreen.strokeStyle = "Red";
        }

        drawKeypoint(left);
        drawKeypoint(right);
        return correct;
    }

    function keypointsAligned(left, right, nose, tol = 160) {
        let dLeftNose = Math.abs(nose.x - left.x);
        let dRightNose = Math.abs(right.x - nose.x);
        let xDiff = Math.abs(dLeftNose - dRightNose);
        let totalXDiff = Math.abs(right.x - left.x);
        let yDiff = Math.abs(left.y - right.y);
        if (totalXDiff < 80) {
            return xDiff < tol && yDiff < tol;
        }

        return xDiff < totalXDiff / 4 && yDiff < tol;
    }

    const drawKeypoint = (keypoint) => {
        // If score is null, just show the keypoint.
        const score = keypoint.score != null ? keypoint.score : 1;
        const scoreThreshold = 0.3;

        if (score >= scoreThreshold) {
            const circle = new Path2D();
            circle.arc(keypoint.x, keypoint.y, DEFAULT_RADIUS, 0, 2 * Math.PI);
            ctxFullScreen.fill(circle);
            ctxFullScreen.stroke(circle);
        }
    };

    function avgPoseScore(poses) {
        var sum = 0.0;
        for (let p of poses) {
            sum += p.score;
        }
        return sum / poses.length;
    }

    const RenderTime = ({ remainingTime }) => {
        return (
            <TimerContentWrapper>
                <TimerValue>{remainingTime}</TimerValue>
            </TimerContentWrapper>
        );
    };

    return (
        <Container>
            <CameraWrapper>
                <Canvas id="output-full-screen"></Canvas>
                <Camera
                    className="filter blur-lg"
                    ref={webcamRef}
                    style={{ visibility: "hidden" }}
                    audio={false}
                    videoConstraints={VIDEO_CONSTRAINTS}
                    onUserMediaError={onUserMediaError}
                    onUserMedia={onUserMedia}
                />
            </CameraWrapper>
            <SideBar>
                {!countdown ? (
                    <>
                        <Title>In Position gehen</Title>
                        <ImageRow>
                            <Logo>
                                <img src={deskImage} />
                            </Logo>
                            <PoseDescription>
                                Stellen Sie sich frontal vor die Kamera, so dass
                                Ihr Oberkörper sowie Ihre (seitlich)
                                ausgestreckten Arme zu sehen sind.
                            </PoseDescription>
                        </ImageRow>
                        <div style={{ height: 40 }}></div>
                        <NotWorkingText>
                            Erkennung funktioniert nicht?
                        </NotWorkingText>
                        <NotWorkingParagraph>
                            <ul style={{ listStyleType: "disc" }}>
                                <li>Achten Sie auf ausreichende Beleuchtung</li>
                                <li>
                                    Vermeiden Sie es, die Kamera direkt auf
                                    Licht zu richten
                                </li>
                                <li>
                                    Stellen Sie sicher, dass Sie richtig
                                    positioniert sind
                                </li>
                            </ul>
                        </NotWorkingParagraph>
                        <div style={{ height: 60 }}></div>
                        <NotWorkingText>Hilft nichts?</NotWorkingText>
                        <a
                            href="src/components/workout/startup/squats/position"
                            style={{ marginLeft: 20 }}
                        >
                            Support Kontaktieren
                        </a>
                    </>
                ) : (
                    <>
                        <Title>Perfekt! Bleiben Sie in Position</Title>
                        <PoseDescription>Übung startet in...</PoseDescription>
                        <TimerWrapper>
                            <CountdownCircleTimer
                                isPlaying
                                duration={8}
                                trailColor={"#a6dcfd"}
                                colors={[
                                    "#87CEFA",
                                    "#6495ED",
                                    "#1E90FF",
                                    "#0000FF",
                                ]}
                                colorsTime={[10, 6, 3, 0]}
                                onComplete={navigateToExercise}
                                onUpdate={(remaining) => {
                                    !isPostureCorrect()
                                        ? setAlert(true)
                                        : setAlert(false);
                                }}
                                size={330}
                                strokeWidth={10}
                            >
                                {RenderTime}
                            </CountdownCircleTimer>
                            <AlertText
                                style={
                                    alert
                                        ? { visibility: "visible" }
                                        : { visibility: "hidden" }
                                }
                            >
                                Aufmerksamkeit ! halten Sie
                                <br /> Ihre Position
                            </AlertText>
                        </TimerWrapper>
                    </>
                )}
            </SideBar>
        </Container>
    );
};

const Container = styled.div`
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr;
    grid-template-columns: 0.6fr 0.4fr;
    grid-template-areas: "camera sidebar";
    transition: all 0.25s ease-in-out;
    @media (max-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0.4fr 0.6fr;
        grid-template-areas:
            "camera"
            "sidebar";
    }
`;

const SideBar = styled.div`
    background: linear-gradient(180deg, #ffffff 0%, #e4f8ff 100%);
    grid-area: sidebar;
    overflow: hidden;
    flex-direction: column;
`;

const CameraWrapper = styled.main`
    grid-area: camera;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #ffffff 0%, #e4f8ff 100%);
`;

const Camera = styled(Webcam)`
    width: 100%;
    height: 100%;
    position: fixed;
`;

const Canvas = styled.canvas`
    width: 100%;
    height: 100%;
`;

const Title = styled.h4`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    margin: 50px 0px 20px 20px;
    align-items: start;
    font-size: 40px;
    font-weight: 400;
`;

const ImageRow = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 50px 20px 20px 20px;
`;

const Logo = styled.div``;

const PoseDescription = styled.p`
    color: rgb(88, 95, 102);
    font-family: "Roboto";
    font-weight: 300;
    font-size: 24px;
    margin-left: 20px;
`;

const NotWorkingText = styled.h6`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    margin-left: 20px;
    font-size: 24px;
    font-weight: 400;
`;

const NotWorkingParagraph = styled.p`
    color: rgb(88, 95, 102);
    font-family: "Roboto";
    font-weight: 300;
    font-size: 24px;
    margin-left: 40px;
`;

const TimerWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 25%;
    flex-direction: column;
    align-items: center;
`;

const TimerValue = styled.div`
    font-size: 100px;
    color: #1e90ff;
`;

const TimerContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const AlertText = styled.h1`
    color: rgb(245, 27, 27);
    font-family: "Roboto";
    font-weight: 400;
    font-size: 35px;
    margin-top: 20px;
`;

export default WYPositionScreen;
