export {Coins};

export default class Coins {
    x_coordinates = [];
    number_coins = 0;
    initial_number = 0;

    fillCoordinates(start, end, period) {
        var j = 0;
        for (let i = start; i < end; i += period) {
            this.x_coordinates.push(i);
            if (j % 2 === 0) {
                this.x_coordinates.push(i + 3.473 - period/2); // + 1,9
                this.x_coordinates.push(i - period/2 - 0.331); // - 1,9
            }
            j++;
        }
        this.initial_number = this.x_coordinates.length;
    }

    getCurrentCoordinates(start, end) {
        return this.x_coordinates.filter(x => x >= start && x <= end)
    }

    checkCoins(x_point, y_point, y_wave, step_width) {
        let coinIdx = this.x_coordinates.findIndex(x =>
            x >= x_point - 2 * step_width
                && x <= x_point + 2 * step_width
        );
        if (coinIdx < 0) {
            return -1;
        }
        this.x_coordinates.splice(coinIdx, 1);
        this.number_coins++;
        return 1;
    }
}