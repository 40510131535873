import styled from "styled-components";
import { PlayCircleFill } from "@styled-icons/bootstrap/PlayCircleFill";
import { useNavigate } from "react-router-dom";

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  grid-template-rows: 0.2fr 0.5fr 0.3fr;
  grid-template-areas:
    "title title"
    "body action"
    "options options";
  border-radius: 18px;
  border: 1.5px solid rgba(225, 225, 225);
  background: #ffffff;
  margin: 30px;
  width: 80%;
  padding: 20px;
`;

const CardTitle = styled.h5`
  grid-area: title;
  background-clip: text;
  -webkit-background-clip: text;
  text-align: start;
  color: rgb(88, 95, 102);
  font-family: "Roboto";
  font-weight: normal;
`;

const CardAction = styled.div`
  grid-area: action;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CardActions = styled(PlayCircleFill)`
  color: rgb(7, 150, 238);

  height: 50px;
`;

const CardOptions = styled.div`
  grid-area: options;
  font-size: 1em;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const CardTextWrapper = styled.div`
  grid-area: body;
  margin: 10px 0px 10px 0px;
`;

const CardTextBody = styled.p`
  color: rgb(88, 95, 102);
  font-family: "Roboto";
  font-weight: 300;
  text-align: start;
`;

const CardBadge = styled.div`
  display: flex;
  height: 30px;
  width: 80px;
  background: ${(props) => props.badgeColor || "rgba(0,0,0,10%)"};
  color: ${(props) => props.textColor || "rgba(88, 95, 102, 1)"};
  border-radius: 44px;
  justify-content: center;
  align-items: center;
`;

export const ExerciseCard = (props) => {
  const navigate = useNavigate();
  return (
    <CardWrapper onClick={() => navigate(props.path)}>
      <CardTitle>{props.title || " "}</CardTitle>
      <CardTextWrapper>
        <CardTextBody>{props.body || " "}</CardTextBody>
      </CardTextWrapper>
      <CardAction>
        <CardActions></CardActions>
      </CardAction>

      <CardOptions>
        <CardBadge
          badgeColor="rgba(42, 163, 42, 0.2)"
          textColor="rgb(42, 163, 42)"
        >
          {props.type || " "}
        </CardBadge>
        <CardBadge>{props.duration || " "}.</CardBadge>
      </CardOptions>
    </CardWrapper>
  );
};
