import Analytics from "analytics";
import Cookies from "js-cookie";
import googleAnalytics from "@analytics/google-analytics";
import mixpanelPlugin from "@analytics/mixpanel";

export class SportifyAnalytics {
  analytics;
  fps_count = 0;

  constructor() {
    let data_consent = Cookies.get("data_consent");
    let plugins = [];
    if (data_consent === undefined) {
      console.log("cookies not accepted");
      return;
    } else {
      plugins = [
        mixpanelPlugin({
          token: "19ec41f7060e6217ee5cc1ced1cded84",
        }),
      ];
    }

    this.analytics = Analytics({
      app: "sportify_react",
      version: 0.1,
      debug: true,
      plugins: plugins,
    });
    this.analytics.ready(() => {
      if (data_consent === undefined) {
        return;
      }
      let userId = Cookies.get("userId");
      if (userId == null) {
        userId = Date.now().toString();
        Cookies.set("userId", userId);
      }
      this.analytics.identify(userId);
    });
  }

  trackKneeAnkleDistance(kad) {
    this.analytics.track("knee_ankle_distance", {
      kneeAnkleDistance: kad,
    });
  }

  trackKneeHipDistance(khp) {
    this.analytics.track("knee_hip_distance", {
      kneeHipDistance: khp,
    });
  }

  trackFPS(fps) {
    if (fps < 40) {
      this.analytics.track("fps", {
        fps: fps,
      });
    } else if (this.fps_count === 5) {
      this.analytics.track("fps", {
        fps: fps,
      });
      this.fps_count = 0;
    } else {
      this.fps_count++;
    }
  }

  trackAverageFPS(avg) {
    this.analytics.track("average_fps", {
      avgFPS: avg,
    });
  }

  trackRepetitions(repetitions) {
    this.analytics.track("repetitions", {
      repetitions: repetitions,
    });
  }

  trackWorkoutDone(
    repetitions,
    time,
    coinGoal,
    angleIntervalStart,
    angleIntervalEnd,
    meanAngle,
    minToeKneeDistance,
    maxToeKneeDistance,
    avgToeKneeDistance,
  ) {
    this.analytics.track("WorkoutDone", {
      repetitions: repetitions,
      duration: time,
      coinGoal: coinGoal,
      angleIntervalStart: angleIntervalStart,
      angleIntervalEnd: angleIntervalEnd,
      avgAngle: meanAngle,
      minToeKneeDistance: minToeKneeDistance,
      maxToeKneeDistance: maxToeKneeDistance,
      avgToeKneeDistance: avgToeKneeDistance,
    });
  }

  trackFormInput(formInput) {
    this.analytics.track("FormInput", {
      recognition_satisfaction: formInput["recognition_satisfaction"],
      height_cm: formInput["height_cm"],
      free_text: formInput["free_text"],
    });
  }
}
