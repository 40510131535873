import React, {useEffect, useState} from "react";
import styled from "styled-components";
import greenDot from "../../../assets/greenDotFinish.svg";
import { useNavigate, useLocation } from "react-router-dom";
import {getCurrentRank} from "../../../services/firebase";

const FinishScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let [rank, setRank] = useState(0);

  useEffect(() => {
      getCurrentRank().then((r) => setRank(r))
  }, [])

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        background: "linear-gradient(180deg, #FFFFFF 0%, #EAF9FF 100%)",
      }}
    >
      <SemiCircleDiv>
        <Time>{location.state.time ?? "2:00"}</Time>
        <ExerciseDetails>
          {location.state.title ?? "Title here"}
        </ExerciseDetails>
      </SemiCircleDiv>
      <RepCircleDiv>
        <Reps>{location.state.repititions ?? 0}</Reps>
        <Wdh>Wdh.</Wdh>
      </RepCircleDiv>
      <ColDiv>
        <EndScreenSpan>
          <img
            src={greenDot}
            alt=""
            width="20%"
            style={{ marginRight: "60px" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}>
            <h1 style={{ color: "#25ba7a" }}>Super !</h1>
            <div style={{ marginTop: "3%" }}>
                Sie haben <b> {location.state.points} Punkte </b> gesammelt und belegen jetzt <b> Platz {rank} </b>.
            </div>
              <div>
                  <SecondaryButton
                      onClick={() => {
                          navigate("/position");
                      }}
                  >
                      <h3 style={{ color: "#27D285" }}>Wiederholen</h3>
                  </SecondaryButton>
                  <MenuButton
                      onClick={() => {
                          navigate("/home_challenge");
                      }}
                  >
                      <h3 style={{ color: "white" }}>Zum Hauptmenü</h3>
                  </MenuButton>
              </div>
          </div>
        </EndScreenSpan>
      </ColDiv>
    </div>
  );
};

const SemiCircleDiv = styled.div`
  position: absolute;
  width: 450px;
  height: 180px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -15%);
  background-color: white;
  border-bottom-left-radius: 210px;
  border-bottom-right-radius: 210px;
  border: 3px solid #e3e3e3;
  border-top: 0;
  flex-direction: column;
  justify-content: end;
`;

const ColDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15%;
`;

const MenuButton = styled.button`
  height: 55px;
  width: 280px;
  background: #0796ee;
  border-radius: 8px;
  border: none;
  margin-top: 10%;
`;

const SecondaryButton = styled.button`
  height: 55px;
  width: 280px;
  background: #FFFFFF;
  border: 3px solid #CDE8E6;
  border-radius: 8px;
  margin-top: 10%;
  margin-right: 50px;
`;

const EndScreenSpan = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const Time = styled.h4`
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(88, 95, 102, 1);
  font-family: "Roboto";
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  margin-top: 25px;
`;

const ExerciseDetails = styled.h6`
  background-clip: text;
  -webkit-background-clip: text;
  color: #585f66;
  font-family: "Roboto";
  text-align: center;
  font-size: 25px;
  font-weight: 200;
`;

const Reps = styled.h4`
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(88, 95, 102, 1);
  font-family: "Roboto";
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  margin-top: 30%;
`;

const Wdh = styled.h6`
  background-clip: text;
  -webkit-background-clip: text;
  color: #585f66;
  font-family: "Roboto";
  text-align: center;
  font-size: 20px;
  font-weight: 200;
`;

const RepCircleDiv = styled.div`
  position: absolute;
  width: 150px;
  height: 150px;
  top: 3%;
  right: 3%;
  background-color: white;
  border-radius: 100px;
  border: 3px solid #e3e3e3;
  flex-direction: column;
  justify-content: end;
`;

export default FinishScreen;
