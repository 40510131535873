import * as poseDetection from "@tensorflow-models/pose-detection";
import * as mpPose from "@mediapipe/pose";


export const model = poseDetection.SupportedModels.BlazePose;

export const detectorConfig = {
    runtime: 'mediapipe', // or 'tfjs'
    modelType: 'full',
    solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`
};

export const VIDEO_CONSTRAINTS = {
    width: 500,
    height: 480,
    facingMode: "user",
    deviceId: "",
    frameRate: {max: 60, ideal: 30},
};


export function getObjectFitSize(
    contains /* true = contain, false = cover */,
    containerWidth,
    containerHeight,
    width,
    height
) {
    var doRatio = width / height;
    var cRatio = containerWidth / containerHeight;
    var targetWidth = 0;
    var targetHeight = 0;
    var test = contains ? doRatio > cRatio : doRatio < cRatio;

    if (test) {
        targetWidth = containerWidth;
        targetHeight = targetWidth / doRatio;
    } else {
        targetHeight = containerHeight;
        targetWidth = targetHeight * doRatio;
    }

    return {
        width: targetWidth,
        height: targetHeight,
        x: (containerWidth - targetWidth) / 2,
        y: (containerHeight - targetHeight) / 2,
    };
}

export function toTime(seconds) {
    var date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(14, 5);
}

export const override = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};

export function getColorGradients(colCanvas) {
    const originalHeight = colCanvas.height;
    const originalWidth = colCanvas.width;
    const dimensions = getObjectFitSize(
        true,
        colCanvas.clientWidth,
        colCanvas.clientHeight,
        colCanvas.width,
        colCanvas.height
    );
    colCanvas.width = dimensions.width;
    colCanvas.height = dimensions.height;
    let ratio = Math.min(
        colCanvas.clientWidth / originalWidth,
        colCanvas.clientHeight / originalHeight
    );

    let ctx = colCanvas.getContext("2d");
    ctx.scale(ratio, ratio);

    const greenGradient = ctx.createLinearGradient(
        0,
        0,
        colCanvas.width / 2,
        colCanvas.height / 2
    );
    greenGradient.addColorStop(0, "rgb(38,204,130)");
    greenGradient.addColorStop(1, "rgb(202,218,223)");

    const redGradient = ctx.createLinearGradient(
        0,
        0,
        colCanvas.width / 2,
        colCanvas.height / 2
    );
    redGradient.addColorStop(0, "rgb(251, 63, 63)");
    redGradient.addColorStop(1, "rgb(202,218,223)");

    return [greenGradient, redGradient, ctx];
}

export function drawBall(ctx, pH, width, height, red = false) {
    const ballGradient = ctx.createLinearGradient(
        0,
        0,
        width / 2,
        height / 2
    );
    if (red) {
        ballGradient.addColorStop(0, "rgb(246,41,41)");
        ballGradient.addColorStop(0.5, "rgb(177,35,35)");
    } else {
        ballGradient.addColorStop(0, "rgb(41, 246, 148)");
        ballGradient.addColorStop(0.5, "rgb(35,177,118)");
    }

    ctx.beginPath();
    ctx.arc(width * 0.3, pH, 20, 0, 2 * Math.PI);
    if (red) {
        ctx.strokeStyle = "rgba(219,39,39,0.2)";
    } else {
        ctx.strokeStyle = "rgba(39, 219, 136, 0.2)";
    }
    ctx.fillStyle = ballGradient;
    ctx.stroke();
    ctx.fill();
    return ctx
}