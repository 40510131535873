import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "../../../App.css";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import greenDot from "../../../assets/popupicon.png";

function InformalModal({
    isShowing,
    hide,
    hideCallback,
    finishCallback,
    countdown,
}) {
    const [remaining, setRemaining] = useState(5);

    useEffect(() => {
        if (countdown && isShowing) {
            if (remaining === 0) {
                hide();
                hideCallback();
                setRemaining(6);
            }
            const timer = setTimeout(() => {
                if (remaining > 0) {
                    setRemaining(remaining - 1);
                }
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown, isShowing, remaining]);

    if (
        isShowing &&
        countdown === null
    ) {
        setTimeout(function () {
            hide();
            hideCallback();
        }, 6000);
    }

    if (isShowing) {
        return ReactDOM.createPortal(
            <React.Fragment>
                <div className="modal-overlay" />
                <div
                    className="modal-wrapper"
                    aria-modal
                    aria-hidden
                    tabIndex={-1}
                    role="dialog"
                >
                    <div
                        className="modals"
                        style={{
                            width: "80%",
                            height: "100%",
                            background: "transparent",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <img
                                src={greenDot}
                                alt=""
                                width="180px"
                                height="180px"
                            />
                            <div style={{ width: "20px" }}></div>
                            <Stack spacing={2} direction="column">
                                <h2 style={{ color: "#CC654E" }}>Achtung !</h2>
                                <div
                                    style={{
                                        marginTop: "3%",
                                        fontSize: "20px",
                                        color: "#585F66",
                                        fontWeight:"bold"
                                    }}
                                >
                                    Folge dem Rhytmus des grünen Kreis. Nur wenn
                                    <br />
                                    die Kurve grün ist, erhälst du auch Punkte!
                                </div>
                                <Stack
                                    spacing={2}
                                    direction="row"
                                    alignItems="start"
                                    justifyContent="start"
                                >
                                    <Button
                                        variant="outlined"
                                        style={{
                                            textTransform: "none",
                                            borderRadius: "8px",
                                            width: "250px",
                                            background: "#fff",
                                            color: "#CC654E",
                                            borderColor: "#CC654E",
                                            borderWidth: "2px",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            fontSize: "20px",
                                        }}
                                        onClick={() => {
                                            hide();
                                            finishCallback();
                                        }}
                                    >
                                        Beenden
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            textTransform: "none",
                                            borderRadius: "8px",
                                            minWidth: "160px",
                                            background: "#2E99C2",
                                            color: "#fff",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            fontSize: "20px",
                                            width: "250px",
                                        }}
                                        onClick={() => {
                                            hide();
                                            hideCallback();
                                        }}
                                    >
                                        {countdown ? (
                                            <span>
                                                Fortsetzen in {remaining} Sek.
                                            </span>
                                        ) : (
                                            <span>Fortsetzen</span>
                                        )}
                                    </Button>
                                </Stack>
                            </Stack>
                        </div>
                    </div>
                </div>
            </React.Fragment>,
            document.body
        );
    } else {
        return null;
    }
}

export default InformalModal;
