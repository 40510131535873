import React, {useContext} from "react";
import styled from "styled-components";
import deskImage from "../../assets/desk.png";
import { ExerciseCard } from "./exercise_card";
import {ActivePauseCounterContext} from "../../App";
import TimeCircle from "./timecircle";
import { IconButton, Tooltip } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Home = () => {
  let { apc, _ } = useContext(
    ActivePauseCounterContext
  );
    const currentHour = new Date(Date.now()).getHours();
    const navigate = useNavigate();

    function CardList() {
        const listItems = exercises.map((exercise) => (
            <li key={exercise.id}>
                <ExerciseCard
                    path={exercise.path}
                    key={exercise.key}
                    title={exercise.title}
                    body={exercise.body}
                    type={exercise.type}
                    duration={exercise.duration}
                ></ExerciseCard>
            </li>
        ));
        return <ul>{listItems}</ul>;
    }

    const onLogout = (e) => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                localStorage.removeItem("username");
                localStorage.removeItem("userId");
                navigate(0);
            })
            .catch((error) => {
                // An error happened.
            });
    };

    return (
        <Container>
            <Logout>
                <Tooltip title="Logout">
                    <IconButton aria-label="logout">
                        <LogoutIcon
                            style={{ fontSize: "30px" }}
                            onClick={onLogout}
                        />
                    </IconButton>
                </Tooltip>
            </Logout>
            <Main>
                <HalloDiv>Hallo,</HalloDiv>
                <SubHeadDiv>Zeit für eine aktive Pause</SubHeadDiv>

                <CardList></CardList>
                <div style={{ textAlign: "left", marginLeft: "70px" }}>
                    <a
                        href="datenschutz"
                        style={{ fontSize: "10px", marginRight: "20px" }}
                    >
                        Datenschutzerklärung
                    </a>
                    <a href="impressum" style={{ fontSize: "10px" }}>
                        Impressum
                    </a>
                </div>
            </Main>
            <SideBar>
                <HoursDiv>
                    <TimeCircle></TimeCircle>
                </HoursDiv>
                <BlueDiv></BlueDiv>
                <Logo>
                    <img src={deskImage} alt="" width="65%" />
                </Logo>
            </SideBar>
            <Version>V0.13</Version>
        </Container>
    );
};

const exercises = [
    {
        id: 1,
        title: "Squats",
        body: "Stärken Sie Ihre Muskeln mit dieser effektiven Kraftübung für die Beine.",
        type: "Kraft",
        duration: "2 min",
    path: "/place_laptop"
    },
    {
        id: 2,
        title: "Oberkörper Dehnen (WY)",
        body: "Stärken Sie Ihre Muskeln mit dieser effektiven Kraftübung für die Beine.",
        type: "Dehnen",
        duration: "2 min",
    path: "/position_wy"
    },
    {
        id: 3,
        title: "Oberkörper Dehnen",
        body: "Stärken Sie Ihre Muskeln mit dieser effektiven Kraftübung für die Beine.",
        type: "Dehnen",
        duration: "2 min",
    path: "/position_stretch"
    },
];

const Container = styled.div`
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr;
    grid-template-columns: 0.35fr 0.65fr;
    grid-template-areas: "sidebar main";
    text-align: center;
    transition: all 0.25s ease-in-out;
    @media (max-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0.4fr 0.6fr;
        grid-template-areas:
            "sidebar"
            "main";
    }
    color: white;
`;

const Logout = styled.div`
    position: fixed;
    top: 10px;
    right: 10px;
`;

const Main = styled.main`
    background: linear-gradient(180deg, #ffffff 0%, #e4f8ff 100%);
    color: white;
    grid-area: main;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
`;
const SideBar = styled.div`
    background: #dcf0f7;
    grid-area: sidebar;
    position: relative;
    overflow: hidden;
`;
const Logo = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 6.9%;
    left: 0;
    right: 0;
`;
const BlueDiv = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 17vh;
    background: #b8dcea;
`;
const HoursDiv = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    padding-top: 7%;
    left: 0;
    right: 0;
`;

const HalloDiv = styled.div`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    margin: 30px 0px 5px 60px;
    font-size: 64px;
    font-weight: 300;
    display: flex;
`;

const SubHeadDiv = styled.div`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 0.6);
    font-family: "Roboto";
    font-weight: 300;
    margin: 5px 0px 5px 60px;
    font-size: 36px;
    display: flex;
`;

const Version = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: black;
    font-size: 12px;
`

export default Home;
