import React, {useContext} from "react";
import styled from "styled-components";
import Webcam from "react-webcam";
import { useEffect, useRef, useState } from "react";
import deskImage from "../../../../assets/position_user.png";
// eslint-disable-next-line
import * as tfJsCore from "@tensorflow/tfjs-core";
// eslint-disable-next-line
import * as tfJsConverter from "@tensorflow/tfjs-converter";
// eslint-disable-next-line
import * as tfJsBackendWebgl from "@tensorflow/tfjs-backend-webgl";
import * as poseDetection from "@tensorflow-models/pose-detection";
import {
    calcHipKneeDistance,
    calcKneeAnkleDistance,
    isPostureCorrect,
    setHipKneeDistance,
    setKneeAnkleDistance,
    setLimbsStatus,
} from "../../../../services/workout_analysis/handler";
import { useNavigate } from "react-router-dom";
import { AnalyticsContext } from "../../../../App";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const VIDEO_CONSTRAINTS = {
    width: 500,
    height: 480,
    facingMode: "user",
    deviceId: "",
    frameRate: { max: 60, ideal: 30 },
};

const MOVENET_CONFIG = {
    maxPoses: 1,
    type: "lightning",
    scoreThreshold: 0.3,
};

const DEFAULT_LINE_WIDTH = 5;
const DEFAULT_RADIUS = 10;

let detector;
let startInferenceTime,
    numInferences = 0;
let inferenceTimeSum = 0,
    lastPanelUpdate = 0;
let rafId;
let canvasFullScreen;
let ctxFullScreen;
let model;
let modelType;
let instructions = "";

const PositionScreen = () => {
    const [cameraReady, setCameraReady] = useState(false);
    const [displayFps, setDisplayFps] = useState(0);
    const webcamRef = useRef({});
    const navigate = useNavigate();
    let webAnalytics = useContext(AnalyticsContext);
    const [countdown, setCountdown] = useState(false);
    const [alert, setAlert] = useState(false);

    var wdh = 0;
    var start = Date.now();

    var correctCount = 0;

    const onUserMediaError = () => {
        console.log("ERROR in Camera!");
    };

    const onUserMedia = () => {
        console.log("Camera loaded!");
        setCameraReady(true);
    };

    useEffect(() => {
        webAnalytics.analytics.track("position_screen");
        _loadPoseNet().then();

        // eslint-disable-next-line
    }, []);

    const _loadPoseNet = async () => {
        if (rafId) {
            window.cancelAnimationFrame(rafId);
            detector.dispose();
        }

        detector = await createDetector();
        await renderPrediction();
    };

    const createDetector = async () => {
        model = poseDetection.SupportedModels.MoveNet;
        modelType = poseDetection.movenet.modelType.SINGLEPOSE_THUNDER; //or SINGLEPOSE_THUNDER
        return await poseDetection.createDetector(model, {
            modelType: modelType,
        });
    };

    const renderPrediction = async () => {
        await renderResult();
        rafId = requestAnimationFrame(renderPrediction);
    };

    const renderResult = async () => {
        if (Date.now() - start >= 5000) {
            console.log(wdh / 5);
            start = Date.now();
            wdh = 0;
        }
        wdh += 1;

        const video = webcamRef.current && webcamRef.current["video"];

        if (!cameraReady && !video) {
            return;
        }

        if (video.readyState < 2) {
            return;
        }

        beginEstimatePosesStats();
        const poses = await detector.estimatePoses(video, {
            maxPoses: MOVENET_CONFIG.maxPoses, //When maxPoses = 1, a single pose is detected
            flipHorizontal: false,
        });
        endEstimatePosesStats();
        drawCtxFullScreen(video);

        if (poses.length > 0 && avgPoseScore(poses) > 0.5) {
            drawResultsFullScreen(poses);
        }
    };

    const beginEstimatePosesStats = () => {
        startInferenceTime = (performance || Date).now();
    };

    const endEstimatePosesStats = () => {
        const endInferenceTime = (performance || Date).now();
        inferenceTimeSum += endInferenceTime - startInferenceTime;
        ++numInferences;
        const panelUpdateMilliseconds = 1000;

        if (endInferenceTime - lastPanelUpdate >= panelUpdateMilliseconds) {
            const averageInferenceTime = inferenceTimeSum / numInferences;
            inferenceTimeSum = 0;
            numInferences = 0;
            setDisplayFps(1000.0 / averageInferenceTime, 120);
            lastPanelUpdate = endInferenceTime;
        }
    };

    const drawCtxFullScreen = (video) => {
        canvasFullScreen = document.getElementById("output-full-screen");
        ctxFullScreen = canvasFullScreen.getContext("2d");

        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        video.width = videoWidth;
        video.height = videoHeight;

        canvasFullScreen.width = videoWidth;
        canvasFullScreen.height = videoHeight;
        ctxFullScreen.fillRect(0, 0, videoWidth, videoHeight);

        ctxFullScreen.translate(video.videoWidth, 0);
        ctxFullScreen.scale(-1, 1);
        ctxFullScreen.drawImage(video, 0, 0, videoWidth, videoHeight);
    };

    const drawResultsFullScreen = (poses) => {
        for (const pose of poses) {
            drawResult(pose);
            // angles = doReps(poses[0].keypoints);
        }
    };

    const navigateToExercise = () => {
        if (isPostureCorrect()) {
            navigate("/new_game");
        } else {
            setCountdown(false);
        }
    };

    const drawResult = (pose) => {
        if (pose.keypoints != null) {
            drawKeypoints(pose.keypoints);
            //drawSkeleton(pose.keypoints);
        }
    };

    const drawKeypoints = (keypoints) => {
        const keypointInd = poseDetection.util.getKeypointIndexBySide(model);
        ctxFullScreen.fillStyle = "White";
        ctxFullScreen.strokeStyle = "White";
        ctxFullScreen.lineWidth = DEFAULT_LINE_WIDTH;

        // draw eyes -> always green
        ctxFullScreen.fillStyle = "Green";
        ctxFullScreen.strokeStyle = "Green";
        drawKeypoint(keypoints[keypointInd.left[0]]);
        drawKeypoint(keypoints[keypointInd.right[0]]);

        let nose = keypoints[keypointInd.middle[0]];

        // shoulders
        let shouldersCorrect = drawPair(
            keypoints[keypointInd.left[2]],
            keypoints[keypointInd.right[2]],
            nose
        );

        /* Optional: dynamic instructions based on current state
        if (shouldersCorrect) {
            instructions = "Stellen Sie sicher, dass Ihre Schultern zu sehen sind. " +
                "Neigen Sie evtl. die Kamera etwas weiter nach oben oder bewegen sich weiter nach hinten"
        }
         */

        // elbows
        let elbowsCorrect = drawPair(
            keypoints[keypointInd.left[3]],
            keypoints[keypointInd.right[3]],
            nose
        );

        // hands
        let handsCorrect = drawHands(
            keypoints[keypointInd.left[4]],
            keypoints[keypointInd.right[4]],
            nose,
            keypoints[keypointInd.left[5]],
            keypoints[keypointInd.right[5]]
        );

        // hips
        let hipsCorrect = drawPair(
            keypoints[keypointInd.left[5]],
            keypoints[keypointInd.right[5]],
            nose
        );

        // knees
        let kneesCorrect = drawPair(
            keypoints[keypointInd.left[6]],
            keypoints[keypointInd.right[6]],
            nose
        );

        //ankles
        let anklesCorrect = drawPair(
            keypoints[keypointInd.left[7]],
            keypoints[keypointInd.right[7]],
            nose
        );


        if (!(avgPoseScoreFace(keypoints) > 0.55 && avgPoseScoreLowerBody(keypoints) < 0.3)) {
            if (avgPoseScoreLowerBody(keypoints) > 0.5) {
                if (anklesCorrect && hipsCorrect) {
                    instructions = "";
                } else {
                    const camera_down = "Neigen Sie die Kamera weiter nach unten oder bewegen" +
                        " Sie sich weiter nach hinten";
                    if (!anklesCorrect) {
                        instructions = "Stellen Sie sicher, dass Ihre Füße zu sehen sind. " + camera_down
                    }

                    /* only relevant when shoulders needs to be correct
                    if (!anklesCorrect && !shouldersCorrect) {
                        instructions = "Stellen Sie sicher, dass Ihre Füße und Schultern zu sehen sind. " +
                            "Bewegen Sie sich weiter nach hinten oder passen die Kameraneigung an."
                    }
                     */

                    if (anklesCorrect && !hipsCorrect) {
                        instructions = "Stellen Sie sicher, dass Ihre Hüfte zu sehen ist. Neigen Sie die Kamera " +
                            "weiter nach oben oder bewegen Sie sich weiter nach hinten"
                    }
                }
            } else {
                instructions = "";
            }
        } else {
            instructions = "";
        }

        if (
            hipsCorrect &&
            kneesCorrect &&
            anklesCorrect
        ) {
            correctCount++;

            if (correctCount > 10) {
                setLimbsStatus(true);

                // use knee hip distance to determine angle or "level" of pushup
                setHipKneeDistance(calcHipKneeDistance(keypoints));
                setKneeAnkleDistance(calcKneeAnkleDistance(keypoints));
                setCountdown(true);
            }
        } else {
            setLimbsStatus(false);
            setHipKneeDistance(0.0);
            if (correctCount > 0) {
                correctCount--;
            }
            if (correctCount === 0) {
                setCountdown(false);
            }
        }
    };

    function drawHands(left, right, nose, hipLeft, hipRight) {
        let aligned = keypointsAligned(left, right, nose);
        let y_hip = (hipLeft.y + hipRight.y) / 2;
        let correctHeightL = left.y > y_hip * 0.93 && left.y < y_hip * 1.07;
        let correctHeightR = right.y > y_hip * 0.93 && right.y < y_hip * 1.07;
        if (aligned && correctHeightL && correctHeightR) {
            ctxFullScreen.fillStyle = "Green";
            ctxFullScreen.strokeStyle = "Green";
            drawKeypoint(left);
            drawKeypoint(right);
            return true;
        }

        if (!correctHeightL) {
            ctxFullScreen.fillStyle = "Green";
            ctxFullScreen.strokeStyle = "Green";
            let hipDist = Math.abs(hipRight.x - hipLeft.x);
            const kp = {
                x: hipLeft.x + hipDist / 2,
                y: y_hip,
            };
            drawKeypoint(kp);
            ctxFullScreen.fillStyle = "Red";
            ctxFullScreen.strokeStyle = "Red";
            drawKeypoint(left);

            const lingrad = ctxFullScreen.createLinearGradient(
                kp.x,
                kp.y,
                left.x,
                left.y
            );
            lingrad.addColorStop(0, "Green");
            lingrad.addColorStop(1, "Red");
            ctxFullScreen.fillStyle = lingrad;
            ctxFullScreen.strokeStyle = lingrad;
            ctxFullScreen.beginPath();
            ctxFullScreen.moveTo(kp.x, kp.y);
            ctxFullScreen.lineTo(left.x, left.y);
            ctxFullScreen.stroke();
        } else {
            ctxFullScreen.fillStyle = "Green";
            ctxFullScreen.strokeStyle = "Green";
            drawKeypoint(left);
        }
        if (!correctHeightR) {
            ctxFullScreen.fillStyle = "Green";
            ctxFullScreen.strokeStyle = "Green";
            let hipDist = Math.abs(hipRight.x - hipLeft.x);
            let kp = {
                x: hipRight.x - hipDist / 2,
                y: y_hip,
            };
            drawKeypoint(kp);
            ctxFullScreen.fillStyle = "Red";
            ctxFullScreen.strokeStyle = "Red";
            drawKeypoint(right);

            const lingrad = ctxFullScreen.createLinearGradient(
                kp.x,
                kp.y,
                right.x,
                right.y
            );
            lingrad.addColorStop(0, "Green");
            lingrad.addColorStop(1, "Red");
            ctxFullScreen.fillStyle = lingrad;
            ctxFullScreen.strokeStyle = lingrad;
            ctxFullScreen.beginPath();
            ctxFullScreen.moveTo(kp.x, kp.y);
            ctxFullScreen.lineTo(right.x, right.y);
            ctxFullScreen.stroke();
        } else {
            ctxFullScreen.fillStyle = "Green";
            ctxFullScreen.strokeStyle = "Green";
            drawKeypoint(right);
        }
        return false;
    }

    function drawPair(left, right, nose) {
        let correct = keypointsAligned(left, right, nose);
        if (left.score < 0.60 || right.score < 0.60) {
            correct = false;
        }
        if (correct) {
            correct = true;
            ctxFullScreen.fillStyle = "Green";
            ctxFullScreen.strokeStyle = "Green";
        } else {
            ctxFullScreen.fillStyle = "Red";
            ctxFullScreen.strokeStyle = "Red";
        }

        drawKeypoint(left);
        drawKeypoint(right);
        return correct;
    }

    function keypointsAligned(left, right, nose, tol = 250) {
        if (nose.score > 0.6) {
            let dLeftNose = Math.abs(nose.x - left.x);
            let dRightNose = Math.abs(right.x - nose.x);
            let xDiff = Math.abs(dLeftNose - dRightNose);
            let totalXDiff = Math.abs(right.x - left.x);
            let yDiff = Math.abs(left.y - right.y);
            if (totalXDiff < 100) {
                return xDiff < tol && yDiff < tol;
            }

            return xDiff < totalXDiff && yDiff < tol;
        } else {
            return left.score + right.score > 1.2;
        }
    }

    const drawKeypoint = (keypoint) => {
        // If score is null, just show the keypoint.
        const score = keypoint.score != null ? keypoint.score : 1;
        const scoreThreshold = MOVENET_CONFIG.scoreThreshold || 0.7;

        if (score >= scoreThreshold) {
            const circle = new Path2D();
            circle.arc(keypoint.x, keypoint.y, DEFAULT_RADIUS, 0, 2 * Math.PI);
            ctxFullScreen.fill(circle);
            ctxFullScreen.stroke(circle);
        }
    };

    function avgPoseScore(poses) {
        let sum = 0.0;
        for (let p of poses) {
            sum += p.score;
        }
        return sum / poses.length;
    }

    function avgPartialPoseScore(poses, start, end) {
        let sum = 0.0;
        for (let i = start; i < end; i++) {
            if (poses[i] === undefined) {
                sum += 0;
            } else {
                sum += poses[i].score;
            }
        }
        return sum / (end - start);
    }

    function avgPoseScoreFace(poses) {
        return avgPartialPoseScore(poses, 0, 5);
    }

    function avgPoseScoreLowerBody(poses) {
        return avgPartialPoseScore(poses, 11, 17);
    }

    const RenderTime = ({ remainingTime }) => {
        return (
            <TimerContentWrapper>
                <TimerValue>{remainingTime}</TimerValue>
            </TimerContentWrapper>
        );
    };

    const addInstructions = () => {
        if (instructions !== "") {
            return (
                <PoseDescription>
                    <span style={{color: "#ffffff", fontWeight: "bold"}}>
                        {instructions}
                    </span>
                </PoseDescription>
            )
        }
        return (
            <PoseDescription>
                Stellen Sie sich vor die Kamera, so dass Ihre{" "}
                <span style={{ fontWeight: "bold" }}>
                                Hüfte
                            </span>
                ,<span style={{ fontWeight: "bold" }}> Knie</span>,{" "}
                <span style={{ fontWeight: "bold" }}>Schultern </span>{" "}
                und{" "}
                <span style={{ fontWeight: "bold" }}>
                                Kniegelenke
                            </span>{" "}
                frontal sichtbar sind.
                <br />
                <br />
                Die Übung startet automatisch, sobald Sie richtig
                stehen.
            </PoseDescription>
        );
    }

    return (
        <Container>
            <Canvas id="output-full-screen"></Canvas>
            <Camera
                className="filter blur-lg"
                ref={webcamRef}
                style={{ visibility: "hidden" }}
                audio={false}
                videoConstraints={VIDEO_CONSTRAINTS}
                onUserMediaError={onUserMediaError}
                onUserMedia={onUserMedia}
            />

            <SideBar>
                {!countdown ? (
                    <>
                        <Title>Positionieren Sie sich</Title>
                        {addInstructions()}
                        <Logo>
                            <img src={deskImage} alt="" />
                        </Logo>
                    </>
                ) : (
                    <>
                        <Title>Perfekt! Bleiben Sie in Position</Title>
                        <PoseDescription>Übung startet in...</PoseDescription>
                        <TimerWrapper>
                            <CountdownCircleTimer
                                isPlaying
                                duration={8}
                                trailColor={"rgba(255, 255, 255, 0.4)"}
                                colors={["#73F573"]}
                                onComplete={navigateToExercise}
                                onUpdate={(remaining) => {
                                    !isPostureCorrect()
                                        ? setAlert(true)
                                        : setAlert(false);
                                }}
                                size={330}
                                strokeWidth={10}
                            >
                                {RenderTime}
                            </CountdownCircleTimer>
                            <AlertText
                                style={
                                    alert
                                        ? { visibility: "visible" }
                                        : { visibility: "hidden" }
                                }
                            >
                                Aufmerksamkeit ! halten Sie
                                <br /> Ihre Position
                            </AlertText>
                        </TimerWrapper>
                    </>
                )}
            </SideBar>
        </Container>
    );
};

const Container = styled.div`
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr;
    grid-template-columns: 0.4fr 0.6fr;
    grid-template-areas: "sidebar camera";
    transition: all 0.25s ease-in-out;
`;

const SideBar = styled.div`
    background: radial-gradient(
        363.87% 138.58% at 2.13% 0%,
        rgba(102, 109, 162) 10.04%,
        rgba(179, 216, 230) 100%
    );
    grid-area: sidebar;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;


const Camera = styled(Webcam)`
    width: 100%;
    height: 100%;
    position: fixed;
`;

const Canvas = styled.canvas`
    width: 100%;
    height: 100%;
`;

const TimerValue = styled.div`
    font-size: 100px;
    color: #73f573;
`;

const TimerContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;


const Title = styled.h4`
    background-clip: text;
    -webkit-background-clip: text;
    color: #fff;
    font-family: "Roboto";
    align-items: start;
    font-size: 40px;
    font-weight: 400;
    margin-top: 50px;
    margin-left: 10%;
    @media (max-width: 1440px) {
        font-size: 32px;
        margin-left: 5%;
        margin-top: 5%
    }
`;

const PoseDescription = styled.p`
    color: #fff;
    font-family: "Roboto";
    font-weight: 300;
    font-size: 26px;
    margin-top: 50px;
    margin-left: 10%;
    margin-right: 10px;
    @media (max-width: 1440px) {
        font-size: 22px;
        margin-left: 5%;
        margin-top: 25px;
    }
`;

const Logo = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    width: 90%;
    @media (max-width: 1440px) {
        width: 90%;
        margin-top: 75px;
    }
`;

const TimerWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 25%;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1440px) {
        margin-top: 10%;
    }
`;

const AlertText = styled.h1`
    color: rgb(245, 27, 27);
    font-family: "Roboto";
    font-weight: 400;
    font-size: 35px;
    margin-top: 20px;
    @media (max-width: 1280px) {
        font-size: 28px;
    }
`;


export default PositionScreen;
