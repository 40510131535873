import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getScores, sortScores } from "../../../services/firebase";
import WinnersRanking from "./winners_ranking";

let first = {
    userName: "AlexissancheS",
    score: [23526],
    workouts: [],
    duration: [],
};
let second = {
    userName: "anna_dar",
    score: [5022],
    workouts: [],
    duration: [],
};
let third = {
    userName: "Mathias",
    score: [1230],
    workouts: [],
    duration: [],
};
const FinishChallenge = () => {
    const navigate = useNavigate();
    let username = localStorage.getItem("username");
    let currentUser = username;

    const [myRank, setMyRank] = useState(0);
    //setBest3(best)

    useEffect(() => {
    }, []);

    function getEntries(arr, username) {
        arr = sortScores(arr);
        const index = arr.findIndex((elem) => elem.userName === username);
        if (index === -1) {
            return null;
        }
        setMyRank(index + 1);

        const result = [];
        const rank = [];

        for (let i = 0; i < arr.length - 3; i++) {
            result.push(arr[i + 3]);
            rank.push(i + 3 + 1);
        }
        return [result, rank];
    }

    return (
        <Container>
            <Title>
                <HalloDiv>Hallo {currentUser},</HalloDiv>
                <SubHeadDiv>
                    Die Squat-Challenge ist vorbei.
                </SubHeadDiv>
            </Title>
            <Body>
                <WinnersTxt>Unsere Gewinner</WinnersTxt>
                <WinnersRow>
                    <WinnersRanking
                        score={
                            second.score?.reduce(
                                (acc, val) => acc + val,
                                0
                            ) || 0
                        }
                        username={second.userName}
                        place={2}
                    ></WinnersRanking>
                    <WinnersRanking
                        score={
                            first.score?.reduce(
                                (acc, val) => acc + val,
                                0
                            ) || 0
                        }
                        username={first.userName}
                        place={1}
                    ></WinnersRanking>
                    <WinnersRanking
                        score={
                            third.score?.reduce(
                                (acc, val) => acc + val,
                                0
                            ) || 0
                        }
                        username={third.userName}
                        place={3}
                        style={{ marginLeft: "auto" }}
                    ></WinnersRanking>
                </WinnersRow>
                <MoreButton
                    onClick={() =>
                        navigate(`/leaderboard`, {
                            state: { currentUser },
                        })
                    }
                >
                    Vollständiges Ranking anzeigen &rarr;
                </MoreButton>
            </Body>
        </Container>
    );
};

const Container = styled.div`
    display: grid;
    height: 100vh;
    grid-template-rows: 0.2fr 0.8fr;
    grid-template-columns: 1fr;
    grid-template-areas: "title" "body";
    transition: all 0.25s ease-in-out;
    background: linear-gradient(180deg, #ffffff 0%, #e5f8ff 100%); ;
`;

const Title = styled.div`
    grid-area: title;
    display: flex;
    flex-direction: column;
`;

const Body = styled.div`
    grid-area: body;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3%;
`;

const HalloDiv = styled.div`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    margin: 60px 0px 5px 70px;
    font-size: 3.9vw;
    font-weight: 300;
    display: flex;
`;

const SubHeadDiv = styled.div`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 0.6);
    font-family: "Roboto";
    font-weight: 300;
    margin: 0px 0px 0px 70px;
    font-size: 2vw;
    display: flex;
`;

const WinnersTxt = styled.div`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    font-weight: 300;
    font-size: 50px;
    display: flex;
    margin-top: 10px;
`;

const WinnersRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: flex-end;
    margin-top: 3%;
`;

const MoreButton = styled.button`
    background: #d9ecf2;
    width: 70%;
    height: 90px;
    color: #626970;
    font-family: Roboto;
    font-weight: 400;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: auto;
    display: flex;
    justify-content: right;
    align-items: center;
`;

export default FinishChallenge;
