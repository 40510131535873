import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";

const CoinProgress = (props) => {
  const { completed, numberCoins } = props;

  const containerStyles = {
    height: 10,
    width: "80%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    marginLeft: 50,
    marginRight: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: "#46CF95",
    borderRadius: "inherit",
    textAlign: "right",
  };

  const batchContainerStyle = {
    marginLeft: `${completed}%`,
    marginTop: 15,
  };

  return (
    <div>
      <div style={containerStyles}>
        <div style={fillerStyles}></div>
      </div>
      <Container type={"button"}>
        <div className="arrow_box" style={batchContainerStyle}>
          <IconSpan>
            <FontAwesomeIcon icon={faCoins} color="#FFEF5B" />
          </IconSpan>
          <TxtSpan>{numberCoins}</TxtSpan>
        </div>
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  padding: 0;
  cursor: pointer;
`;

const IconSpan = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  color: #0796ee;
  height: 100%;
  font-size: 1.3em;
`;

const TxtSpan = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  color: #46cf95;
  height: 100%;
  font-size: 30px;
`;

export default CoinProgress;
