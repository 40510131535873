import {useEffect, useState} from "react";
import {getDetector, VIDEO_CONSTRAINTS} from "../../../../services/workout_analysis/blazePoseUtils";
import {calcHandsAngle, calcHandsPosition, calcShoulderAngle} from "../../../../services/workout_analysis/handler";
import React, {useRef} from "react";
import Webcam from "react-webcam";
import styled from "styled-components";
import CoinProgress from "../progressbar-coin";
import { useNavigate } from "react-router-dom";
import * as mpPose from "@mediapipe/pose";
import * as poseDetection from "@tensorflow-models/pose-detection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPause} from "@fortawesome/free-solid-svg-icons";
import Modal from "../PauseModal";
import {useModal} from "../../../../hooks/useModal";
import BounceLoader from "react-spinners/BounceLoader";
import {useContext} from "react";
import {ActivePauseCounterContext} from "../../../../App";
import {getObjectFitSize} from "../utils";

let detector = null;
let x_pos_hands = 0;
let seg_pos = 0.5;
let seg_incr = 0.0007;
let counter = 0;
let increaseCoins = false;
let curr_coins = 0;
let posHistory = [];
let isPaused = false;
let coinsGoal = 50;

const override = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};

const StretchScreen = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [cameraReady, setCameraReady] = useState(false);
    const [coins, setCoins] = useState(0);
    const webcamRef = useRef({});
    const {isShowing, toggle} = useModal();
    const navigate = useNavigate();
    let [loading, setLoading] = useState(true);
    let {_, increaseActivePauseCounter} = useContext(ActivePauseCounterContext);

    useEffect(() => {
        setTimeout(() => {
            getDetector().then((det) => {
                detector = det;
                setIsPlaying(true);
                setLoading(false);
            });
        }, 1000);
    })

    useEffect(() => {
        animationLoop()
    }, []);

    const onUserMediaError = () => {
        console.log('ERROR in Camera!');
    };

    const onUserMedia = () => {
        console.log('Camera loaded!');
        setCameraReady(true);
    };

    async function animationLoop() {
        if (detector != null) {
            poseNetLoop();
        }
        setTimeout(function() {
            if (detector != null) {
                //poseNetLoop()
            }
            animationLoop();
            let upper = 0.95;
            let lower = 0.55;
            
            if (seg_pos > upper || seg_pos < lower) {
                seg_incr *= -1;
                if (seg_pos > upper) {
                    seg_pos = upper;
                    counter = 500;
                }
                if (seg_pos < lower) {
                    seg_pos = lower;
                    counter = 500;
                }
            } else {
                if (counter === 0) {
                    seg_pos += seg_incr;
                } else {
                    counter--;
                    if (increaseCoins && counter % 50 === 0) {
                        curr_coins++;
                        setCoins(curr_coins);
                        if (curr_coins === coinsGoal) {
                            increaseActivePauseCounter();
                            breakUp("/");
                        }
                    }
                }
            }
        }, 1000 / 100)

    }

    async function poseNetLoop() {
        const video = webcamRef.current && webcamRef.current['video'];
        if (!cameraReady && !video) {
            return;
        }
        if (video.readyState < 2) {
            return;
        }

        const poses = await detector.estimatePoses(video, {
            maxPoses: 1,
            flipHorizontal: false
        });

        if (poses != null) {
            let pos = calcHandsPosition(poses[0].keypoints3D);
            let ha = calcShoulderAngle(poses[0].keypoints3D);
            //setHa1(ha[0]);
            //setHa2(ha[1]);
            //console.log(ha);
            // Add the new position to the history
            posHistory.push(pos);

            // Keep the history at a maximum length of x
            if (posHistory.length > 30) {
                posHistory.shift();
            }

            // Calculate the average position of the last 5 samples
            let avgPos = [0, 0];
            for (let i = 0; i < posHistory.length; i++) {
                avgPos[0] += posHistory[i][0];
                avgPos[1] += posHistory[i][1];
            }
            avgPos[0] /= posHistory.length;
            avgPos[1] /= posHistory.length;

            // Draw the average position
            draw(avgPos[0], avgPos[1]);
        }
    }

    function draw(x_pos, x_pos2) {
        let canvas = document.getElementById("myCanvas");
        const originalHeight = canvas.height;
        const originalWidth = canvas.width;
        const dimensions = getObjectFitSize(
            true,
            canvas.clientWidth,
            canvas.clientHeight,
            canvas.width,
            canvas.height
        );
        canvas.width = dimensions.width;
        canvas.height = dimensions.height;
        let ratio = Math.min(
            canvas.clientWidth / originalWidth,
            canvas.clientHeight / originalHeight
        );
        let ctx = canvas.getContext("2d");
        ctx.scale(ratio, ratio);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        drawLine(ctx, canvas, x_pos);
        drawLine(ctx, canvas, x_pos2);
        drawCircle(ctx, canvas, seg_pos);

        //let coeff1 = (0.95 - seg_pos) * 2;
        //let coeff2 = 1 - coeff1;
        let coeff1 = 1 / (1 + Math.exp(10 * (seg_pos - 0.75)));
        let coeff2 = 1 - coeff1;
        //console.log("Coeff1  ", coeff1);
        //console.log("Coeff2  ", coeff2);
        drawPoint(ctx, canvas, x_pos * coeff1 + x_pos2 * coeff2)
        /*
        if (seg_pos < 0.75) {
            drawPoint(ctx, canvas, x_pos);
        } else {
            drawPoint(ctx, canvas, x_pos2);
        }

         */
        //drawPoint(ctx, canvas, (x_pos + x_pos2) / 2);
        console.log(x_pos);
        setIncreaseCoins(x_pos, x_pos2);
    }

    function setIncreaseCoins(x_l, x_r) {
        // seg_pos 0.55 - 0.95
        // x -0.5 - 0.5
        increaseCoins = (x_l < -0.05 && seg_pos === 0.55) || (seg_pos === 0.95 && x_r > 0.05);
    }

    function halfCircle(r, x, center) {
        return Math.sqrt(r**2 - (x - center)**2)
    }

    function drawPoint(ctx, canvas, pos) {
        let x_pos = canvas.width/2 + pos * 1.5 * canvas.width/1.5;
        let y_pos = canvas.height - halfCircle(canvas.height * 0.825, x_pos, canvas.width/2);
        ctx.beginPath();

        ctx.arc(x_pos, y_pos, 20, 0, 2*Math.PI, true);
        ctx.fillStyle = "rgb(8,45,30)";//"rgb(35,177,118)";//"rgba(219,39,39,0.2)";
        ctx.fill();
    }

    function getSegmentationGradient(gradient, pos) {
        // pos between 0.5 and 1 (=> 0.75 = center)
        // 0.1
        gradient.addColorStop(pos - 0.05, "rgb(35,177,118)");
        gradient.addColorStop(pos, "rgb(35,177,118)");
        // < 0.9
        if (pos < 0.95) {
            // + 0.1
            gradient.addColorStop(pos + 0.05, "rgb(35,177,118)");
        }

        // ?
        if (pos > -1) {
            //
            gradient.addColorStop(pos - 0.1, "rgba(251,63,63,0.5)");
        }
        if (pos < 0.9) {
            gradient.addColorStop(pos + 0.1, "rgba(251,63,63,0.5)");
        }
    }


    function drawCircle(ctx, canvas, pos) {

        let gradient = ctx.createConicGradient(0, canvas.width/2, canvas.height);

        getSegmentationGradient(gradient, pos);

        ctx.beginPath();
        ctx.arc(canvas.width/2, canvas.height, canvas.height * 0.9, 0, Math.PI, true);
        ctx.fillStyle = gradient;//"rgba(219,39,39,0.2)";
        ctx.fill();

        ctx.beginPath();
        ctx.arc(canvas.width/2, canvas.height, canvas.height * 0.75, 0, Math.PI, true);
        ctx.fillStyle = "rgb(255,255,255)";
        ctx.fill();

        /*
        ctx.beginPath();
        gradient = ctx.createLinearGradient(
            canvas.width / 2,
            canvas.height,
            canvas.width / 2,
            0

        );

        gradient.addColorStop(0, "rgba(41,246,148,0.8)");
        gradient.addColorStop(0.5, "rgb(35,177,118, 0.8)");
        ctx.fillStyle = gradient;
        ctx.moveTo(canvas.width/2, canvas.height);
        ctx.arc(canvas.width/2, canvas.height, canvas.height * 0.9, pos - (Math.PI/10), pos + (Math.PI/10));
        ctx.fill();
         */
    }

    function drawLine(ctx, canvas, x_pos) {
        ctx.beginPath();
        let x = canvas.width/2 + x_pos * 1.5 * canvas.width/2;
        ctx.moveTo(canvas.width/2, canvas.height);

        ctx.lineTo(x, 0);
        //ctx.lineTo(0, canvas.width/2);
        ctx.strokeStyle = "rgba(39, 219, 136, 0.2)";
        ctx.stroke();
        ctx.fill();
    }

    function resume() {
        isPaused = false;
        setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    }

    async function breakUp(route, args) {
        setLoading(!loading);
        setTimeout(function () {
            setLoading(!loading);
            navigate(route, {
                replace: false,
                state: args !== null || args !== undefined ? args : null,
            });
        }, 3000);
    }


    return (
        <div>
            <CoinProgressWrapper>
                <CoinProgress
                    completed={(coins / coinsGoal) * 100}
                    numberCoins={coins}>
                </CoinProgress>
            </CoinProgressWrapper>
            <CamDiv>
                <Webcam
                    className="filter blur-lg"
                    style={{ visibility: "hidden" }}
                    ref={webcamRef}
                    audio={false}
                    videoConstraints={VIDEO_CONSTRAINTS}
                    onUserMediaError={onUserMediaError}
                    onUserMedia={onUserMedia}
                />
            </CamDiv>
            <Canvas id="myCanvas" style={{ objectFit: "contain" }}></Canvas>
            <PauseDiv>
                <Butt type={"button"}>
                    <IconSpan>
                        <FontAwesomeIcon
                            icon={faPause}
                            onClick={() => {
                                isPaused = true;
                                setIsPlaying((prevIsPlaying) => !prevIsPlaying);
                                toggle();
                            }}
                        />
                    </IconSpan>
                    <TxtSpan>Pause</TxtSpan>
                </Butt>
            </PauseDiv>
            <Modal
                isShowing={isShowing}
                hide={toggle}
                hideCallback={resume}
                finishCallback={() => breakUp("/")}
            />
            <BounceLoader
                cssOverride={override}
                loading={loading}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
                color="hsla(168, 67%, 53%, 1)"
            />
        </div>
    )
}

const CamDiv = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #eaf9ff 100%);
  position: absolute;
  overflow: hidden;
`;

const CoinProgressWrapper = styled.div`
  padding-top: 40px;
  width: 50vw; /* half of the viewport width */
  margin: 0 auto; /* center horizontally */
  text-align: center;
`;

const PauseDiv = styled.div`
  position: absolute;
  left: 20px;
  top: 10px;
`;

const IconSpan = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  color: #0796ee;
  height: 100%;
  font-size: 1.5em;
`;

const TxtSpan = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  color: #0796ee;
  height: 100%;
`;

const Butt = styled.button`
  display: flex;
  height: 40px;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  border-radius: 5px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
`;

export default StretchScreen;