import styled from "styled-components";
import React, { useContext } from "react";
import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause } from "@fortawesome/free-solid-svg-icons";
// eslint-disable-next-line
import * as tfJsCore from "@tensorflow/tfjs-core";
// eslint-disable-next-line
import * as tfJsConverter from "@tensorflow/tfjs-converter";
// eslint-disable-next-line
import * as tfJsBackendWebgl from "@tensorflow/tfjs-backend-webgl";
import * as poseDetection from "@tensorflow-models/pose-detection";
import Webcam from "react-webcam";
import { WYAnalyticsBlaze } from "../../../../services/workout_analysis/workout_analytics";
import { useNavigate } from "react-router-dom";
import { useElapsedTime } from "use-elapsed-time";
import Modal from "../PauseModal";
import {useModal} from "../../../../hooks/useModal";
import BounceLoader from "react-spinners/BounceLoader";
import Coins from "../../../../services/workout_analysis/coins";
import CoinProgress from "../progressbar-coin";
import { ActivePauseCounterContext, AnalyticsContext } from "../../../../App";
import * as mpPose from "@mediapipe/pose";
import {drawBall, getObjectFitSize, toTime} from "../utils";

const VIDEO_CONSTRAINTS = {
    width: 500,
    height: 480,
    facingMode: "user",
    deviceId: "",
    frameRate: { max: 60, ideal: 30 },
};

const override = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};

let startInferenceTime,
    numInferences = 0;
let inferenceTimeSum = 0,
    lastPanelUpdate = 0;
let fpsSum = 0;
let counter = 0;

var isPaused = false;

var shoulderAngle = 0.0;
var shoulderAngleSum = 0.0;
var slidingWindowCount = 0;

let duration = 480;
let coinsGoal = 40;
const target_fps = 50;
var fps_counter = 0;
var dispFPS = 0;

let poseNetMutex = true;
let worker;
let loaded_detector = false;
let lastDiff = 101;
let outsideCount = 0;
let insideCount = 0;

let whd = 0;
let colors = [];
var mtx = 0;
let poseInstructions = "";

const model = poseDetection.SupportedModels.BlazePose;
let detector = null;
const detectorConfig = {
    runtime: "mediapipe", // or 'tfjs'
    modelType: "full",
    solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`,
};

const WYGameScreen = () => {
    const [cameraReady, setCameraReady] = useState(false);
    const [displayFps, setDisplayFps] = useState(0.0);
    const [isPlaying, setIsPlaying] = useState(true);
    //const [poseNetMutex, setPNM] = useState(true);
    const webcamRef = useRef({});
    const navigate = useNavigate();
    const [analytics] = useState(new WYAnalyticsBlaze());
    const [coins] = useState(new Coins());
    const { elapsedTime } = useElapsedTime({
        isPlaying: isPlaying,
        duration: duration,
        updateInterval: 0.005,
        onComplete: (time) => {
            isPaused = true;
            // webAnalytics.trackAverageFPS(fpsSum / counter);
            sendWorkoutEndStatistics(false);
            breakUp("/finish", {
                time: toTime(elapsedTime.toFixed(1)),
                title: "Squats - 2 min.",
                repititions: analytics.repetitions,
            });
        },
    });
    const { isShowing, toggle } = useModal();
    let [loading, setLoading] = useState(true);
    let { _, increaseActivePauseCounter } = useContext(
        ActivePauseCounterContext
    );
    // let webAnalytics = useContext(AnalyticsContext);

    const amplitude = 200;
    const frequency = 0.0085;

    useEffect(() => {
        poseDetection.createDetector(model, detectorConfig).then((det) => {
            detector = det;
            setLoading(false);
            loaded_detector = true;
        });

        //pause();
        coins.fillCoordinates((Math.PI / 2) * 5, duration, Math.PI); // TODO
        // webAnalytics.analytics.track("new_game");
        // webAnalytics.trackKneeHipDistance(getHipKneeDistance());
        // webAnalytics.trackKneeAnkleDistance(getKneeAnkleDistance());

        // worker = new Worker(
        //     new URL(
        //         "../../../services/workout_analysis/worker.js",
        //         import.meta.url
        //     )
        // );

        // worker.addEventListener("message", (event) => {
        //     const poses = event.data_knees.jsx;
        //     if (poses != null) {
        //         endEstimatePosesStats();
        //         calculateAngles(poses);
        //         if (!loaded_detector) {
        //             loaded_detector = true;
        //             setLoading(false);
        //         }
        //     }
        //     poseNetMutex = !poseNetMutex;
        // });

        poseInstructions = poseInstructions + "\ninstruct";
    }, []);

    async function poseNetLoop() {
        beginEstimatePosesStats();

        const video = webcamRef.current && webcamRef.current["video"];
        if (!cameraReady && !video) {
            poseNetMutex = !poseNetMutex;
            return;
        }
        if (video.readyState < 2) {
            poseNetMutex = !poseNetMutex;
            return;
        }

        const poses = await detector.estimatePoses(video, {
            maxPoses: 1,
            flipHorizontal: false,
        });

        if (poses != null) {
            endEstimatePosesStats();
            calculateAngles(poses);
        }
    }

    useEffect(() => {
        animationLoop();
    }, []);

    async function animationLoop() {
        // target fps == 50
        // animation fps == 25 -> animate every second prediction
        // => if poseNetLoop fps < 50 -> (1 / (25/fps)) * 10
        if (loaded_detector) {
            poseNetLoop();
        }
        setTimeout(function () {
            if (loaded_detector) {
                let fract = ((1 / (25 / dispFPS)) * 10).toFixed(0);
                if (fract > 20) {
                    fract = 20;
                }
                if (
                    (dispFPS > target_fps) & (mtx === 0) ||
                    fps_counter % fract !== 0
                ) {
                    draw();
                    drawColoration();
                    mtx = -1;
                } else {
                    mtx = 0;
                }
            }
            animationLoop();
            fps_counter += 10;
        }, 1000 / target_fps);
    }

    useEffect(() => {
        if (coins.number_coins === coinsGoal) {
            increaseActivePauseCounter();
            sendWorkoutEndStatistics(true);
            breakUp("/finish", {
                time: toTime(elapsedTime.toFixed(1)),
                title: "Squats",
                repititions: analytics.repetitions,
            });
        }
    }, [coins.number_coins]);

    const onUserMediaError = () => {
        console.log("ERROR in Camera!");
    };

    const onUserMedia = () => {
        console.log("Camera loaded!");
        setCameraReady(true);
    };

    const beginEstimatePosesStats = () => {
        startInferenceTime = (performance || Date).now();
    };

    const endEstimatePosesStats = () => {
        const endInferenceTime = (performance || Date).now();
        inferenceTimeSum += endInferenceTime - startInferenceTime;
        ++numInferences;
        const panelUpdateMilliseconds = 1000;

        if (endInferenceTime - lastPanelUpdate >= panelUpdateMilliseconds) {
            const averageInferenceTime = inferenceTimeSum / numInferences;
            inferenceTimeSum = 0;
            numInferences = 0;
            let fps = 1000 / averageInferenceTime;
            fpsSum += fps;
            counter++;
            setDisplayFps(fps);
            dispFPS = fps;
            if (counter === 30) {
                dispFPS = fpsSum / 30;
                counter = 0;
                fpsSum = 0;
                // webAnalytics.trackFPS(fps);
            }
            lastPanelUpdate = endInferenceTime;
        }
    };

    const calculateAngles = (poses) => {
        if (isPaused) return;
        for (const pose of poses) {
            slidingWindowCount++;
            let shAngle = analytics.calcShElAngle(pose.keypoints3D);

            shoulderAngleSum += shAngle;
            if (slidingWindowCount === 2) {
                let newShoulderAngle = shoulderAngleSum / 2;
                let difference = Math.abs(shoulderAngle / newShoulderAngle - 1);
                if (difference > 0.015) {
                    let diff = newShoulderAngle - shoulderAngle;
                    shoulderAngle = shoulderAngle + diff / 4;
                }
                shoulderAngleSum = 0;
                slidingWindowCount = 0;
            }
        }
    };

    //////////////////////////////////////////

    function wavePath(x, height) {
        return height * 0.5 - Math.cos(Math.cos(-x) * 0.35 + x) * amplitude;
    }

    var time = 0;
    let time_step = 0.1;
    let colCanvas = null;

    function drawColoration() {
        if (colCanvas == null) {
            colCanvas = document.getElementById("myColorationCanvas");
        }
        const originalHeight = colCanvas.height;
        const originalWidth = colCanvas.width;
        const dimensions = getObjectFitSize(
            true,
            colCanvas.clientWidth,
            colCanvas.clientHeight,
            colCanvas.width,
            colCanvas.height
        );
        colCanvas.width = dimensions.width;
        colCanvas.height = dimensions.height;
        let ratio = Math.min(
            colCanvas.clientWidth / originalWidth,
            colCanvas.clientHeight / originalHeight
        );

        let ctx = colCanvas.getContext("2d");
        ctx.scale(ratio, ratio);

        const greenGradient = ctx.createLinearGradient(
            0,
            0,
            colCanvas.width / 2,
            colCanvas.height / 2
        );
        greenGradient.addColorStop(0, "rgb(38,204,130)");
        greenGradient.addColorStop(1, "rgb(202,218,223)");

        const redGradient = ctx.createLinearGradient(
            0,
            0,
            colCanvas.width / 2,
            colCanvas.height / 2
        );
        redGradient.addColorStop(0, "rgb(251, 63, 63)");
        redGradient.addColorStop(1, "rgb(202,218,223)");

        let x = drawWavePath(ctx, redGradient, greenGradient);
        drawPoint(ctx, x, redGradient, greenGradient);
        drawCoins(ctx);
    }

    function drawWavePath(ctx, redGradient, greenGradient) {
        //// colored path
        ctx.beginPath();
        let x = 0;
        let len = colors.length;
        for (let j = -1; j <= colCanvas.width * 0.3; j++) {
            x = time + j * frequency;
            if (wavePath(x, colCanvas.height) >= 550) {
                ctx.lineTo(j, 550);
            } else if (wavePath(x, colCanvas.height) <= 300) {
                ctx.lineTo(j, 300);
            } else {
                ctx.lineTo(j, wavePath(x, colCanvas.height));
            }
            if (len === 0) {
                colors.push("green");
            } else {
                ctx.lineWidth = 40;
                if (j === -1) continue;
                if (colors[j] === "green") {
                    ctx.strokeStyle = greenGradient;
                } else if (colors[j] === "red") {
                    ctx.strokeStyle = redGradient;
                }
                ctx.stroke();
                ctx.beginPath();
                if (wavePath(x, colCanvas.height) >= 550) {
                    ctx.lineTo(j, 550);
                } else if (wavePath(x, colCanvas.height) <= 300) {
                    ctx.lineTo(j, 300);
                } else {
                    ctx.lineTo(j, wavePath(x, colCanvas.height));
                }
            }
        }
        return x;
    }

    function drawPoint(ctx, x, redGradient, greenGradient) {
        let aIStart = 140;
        let aIEnd = 40;
        let intervalDistance = Math.abs(aIStart - aIEnd);
        let min = 300;
        let max = 550;
        let mappedAngle =
            -730 + (1460 / intervalDistance) * (shoulderAngle - aIEnd);
        let pH = 1 / (0.002 + 0.002 * Math.exp(0.003 * mappedAngle)) + min;
        if (pH > max + max * 0.05) {
            pH = max + max * 0.05;
        }
        if (pH < min - min * 0.05) {
            pH = min - min * 0.05;
        }
        if (isPaused) return pH;
        whd++;
        var wP;
        if (wavePath(x, colCanvas.height) >= 550) {
            wP = 550;
        } else if (wavePath(x, colCanvas.height) <= 300) {
            wP = 300;
        } else {
            wP = wavePath(x, colCanvas.height);
        }

        let diff = wP - pH;
        if (Math.abs(diff) > 100) {
            outsideCount++;
            if (outsideCount >= 15) insideCount = 0;
        } else {
            if (lastDiff > 100) {
                lastDiff = diff;
            }
            insideCount++;
            if (insideCount >= 35) outsideCount = 0;
            coins.checkCoins(x, pH, wP, time_step); // optimization potential
        }
        if (insideCount > 35) {
            pH = wP - lastDiff;
            lastDiff = lastDiff - lastDiff / 20;
            ctx.strokeStyle = greenGradient;
            for (let i = 0; i < 10; i++) {
                colors.shift();
                colors.push("green");
            }
        } else if (outsideCount > 15) {
            ctx.strokeStyle = redGradient;
            for (let i = 0; i < 10; i++) {
                colors.shift();
                colors.push("red");
            }
        }

        // //// angle point
        drawBall(ctx, pH, colCanvas.width, colCanvas.height);
        return pH;
    }

    function drawCoins(ctx) {
        const coinGrad = ctx.createLinearGradient(
            0,
            0,
            colCanvas.width / 2,
            colCanvas.height / 2
        );
        coinGrad.addColorStop(0, "rgb(255,240,0)");
        coinGrad.addColorStop(0.5, "rgb(255,254,0)");

        let currentCoins = coins.getCurrentCoordinates(
            time,
            time + colCanvas.width * frequency
        ); // TODO: optimization potential
        for (let coinX of currentCoins) {
            ctx.beginPath();
            if (wavePath(coinX, colCanvas.height) >= 550) {
                ctx.arc((coinX - time) / frequency, 550, 12, 0, 2 * Math.PI);
            } else if (wavePath(coinX, colCanvas.height) <= 300) {
                ctx.arc((coinX - time) / frequency, 300, 12, 0, 2 * Math.PI);
            } else {
                ctx.arc(
                    (coinX - time) / frequency,
                    wavePath(coinX, colCanvas.height),
                    12,
                    0,
                    2 * Math.PI
                );
            }
            ctx.strokeStyle = "rgba(219,204,39,0.35)";
            ctx.fillStyle = coinGrad;
            ctx.lineWidth = 16;
            ctx.stroke();
            ctx.fill();
        }
    }

    function draw() {
        if (!isPaused) {
            time = time + time_step;
        }
        var canvas = document.getElementById("myCanvas");
        const originalHeight = canvas.height;
        const originalWidth = canvas.width;
        const dimensions = getObjectFitSize(
            true,
            canvas.clientWidth,
            canvas.clientHeight,
            canvas.width,
            canvas.height
        );
        canvas.width = dimensions.width;
        canvas.height = dimensions.height;

        var ctx = canvas.getContext("2d");
        let ratio = Math.min(
            canvas.clientWidth / originalWidth,
            canvas.clientHeight / originalHeight
        );

        ctx.scale(ratio, ratio);
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        //// Mid line
        ctx.beginPath();
        for (let j = -1; j <= canvas.width; j++) {
            let x = time + j * frequency;
            if (wavePath(x, canvas.height) >= 550) {
                ctx.lineTo(j, 550);
            } else if (wavePath(x, canvas.height) <= 300) {
                ctx.lineTo(j, 300);
            } else {
                ctx.lineTo(j, wavePath(x, canvas.height));
            }
        }
        ctx.lineWidth = 40;
        ctx.strokeStyle = "#CADADF";
        ctx.stroke();
    }


    function resume() {
        isPaused = false;
        setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    }

    function sendWorkoutEndStatistics(coinGoal) {
        // webAnalytics.trackWorkoutDone(
        //     analytics.repetitions,
        //     elapsedTime.toFixed(1),
        //     coinGoal,
        //     analytics.angleIntervalStart,
        //     analytics.angleIntervalEnd,
        //     analytics.avgKneeAngle(),
        //     analytics.minToeKneeDistance,
        //     analytics.maxToeKneeDistance,
        //     analytics.avgToeKneeDistance(),
        //     analytics.hip_knee_distance,
        //     analytics.knee_ankle_distance
        // );
    }

    async function breakUp(route, args) {
        setLoading(!loading);
        setTimeout(function () {
            setLoading(!loading);
            navigate(route, {
                replace: false,
                state: args !== null || args !== undefined ? args : null,
            });
        }, 3000);
    }

    function renderPostureStatus() {
        if (analytics.fk_dist / analytics.knee_ankle_distance > 0.75)
            return (
                <h3 style={{ color: "Red" }}>
                    Knie scheinen über den Zehenspitzen zu sein
                </h3>
            );
        return null;
    }

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <CamDiv>
                <Webcam
                    className="filter blur-lg"
                    style={{ visibility: "hidden" }}
                    ref={webcamRef}
                    audio={false}
                    videoConstraints={VIDEO_CONSTRAINTS}
                    onUserMediaError={onUserMediaError}
                    onUserMedia={onUserMedia}
                />
            </CamDiv>
            <Canvas id="myCanvas" style={{ objectFit: "contain" }}></Canvas>
            <ColorationCanvas
                id="myColorationCanvas"
                style={{ objectFit: "contain" }}
            ></ColorationCanvas>
            <Canvas id="videoCanvas" style={{ visibility: "hidden" }}></Canvas>

            <SemiCircleDiv>
                <ExerciseDetails>Squats</ExerciseDetails>
                <span>{shoulderAngle}</span>
                <CoinProgress
                    completed={(coins.number_coins / coinsGoal) * 100}
                    numberCoins={coins.number_coins}
                ></CoinProgress>

                {renderPostureStatus()}
            </SemiCircleDiv>
            <RepCircleDiv>
                <Reps>{analytics.repetitions}</Reps>
                <Wdh>Wdh.</Wdh>
            </RepCircleDiv>
            <PauseDiv>
                <Butt type={"button"}>
                    <IconSpan>
                        <FontAwesomeIcon
                            icon={faPause}
                            onClick={() => {
                                isPaused = true;
                                setIsPlaying((prevIsPlaying) => !prevIsPlaying);
                                toggle();
                            }}
                        />
                    </IconSpan>
                    <TxtSpan>Pause</TxtSpan>
                </Butt>
            </PauseDiv>
            <Modal
                isShowing={isShowing}
                hide={toggle}
                hideCallback={resume}
                finishCallback={() => breakUp("/")}
                poseInstructions={poseInstructions}
            />
            <BounceLoader
                cssOverride={override}
                loading={loading}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
                color="hsla(168, 67%, 53%, 1)"
            />
        </div>
    );
};

const Canvas = styled.canvas`
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #ffffff 0%, #eaf9ff 100%);
    position: absolute;
    overflow: hidden;
`;
const ColorationCanvas = styled.canvas`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
`;

const SemiCircleDiv = styled.div`
    position: absolute;
    width: 450px;
    height: 180px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -15%);

    background-color: white;
    border-bottom-left-radius: 210px;
    border-bottom-right-radius: 210px;
    border: 3px transparent #e3e3e3;
    border-top: 0;
    flex-direction: column;
    justify-content: end;
    text-align: center;
`;

const RepCircleDiv = styled.div`
    position: absolute;
    width: 150px;
    height: 150px;
    top: 3%;
    right: 3%;

    background-color: white;
    border-radius: 100px;
    border: 3px solid #e3e3e3;
    flex-direction: column;
    justify-content: end;
`;

const Time = styled.h4`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    text-align: center;
    font-size: 40px;
    font-weight: 400;
    margin-top: 25px;
`;

const ExerciseDetails = styled.h6`
    background-clip: text;
    -webkit-background-clip: text;
    color: #585f66;
    font-family: "Roboto";
    text-align: center;
    font-size: 25px;
    font-weight: 200;
`;

const Reps = styled.h4`
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(88, 95, 102, 1);
    font-family: "Roboto";
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    margin-top: 30%;
`;

const Wdh = styled.h6`
    background-clip: text;
    -webkit-background-clip: text;
    color: #585f66;
    font-family: "Roboto";
    text-align: center;
    font-size: 20px;
    font-weight: 200;
`;

const PauseDiv = styled.div`
    position: absolute;
    left: 20px;
    top: 10px;
`;

const CamDiv = styled.div`
    position: absolute;
    right: 20px;
    top: 10px;
`;

const Butt = styled.button`
    display: flex;
    height: 40px;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    border-radius: 5px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
`;

const IconSpan = styled.span`
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    color: #0796ee;
    height: 100%;
    font-size: 1.5em;
`;

const TxtSpan = styled.span`
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    color: #0796ee;
    height: 100%;
`;

const EndScreenSpan = styled.span`
    align-items: center;
    color: #25ba7a;
`;

export default WYGameScreen;
