import React from "react";
import PropTypes from "prop-types";
import { formatNumber } from "../leaderboard/leaderboard";
import goldTrophy from "../../../assets/gold_trophy.png";
import silverTrophy from "../../../assets/silver_trophy.png";
import bronzeTrophy from "../../../assets/bronze_trophy.png";

const SCORE_COLORS = {
    1: "#2E99C2",
    2: "#666DA2",
    3: "#332D52",
};

// Best Three users inside Leaderboard
const WinnersRanking = ({ username, score, place }) => {
    const scoreColor = SCORE_COLORS[place] || "transparent";

    const scoreStyle = {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "4vw",
        lineHeight: "80px",
        textAlign: "center",
        color: "#FFFFFF",
        backgroundColor: scoreColor,
        height: place === 1 ? 170 : place === 2 ? 140 : 110,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "10px",
        width: place === 1 ? "25vw" : "20vw",
    };

    const usernameStyle = {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 20,
        lineHeight: "21px",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        textAlign: "center",
        color: "#23272A",
        height: "50px",
        backgroundColor:
            place === 1
                ? "rgba(46, 153, 194, 0.29)"
                : place === 2
                ? "rgba(102, 109, 162, 0.29)"
                : "rgba(51, 45, 82, 0.29)",
    };

    const userScoreStyle = {
        marginLeft: "20px",
        marginRight: "20px",
    };

    const pStyle = {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "3.0vw",
        lineHeight: "75px",
        textAlign: "center",
        color: "#FFFFFF",
    };

    return (
        <div style={userScoreStyle}>
            <div style={usernameStyle}>
                {username}
                {place === 1 ? (
                    <img
                        src={goldTrophy}
                        alt="gold"
                        style={{ alignSelf: "flex-end", height: "100px" }}
                    />
                ) : place === 2 ? (
                    <img
                        src={silverTrophy}
                        alt="silver"
                        style={{ alignSelf: "flex-end", height: "100px" }}
                    />
                ) : (
                    <img
                        src={bronzeTrophy}
                        alt="bronze"
                        style={{ alignSelf: "flex-end", height: "100px" }}
                    />
                )}
            </div>
            <div style={scoreStyle}>
                {formatNumber(score)}
                <span style={pStyle}> p.</span>
            </div>
        </div>
    );
};

WinnersRanking.propTypes = {
    username: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    place: PropTypes.oneOf([1, 2, 3, undefined]),
};

export default WinnersRanking;
