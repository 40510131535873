import React, { useEffect } from "react";
import "./App.css";
import Home from "./components/home/home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PositionScreen from "./components/workout/startup/squats/position";
import SquatsScreen from "./components/workout/game/squats/squats";
import FinishScreen from "./components/workout/finish/finish_exercise";
import { SportifyAnalytics } from "./services/mixpanel_analytics/SportifyAnalytics";
import CookieConsent from "react-cookie-consent";
import {
    DataConsent,
    Impressum,
} from "./components/impressum_dataconsent/dataconsent";
import FeedbackForm from "./components/workout/finish/feedback_form";
import PlaceLaptopScreen from "./components/workout/startup/squats/placing_laptop";
import TestingScreen from "./components/live_testing/testing";

import { fetchToken, onMessageListener } from "./services/firebase";
import { Toast } from "react-bootstrap";
import icon from "./assets/icon.png";
import StretchScreen from "./components/workout/game/stretch/upper_body_stretch_screen";
import WYGameScreen from "./components/workout/game/stretch/wy_game_screen";
import StretchPositionScreen from "./components/workout/startup/stretch/position";
import InstructionScreen from "./components/workout/startup/squats/instruction";
import InstructionScreenStretch from "./components/workout/startup/stretch/instruction_stretch";
import WYInstructionScreen from "./components/workout/startup/wy_stretch/wy_instruction";
import WYPositionScreen from "./components/workout/startup/wy_stretch/wy_position";
import Leaderboard from "./components/challenge/leaderboard/leaderboard";
import HomeChallenge from "./components/challenge/home_challenge/home_challenge";
import LoginScreen from "./components/authentication/login/login_screen";
import RegisterScreen from "./components/authentication/register/register_screen";
import Redirect from "./Redirect";
import FinishChallenge from "./components/challenge/finish/finish_challenge";

export const ActivePauseCounterContext = React.createContext({
    apc: [],
    increaseActivePauseCounter: () => {},
});

export const AnalyticsContext = React.createContext(null);

function App() {
    let today = new Date(Date.now()).getDate();
    let initialAPC = [];
    const [apc, setAPC] = React.useState(initialAPC);
    const [analytics, _] = React.useState(new SportifyAnalytics());
    const [isTokenFound, setTokenFound] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [notification, setNotification] = React.useState({
        title: "",
        body: "",
    });


    fetchToken(setTokenFound);

    useEffect(() => {
        if (localStorage.getItem("today") !== today.toString()) {
            localStorage.setItem("today", today.toString());
            localStorage.setItem(today.toString(), initialAPC.toString());
        } else {
            let apcString = localStorage.getItem(today.toString());
            initialAPC = JSON.parse("[" + apcString + "]");
            setAPC(initialAPC);
        }
    }, []);

    function increaseActivePauseCounter() {
        apc.push(new Date(Date.now()).getHours());
        setAPC(apc);
        localStorage.setItem(today.toString(), apc.toString());
    }

    onMessageListener()
        .then((payload) => {
            setNotification({
                title: payload.notification.title,
                body: payload.notification.body,
            });
            setShow(true);
            console.log(payload);
        })
        .catch((err) => console.log("failed: ", err));

    return (
        <div>
            <Toast
                onClose={() => setShow(false)}
                show={show}
                delay={3000}
                autohide
                animation
                style={{
                    position: "absolute",
                    top: 20,
                    right: 20,
                    minWidth: 200,
                }}
            >
                <Toast.Header
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                    }}
                >
                    <img
                        src={icon}
                        className="rounded mr-2"
                        alt=""
                        width={25}
                        height={25}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                        }}
                    >
                        <strong className="mr-auto">
                            {notification.title}
                        </strong>
                        <small>just now</small>
                    </div>
                </Toast.Header>
                <Toast.Body>{notification.body}</Toast.Body>
            </Toast>

            <BrowserRouter>
                <Redirect></Redirect>
                <CookieConsent
                    onAccept={() => {
                        window.location.reload(false);
                    }}
                    location="bottom"
                    buttonText="Akzeptieren"
                    cookieName="data_consent"
                    style={{ background: "#C6C7DB" }}
                    buttonStyle={{
                        background: "#0796EE",
                        color: "#ffffff",
                        borderRadius: "4px",
                    }}
                    expires={150}
                >
                    <span style={{ color: "#585F66" }}>
                        Diese Website nutzt Cookies{"     "}
                    </span>
                    <a href="datenschutz" style={{ fontSize: "10px" }}>
                        Datenschutzerklärung
                    </a>
                </CookieConsent>
                <ActivePauseCounterContext.Provider
                    value={{ apc, increaseActivePauseCounter }}
                >
                    <AnalyticsContext.Provider value={analytics}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route
                                path="position"
                                element={<PositionScreen />}
                            />
                            <Route path="new_game" element={<SquatsScreen />} />
                            <Route path="finish" element={<FinishScreen />} />
                            <Route
                                path="datenschutz"
                                element={<DataConsent />}
                            />
                            <Route path="impressum" element={<Impressum />} />
                            <Route path="feedback" element={<FeedbackForm />} />
                            <Route
                                path="place_laptop"
                                element={<PlaceLaptopScreen />}
                            />
                            <Route path="test" element={<TestingScreen />} />
                            <Route path="new_game_stretch" element={<StretchScreen />} />
                            <Route path="wy_game_screen" element={<WYGameScreen />} />
                            <Route path="position_stretch" element={<StretchPositionScreen/>}/>
                            <Route path="instruction_squat" element={<InstructionScreen/>}></Route>
                            <Route path="instruction_stretch" element={<InstructionScreenStretch/>}></Route>
                            <Route path="instruction_stretch_yw" element={<WYInstructionScreen/>}></Route>
                            <Route path="position_wy" element={<WYPositionScreen />}/>
                            <Route path="leaderboard" element={<Leaderboard></Leaderboard>}></Route>
                            <Route path="home_challenge" element={<HomeChallenge></HomeChallenge>}></Route>
                            <Route path="login" element={<LoginScreen />} />
                            <Route path="register" element={<RegisterScreen />} />
                            <Route path="finish_challenge" element={<FinishChallenge />} />
                        </Routes>
                    </AnalyticsContext.Provider>
                </ActivePauseCounterContext.Provider>
            </BrowserRouter>
        </div>
    );
}

export default App;
