import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import "../../../App.css";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";


function PauseModal({isShowing, hide, hideCallback, finishCallback, poseInstructions, countdown}) {

    const [remaining, setRemaining] = useState(10);

    useEffect(() => {
        if (countdown && isShowing) {
            if (remaining === 0) {
                hide();
                hideCallback();
                setRemaining(10)
            }
            const timer = setTimeout(() => {
                if (remaining > 0) {
                    setRemaining(remaining - 1);
                }
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown, isShowing, remaining]);


    if (isShowing && poseInstructions != null && poseInstructions !== "" && countdown === null) {
        setTimeout(function () {
            hide();
            hideCallback();
        }, 10000);
    }

    if (isShowing) {
        return (
            ReactDOM.createPortal(
                <React.Fragment>
                    <div className="modal-overlay"/>
                    <div
                        className="modal-wrapper"
                        aria-modal
                        aria-hidden
                        tabIndex={-1}
                        role="dialog"
                    >
                        <div className="modals">
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center">
                                <h3>{poseInstructions}</h3>
                            </Stack>
                            <Stack
                                spacing={2}
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Button
                                    variant="contained"
                                    style={{
                                        textTransform: "none",
                                        borderRadius: "8px",
                                        width: "150px",
                                        background: "rgb(204, 101, 78, 0.2)",
                                        color: "#CC654E",
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        fontSize: "20px"
                                    }}
                                    onClick={() => {
                                        hide();
                                        finishCallback();
                                    }}
                                >
                                    Beenden
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{
                                        textTransform: "none",
                                        borderRadius: "8px",
                                        minWidth: "160px",
                                        background: "#0796EE",
                                        color: "#fff",
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        fontSize: "20px"
                                    }}
                                    onClick={() => {
                                        hide();
                                        hideCallback();
                                    }}
                                >
                                    {countdown ? (
                                        <span>Fortsetzen in {remaining} Sek.</span>
                                    ) : (
                                        <span>Fortsetzen</span>
                                    )}
                                </Button>
                            </Stack>
                        </div>
                    </div>
                </React.Fragment>,
                document.body
            )
        )
    } else {
        return null;
    }
}

export default PauseModal;
